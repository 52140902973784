import { useRef, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import classes from "./ScotabdsSelect.module.css";

// Service
import { fetchScotabdList} from "@services/forms/projectscopesauditareascotabd";

const ScotabdsSelect = (props) => {
  const {
    id,
    name,
    placeholderLeft,
    placeholderRight,
    isDisabled,
    onChange,
    onClose,
    defaultValues,
    label,
    error,
    helperText,
    disabledValues,
    truncateDisplay,
    onFocus,
    ProjectScopeIds,
    ProjectScopeAuditAreaId,
    index,
    placeholder
  } = props;

  const dropdownRef = useRef(null);

  const [menuItemWidth, setMenuItemWidth] = useState(0);
  const [menuItems, setMenuItems] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const { projectId } = useParams();

  const fieldId = "ScotabdsSelect" + id;

  const processAccountList = lists => {
    let accountList
    try {
      const parsedData = JSON.parse(lists)
      accountList = parsedData.map(({ AccountBalanceName }) => AccountBalanceName).join(", ")
    } catch (error) {
      accountList = lists
    }

    return accountList
  }

  useEffect(() => {
    if (!ProjectScopeIds.length && !ProjectScopeAuditAreaId.length) return;

    async function FetchData() {
      const data = await fetchScotabdList(projectId, ProjectScopeIds, ProjectScopeAuditAreaId, true); // IsFromRiskFactors = true

      const scotbdMenuItems = data ? data.map((item) => ({
        labelLeft: item.ClassOfTransactionName,
        labelRight: processAccountList(item.AccountBalanceTypeList),
        value: item.ProjectScopeAuditAreaSCOTABDId,
      })) : [];

      setMenuItems(scotbdMenuItems);
    }

    FetchData();
  }, [ProjectScopeIds, ProjectScopeAuditAreaId, index])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValues = typeof value === "string" ? value.split(",") : value;
    setValues(newValues);
    onChange && onChange(newValues);
    return newValues;
  };

  const handleClose = (event) => {
    onClose && onClose(event);
  };

  const renderDisplayValues = (selected) => {
    const selectedTexts =
      menuItems &&
      menuItems
        .filter((item) => {
          if (item?.hasOwnProperty("value")) return selected.includes(item.value);
          else return selected.includes(item);
        })
        .map((item) => {
          if (item?.hasOwnProperty("labelLeft")) return item.labelLeft;
          else return item;
        });

    const ScotabdsSelectDisplay = () => {
      const styleKey = truncateDisplay ? "truncate-values" : "render-values";

      if (!selectedTexts.length) {
        return <div className={`${classes["placeholder"]}`}>{placeholder}</div>;
      }

      return (
        <div className={`${selectedTexts.length > 0 ? classes[styleKey] : classes["placeholder"]}`}>
          {selectedTexts.join(", ") || placeholderLeft}
        </div>
      );
    };
    return <ScotabdsSelectDisplay />;
  };

  const menuItemHMTL =
    menuItems &&
    menuItems.map((item, index) => (
      <MenuItem
        disabled={disabledValues.indexOf(item) >= 0}
        className={classes["scotabds-item"]}
        key={index}
        value={item?.hasOwnProperty("value") ? item.value : item}
        sx={{
          maxWidth: `${menuItemWidth}px`,
          whiteSpace: "normal",
          wordWrap: "break-word",
          color: item?.color ? item.color : {},
        }}
      >
        <div className={classes["scotabds-transaction"]}>
            <Checkbox
              checked={
                values.findIndex((value) => {
                  if (item?.hasOwnProperty("value")) return value === item.value;
                  else return value === item;
                }) > -1
              }
            />
            <ListItemText primary={item?.hasOwnProperty("labelLeft") ? item.labelLeft : item} />
          </div>
        <div className={classes["scotabds-placeholder"]}>
          <div className={classes["scotabds-AccountBalance"]}>
            <ListItemText primary={item?.hasOwnProperty("labelRight") ? item.labelRight : item} />
          </div>
        </div>
      </MenuItem>
    ));

  useEffect(() => {
    setMenuItemWidth(dropdownRef.current?.offsetWidth);
  }, [menuItems, onChange]);

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  return (
    <FormControl ref={dropdownRef} className={classes["scotabds-select"]} error={error} fullWidth>
      <InputLabel id={fieldId} shrink>
        {label}
      </InputLabel>
      <Select
        name={name}
        multiple
        displayEmpty
        value={values}
        label={label}
        error={error}
        onChange={handleChange}
        onClose={handleClose}
        disabled={isDisabled}
        notched
        renderValue={renderDisplayValues}
        sx={{
          "& .MuiSelect-select": {
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
          },
        }}
        onFocus={onFocus}
      >
        <MenuItem
          disabled
          value=""
          sx={{
            whiteSpace: "unset",
            wordBreak: "break-all",
            opacity: "1 !important",
          }}
        >
          <div className={classes["scotabds-placeholder"]}>
            <div className={classes["scotabds-transaction"]}>{placeholderLeft}</div>
            <div className={classes["scotabds-AccountBalance"]}>{placeholderRight}</div>
          </div>
        </MenuItem>
        {menuItemHMTL}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

ScotabdsSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  defaultValues: PropTypes.array,
  disabledValues: PropTypes.array,
  isDisabled: PropTypes.bool,
  placeholderLeft: PropTypes.string,
  placeholderRight: PropTypes.string,
  truncateDisplay: PropTypes.bool,
  onFocus: PropTypes.func,
  placeholder: PropTypes.any,
};

ScotabdsSelect.defaultProps = {
  menuItems: [],
  defaultValues: [],
  disabledValues: [],
  isDisabled: false,
  placeholderLeft: "Select",
  placeholderRight: "",
  truncateDisplay: false,
  error: false,
  helperText: "",
  placeholder: "Select",
};

export default ScotabdsSelect;

import { Grid, Button } from '@mui/material';

import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { VFCheckbox } from '@components/FormView';
import { formatUtcToLocalTimeZone } from '@utilities/dateHelpers';
import { useVFSignOffProvider } from '@providers';
import styles from './FormView.module.css';

export const VFSignOffV2 = ({
    requiredSignOffLevel,
    disabled,
}) => {
    const { signOffUserList, userInfoList, authUser, actions: { handleSignOff, handleRemoveSignOff } } = useVFSignOffProvider(); 
    const signOffs = PROJECT_FORM_INSTANCE.EN.SIGN_OFFS; 
     
    const displaySignOffDetails = (signOffUser) => {
        const user = userInfoList?.find(
            (item) => item.activeDirectoryId.toLowerCase() === signOffUser.signOffUser.toLowerCase()
        );
        const employeeId = user?.employeeId;
        return `${user?.firstName} ${user?.lastName[0]}. ${employeeId ? `(${employeeId})` : ''
            } (${formatUtcToLocalTimeZone(signOffUser.signOffDate)})`;
    };

    const displaySignOff = (signOff, index) => {
        const users = signOffUserList.filter(
            (signOffUser) => signOffUser.signOffLevel === signOff.level
        );
        return (
            <Grid key={index} item>
                <Grid container gap={2} mt={2} direction="column">
                    {users?.length > 0 &&
                        users.map((signOffUser, index) => {
                            return (
                                <Grid key={index} item>
                                    <VFCheckbox
                                        name={signOff.name}
                                        label={signOff.label}
                                        value={true}
                                        disabled={disabled}
                                        hidden={
                                            authUser?.localAccountId?.toLowerCase() !==
                                            signOffUser.signOffUser.toLowerCase()
                                        }
                                        onChange={() => authUser?.localAccountId?.toLowerCase() ===
                                            signOffUser.signOffUser.toLowerCase() ? handleRemoveSignOff(signOffUser.projectFormSignOffId) : null}
                                    />
                                    <span className={styles['vf-signoff-info']}>
                                        {displaySignOffDetails(signOffUser)}
                                    </span>
                                </Grid>
                            );
                        })}
                    {(!users ||
                        users?.length === 0) && (
                            <Grid item>
                                <VFCheckbox
                                    name={signOff.name}
                                    label={signOff.label}
                                    disabled={disabled}
                                    onChange={() => handleSignOff(signOff.level)}
                                />
                                {signOff.level > requiredSignOffLevel && (
                                    <span className={styles['vf-signoff-optional']}>
                                        ({PROJECT_FORM_INSTANCE.EN.OPTIONAL})
                                    </span>
                                )}
                            </Grid>
                        )}
                    {
                        <Grid item>
                            <Button
                                variant="form-view-add-sign-off"
                                disabled={!users || users?.length === 0 || users?.length > 9 || disabled}
                                onClick={() => handleSignOff(signOff.level)}
                            >
                                <span>+</span>{PROJECT_FORM_INSTANCE.EN.BUTTONS.ADD_MY_SIGN_OFF}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <div className={styles['vf-conclusion']}>
                {PROJECT_FORM_INSTANCE.EN.CONCLUSIONS}
            </div>
            <Grid direction="row" container gap={12} mt={2}>
                {signOffs.map((signOff, i) => displaySignOff(signOff, i))}
            </Grid>
        </>
    );
};

import {
    Box,
    Checkbox,
    Collapse,
    IconButton,
    Typography
} from '@mui/material';
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';

import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";
import { CLATooltip } from "@ais/components";
import { ReactComponent as GreenBulb } from "@assets/green_bulb.svg";
import { ReactComponent as OrangeBulb } from "@assets/orange_bulb.svg";
import { extractTextOrUrl } from '@utilities/urlHelpers';
import TailoringProcedureContext from './state/TailoringProcedureContext';

const GreenBulbIcon = () => {
    return (
        <IconButton sx={{ paddingRight: "10px", width: "38px" }} disabled>
            <GreenBulb/>
        </IconButton>
    )
}

const OrangeBulbIcon = () => {
    return (
        <IconButton sx={{ paddingRight: "10px", width: "38px" }} disabled>
            <OrangeBulb/>
        </IconButton>
    )
}

const ProcedureStepsCollapse = (props) => {
    const {
        summaryProcedure,
        children, 
        isLast,
        isFirst,
        isRequired,
        checked,
        handleCheckboxChange,
        isEditable
    } = props;

    const [isExpanded, setIsExpanded] = useState(true);
    const [isSuggested, setIsSuggested] = useState(false)
    const description = summaryProcedure?.SummaryProcedureName || summaryProcedure?.ProcedureStepDescription;
    const assertions = summaryProcedure?.Assertions ?? [];
    const assertionAcronym = assertions.map((assertion) => {
        return assertion.AssertionAcronym.trim();
    })
    const assertionName = assertions.map((assertion) => {
        return assertion.AssertionName.trim();
    })
    const { suggestedProcedures, setApplySuggestion, isAppliedToRiskAssessment } = useContext(TailoringProcedureContext)


    useEffect(() => {
        setIsSuggested(suggestedProcedures?.some((proc) => proc?.SummaryProcedureStepId === summaryProcedure?.SummaryProcedureStepId))
    }, [suggestedProcedures, summaryProcedure])

    const getIcon = () => { 
        if (isSuggested && summaryProcedure?.ProcedureStepDescription && isAppliedToRiskAssessment) {
            return <GreenBulbIcon />
        }

        if ((checked || (!checked && summaryProcedure?.IsRequiredStep)) && summaryProcedure?.ProcedureStepDescription && isAppliedToRiskAssessment) {
            return <OrangeBulbIcon />
        }

        if ((!checked && summaryProcedure?.ProcedureStepDescription) || (summaryProcedure?.ProcedureStepDescription && !isAppliedToRiskAssessment)) {
            return <IconButton sx={{ paddingRight: "10px", width: "38px", height: "44px" }} disabled />
        }
            
        return <></>

    }

    const handleCheckbox = (e) => {
        setApplySuggestion(false)
        handleCheckboxChange({
            ...summaryProcedure,
            IsChecked: e.target.checked ? 1 : 0
        })
    }

    return (
        <Box sx={{ pt: isFirst ? 0 : '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '3%', display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        sx={{ p: 0, display: isLast ? 'none' : 'block' }}
                        onClick={() => { setIsExpanded(prevIsExpaned => !prevIsExpaned) }}>
                        {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                    </IconButton>
                </div>
                <div style={{ width: '97%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <Box sx={{ flexGrow: 1, fontWeight: isFirst ? 'bold' : '', display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                data-test="checkbox-component"
                                sx={{ pl: 0, pr: '5px' }}
                                disabled={isRequired || !isEditable}
                                checked={isRequired || checked}
                                onChange={(e) => handleCheckbox(e)}
                            />
                            {getIcon()}
                            <Typography sx={{
                                fontSize: summaryProcedure?.SummaryProcedureName ? '16px' : '12px',
                                fontWeight: summaryProcedure?.SummaryProcedureName ? 700 : 600,
                                color: summaryProcedure?.SummaryProcedureName ? '#2E334E' : '#4B4B4B',
                            }}>
                                <span
                                    className="ql-editor"
                                    dangerouslySetInnerHTML={{ __html: summaryProcedure?.SummaryProcedureName || extractTextOrUrl(description) }}
                                />
                            </Typography>
                        </Box>
                        <Box sx={{
                            flexShrink: 0,
                            width: '100px'
                        }}>
                            <CLATooltip 
                                title={assertionName.join(', ')}
                                PopperProps={{ sx: { zIndex: 1500 } }}
                                placement="top">
                                <Typography sx={{
                                    overflowWrap: 'break-word',
                                    fontSize: '16px',
                                    color: '#4B4B4B',
                                    fontWeight: isFirst ? 'bold' : '',
                                    textAlign: 'center'
                                }}>
                                    {assertionAcronym.join(', ')}
                                </Typography>
                            </CLATooltip>
                        </Box>
                    </Box>
                </div>
            </Box>
            <Collapse
                data-test="collapse-component"
                sx={{ display: isLast ? 'none' : 'block' }}
                in={isExpanded}
                collapsedSize={0}
                unmountOnExit>
                <Box
                    sx={{ display: 'flex', flexDirection: 'row' }}
                >
                    <div style={{ width: '3%' }} />
                    <div style={{ width: '97%' }}>
                        {children}
                    </div>
                </Box>
            </Collapse>
        </Box >
    );
};

ProcedureStepsCollapse.propTypes = {
    isLast: PropTypes.bool,
    isFirst: PropTypes.bool
}

ProcedureStepsCollapse.defaultProps = {
    isLast: false,
    isFirst: false
}

export default ProcedureStepsCollapse;
import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from "react-hook-form"
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";

import { SCHEMA_ACTION_TYPES } from '@ais/constants';
import { DROPZONE_AREAS, TRIGGERS } from '@constants/forms';
import { SchemaContext } from '@ais/providers';
import {
  createSection,
  updateSection,
  checkQuillTextHasValue,
  filterEmptyCriterias,
  generateSectionRules
} from '@components/Forms/helpers';
import { BaseDialog } from '.';
import {
  BasicTextField,
  ToggleableTextarea,
  CheckboxField,
} from './dialog-components/settings';
import { RulesTab } from './dialog-components/rules';
import { DIALOG } from '@constants/forms';

export const SectionDialog = ({
  visible,
  setVisible,
  additionalData,
  trigger,
}) => {
  const { schema, dispatchSchema } = useContext(SchemaContext);
  const { LABEL } = DIALOG.SETTINGS;

  const properties = schema?.properties;

  const initialValues = {
    title: additionalData?.title ?? '',
    description: additionalData?.description ?? '',
    tooltip: additionalData?.tooltip ?? '',
    excludeFromExport: additionalData?.excludeFromExport ?? false,
    showCollapsed: additionalData?.showCollapsed ?? false,
    showContextMenu: additionalData?.showContextMenu ?? true,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
          behavior: 'show',
          match: 'any',
          criterias: [],
        },
  };

  const schemaValidation = Yup.object().shape({
    title: Yup.string().trim().required(''),
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const handleSubmit = (values) => {
    values.tooltip = checkQuillTextHasValue(values.tooltip) ? values.tooltip : '';
    const filteredRules = filterEmptyCriterias(values.rules);
    if (trigger === TRIGGERS.CANVAS) {
      const newProperty = createSection({
        id: additionalData?.id,
        ...values,
        rules: filteredRules,
      });
      const newProperties = [
        ...properties,
        newProperty
      ];
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_PROPERTIES,
        payload: newProperties,
      });
      generateSectionRules(newProperty, dispatchSchema);
    } else if (trigger === TRIGGERS.DROPZONE) {
      const oldIndex = additionalData?.index;
      const newProperties = [...properties];
      const newProperty = createSection({
        id: additionalData?.id,
        ...values,
        rules: filteredRules,
      });
      if (additionalData?.position === DROPZONE_AREAS.TOP)
        newProperties.splice(oldIndex, 0, newProperty);
      else if (additionalData?.position === DROPZONE_AREAS.BOTTOM)
        newProperties.splice(oldIndex + 1, 0, newProperty);
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_PROPERTIES,
        payload: newProperties,
      });
      generateSectionRules(newProperty, dispatchSchema);
    } else if (trigger === TRIGGERS.ELLIPSIS) {
      const newProperty = updateSection(properties, additionalData.id, {
        ...values,
        rules: filteredRules,
      });
      if (newProperty) {
        dispatchSchema({
          type: SCHEMA_ACTION_TYPES.UPDATE_SECTION,
          index: newProperty.index,
          payload: newProperty.value,
        });
        generateSectionRules(newProperty.value, dispatchSchema);
      }
    }
    setVisible(false);
  }

  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => (
        <Grid container rowSpacing={6} columnSpacing={22}>
          <Grid item xs={12}>
            <BasicTextField
              name="title"
              label="Section Label:"
              placeholder="Section Title Here"
            />
          </Grid>
          <Grid item xs={6}>
            <ToggleableTextarea
              name="description"
              label="Add Description (Optional)"
              placeholder="Text"
            />
          </Grid>
          <Grid item xs={6}>
            <ToggleableTextarea
              name="tooltip"
              label="Add Tooltip Instructions"
              placeholder="Text"
              toggleable
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CheckboxField
                  name="excludeFromExport"
                  label="Exclude from Export"
                />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <CheckboxField
                  name="showCollapsed"
                  label="Show Collapsed"
                  helperText="(Helpful for Guidelines)"
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxField
                  name="showContextMenu"
                  label="Show Context Menu"
                  helperText="(Add a Question, Add a Risk, Add a Risk Factor, Add a Comment)"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      label: LABEL.RULES,
      render: () => (
        <RulesTab
          additionalData={additionalData}
          trigger={trigger}
          isSection
        />
      ),
    },
  ];

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger();
  }, [visible])

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={additionalData?.id}
        idPrefix={LABEL.SECTION_ID}
        title={LABEL.SECTION}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={tabs}
        trigger={trigger}
      />
    </Form>
  );
};

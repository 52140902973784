import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { OutlinedInput, MenuItem, Checkbox } from '@mui/material';

import { CLA_FORMTABLE } from '@ais/constants';
import {
  CLAActionEllipsis,
  CLACurrencyField,
  CLADatePickerField,
  CLADropdown,
  CLANumberField, 
  CLAPercentageField, 
  CLARadioButtonList,
  copyArray,
  copyObject
} from '@ais/forms';

import styles from './CLAFormTable.module.css';

const FORMTABLE_LABEL = CLA_FORMTABLE.EN;

const generateNewRow = () => ({
  id: uuidv4(),
  rowLabel: '',
  rowIndex: '',
});

const handleCurrencyValue = (value) => (isNaN(+value) ? value?.replace('$', '') : value);

export const FormTableWrapper = (props) => {
  const {
    children,
    isInstance,
    wrapper: VFRenderedFieldWrapper,
    ...rest
  } = props

  return (
    <>
      {VFRenderedFieldWrapper && (
        <VFRenderedFieldWrapper isInstance={isInstance} {...rest}>
          {children}
        </VFRenderedFieldWrapper>
      )}
      {!VFRenderedFieldWrapper && (
        <div {...rest}>{children}</div>
      )}
    </>
  )
}

const CLAFormTableCheckbox = ({answer, disabled, onFocus, onChange}) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(answer);
  }, [answer])
 
  return (
    <div onFocus={onFocus} onBlur={() => onChange(value)}>
      <Checkbox
        checked={value}
        onChange={(e) => 
          setValue(e.target.checked)
        }
        disabled={disabled}
      />
    </div>
  )
}

export const CLAFormTable = (props) => {
  const {
    field,
    values,
    onChange,
    isInstance,
    onFocus,
    disabled,
    vfRenderedFieldWrapper,
    isIdle
  } = props;

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [answerList, setAnswerList] = useState([]);

  useEffect(() => {
    setColumns(copyArray(field.columns));
    if (!isInstance) {
      setRows(copyArray(field.rows));
    }
  }, [field.rows, field.columns]);

  useEffect(() => {
    const _values = values ? values : [];
    if (isInstance) {
      const rows = _values.map((v) => ({
        ...v,
        id: v.id,
        rowLabel: v?.rowLabel ?? '',
        rowIndex: '',
      }));
      setRows(rows);
    }
    setAnswerList(copyArray(_values));
  }, [values]);

  const getRowLabel = (row) => {
    const answer = answerList?.length > 0 ? answerList.find((answer) => answer.id === row.id) : {};
    return answer && answer.rowLabel ? answer.rowLabel : row.rowLabel;
  };

  const handleLabelChange = (row, value) => {
    let copy = answerList?.length > 0 ? copyArray(answerList) : [];
    const answer = copy?.find((answer) => answer.id === row.id);

   if (answer) {
      answer.rowLabel = value;
    } else {
      if (copy) {
        copy = [...copy, { id: row.id, rowLabel: value }];
      } else {
        copy = [{ id: row.id, rowLabel: value }];
      }
    }

    if (isInstance) onChange(copy);
    setAnswerList(copy);
  };

  const handleAnswerChange = (rowId, columnId, value, manualOnChange = false) => {
    setAnswerList((prev) => {
      const row = prev?.find((answer) => answer.id === rowId);
      const column = row?.values?.find((value) => value.id === columnId);
      if (row) {
        if (column) column.value = value;
        else {
          const rowValues = row.values ?? [];
          row.values = [...rowValues, { id: columnId, value }];
        }
      } else
        prev = prev
          ? [...prev, { id: rowId, values: [{ id: columnId, value }] }]
          : [{ id: rowId, values: [{ id: columnId, value }] }];
      if (isInstance && !manualOnChange) onChange(prev);
      return prev;
    });
  };

  const addDuplicateRowAnswers = (newRowId, selectedRowId, rowIndex) => {
    setAnswerList((prev) => {
      const selectedRow = prev?.find((answer) => answer.id === selectedRowId);
      if (selectedRow) {
        const copyRow = copyObject(selectedRow);
        prev = [...prev.slice(0, rowIndex), { id: newRowId, rowLabel: copyRow.rowLabel, values: copyRow.values }, ...prev.slice(rowIndex)];
      }
      if (isInstance) onChange(prev);
      return prev;
    });
  };

  const removeRowAnswers = (selectedRowId) => {
    setAnswerList((prev) => {
      const newAnswers = prev?.filter((answer) => answer.id !== selectedRowId);
      prev = copyArray(newAnswers);
      if (isInstance) onChange(prev);
      return prev;
    });
  };

  const handelCancelMenu = () => {
    if (isInstance) onChange(rows)
  }

  const actionButton = (id) => {
    let rowIndex = rows
      .map((row) => {
        return row.id;
      })
      .indexOf(id);
    return (
      <TableCell title={'action'}>
        {disabled && isInstance ? (
          <IconButton
            aria-label="action-ellipsis"
            className="action-ellipsis"
            data-testid="action-ellipsis"
            sx={{ pointerEvents: 'none', cursor: 'not-allowed' }}
          >
            <MoreVertIcon />
          </IconButton>
        ) : (
          <CLAActionEllipsis autofocus={false} onFocus={onFocus} onCancelMenu={handelCancelMenu}>
            {(handleClose) => [
              <MenuItem
                key="insertRowAbove"
                variant="ellipsis-menu"
                onClick={() => {
                  handleClose();
                  setRows((prevState) => {
                    const newRow = generateNewRow();
                    const copy = [...prevState];
                    copy.splice(rowIndex, 0, newRow);
                    if (isInstance) onChange(copy);
                    return copy;
                  });
                }}
              >
                {FORMTABLE_LABEL.INSERT_ABOVE}
              </MenuItem>,
              <MenuItem
                key="insertRowBelow"
                variant="ellipsis-menu"
                onClick={() => {
                  handleClose();
                  setRows((prevState) => {
                    const newRow = generateNewRow();
                    const copy = [...prevState];
                    copy.splice(rowIndex + 1, 0, newRow);
                    if (isInstance) onChange(copy);
                    return copy;
                  });
                }}
              >
                {FORMTABLE_LABEL.INSERT_BELOW}
              </MenuItem>,
              <MenuItem
                key="removeRow"
                variant="ellipsis-menu"
                onClick={() => {
                  handleClose();
                  if (rows.length > 1) {
                    const filtered = rows.filter((row) => row.id !== id);
                    setRows([...filtered]);
                    removeRowAnswers(id);
                  }
                }}
                disabled={rows.length === 1}
              >
                {FORMTABLE_LABEL.REMOVE}
              </MenuItem>,
              <MenuItem
                key="duplicateRow"
                variant="ellipsis-menu"
                onClick={() => {
                  handleClose();
                  const currentRow = copyObject(
                    rows.find((row) => {
                      return row.id === id;
                    })
                  );
                  currentRow.id = uuidv4();
                  setRows((prevState) => {
                    const copy = [...prevState];
                    copy.splice(rowIndex + 1, 0, currentRow);
                    return copy;
                  });
                  addDuplicateRowAnswers(currentRow.id, id, rowIndex);
                }}
              >
                {FORMTABLE_LABEL.DUPLICATE}
              </MenuItem>,
            ]}
          </CLAActionEllipsis>
        )}
      </TableCell>
    );
  };

  const renderTableCell = (rowId, column) => {
    const row = answerList?.length > 0 ? answerList.find((answer) => answer.id === rowId) : {};
    const col = row?.values?.find((value) => value.id === column.id);
    const answer = col?.value;
    switch (column.columnFieldType) {
      case 'shortAnswer':
        return (
          <FormTableWrapper wrapper={vfRenderedFieldWrapper} style={{ minWidth: '250px', minHeight: '58px' }} isInstance>
            {column.validation === 'none' && (
              <TableTextField
                maxRows={20}
                inputProps={{
                  maxLength: 256,
                }}
                defaultValue={answer ?? ''}
                onBlur={(e) => handleAnswerChange(rowId, column.id, e.target.value)}
                onFocus={onFocus}
                disabled={disabled}
                isIdle={isIdle}
              />
            )}
            {column.validation === 'numerical' && (
              <CLANumberField
                digitGroupSeparator=","
                defaultValue={answer}
                onBlur={(e) => handleAnswerChange(rowId, column.id, e.target.value)}
                onFocus={onFocus}
                disabled={disabled}
                isIdle={isIdle}
              />
            )}
            {column.validation === 'usCurrency' && (
              <CLACurrencyField
                defaultValue={handleCurrencyValue(answer)}
                onBlur={(e) => handleAnswerChange(rowId, column.id, e.target.value)}
                onFocus={onFocus}
                disabled={disabled}
                isIdle={isIdle}
              />
            )}
            {column.validation === 'percentage' && (
              <CLAPercentageField
                isRowTable
                value={answer}
                onBlur={(e, value) => handleAnswerChange(rowId, column.id, value?.toString())}
                onFocus={onFocus}
                disabled={disabled}
                useAsDisplay={disabled}
                isIdle={isIdle}
              />
            )}
          </FormTableWrapper>
        );
      case 'dropdown':
        return (
          <FormTableWrapper wrapper={vfRenderedFieldWrapper} style={{ minWidth: '250px', minHeight: '58px' }} isInstance>
            <CLADropdown
              options={column.dropdownOptions}
              value={answer ?? column.dropdownDefaultValue}
              onChange={(e) => handleAnswerChange(rowId, column.id, e.target.value)}
              onBlur={() => {if (isInstance) onChange(answerList);}}
              onOpen={onFocus}
              isDisabled={disabled}
              isIdle={isIdle}
            />
          </FormTableWrapper>
        );
      case 'datepicker':
        return (
          <FormTableWrapper wrapper={vfRenderedFieldWrapper} style={{ minWidth: '250px', minHeight: '58px' }} isInstance>
            <CLADatePickerField
              id={column.id}
              defaultValue={answer}
              onChange={(value) =>
                handleAnswerChange(rowId, column.id, value ?? null, true)
              }
              onBlur={() => {if (isInstance) onChange(answerList)}}
              onFocus={onFocus}
              disabled={disabled}
              isIdle={isIdle}
            />
          </FormTableWrapper>
        );
      case 'radioButton':
        return (
          <FormTableWrapper wrapper={vfRenderedFieldWrapper} style={{ minWidth: '250px', minHeight: '58px' }} isInstance>
            <CLARadioButtonList
              value={answer ?? column.radioDefaultValue}
              columns={2}
              options={column.radioOptions}
              onChange={(value) => handleAnswerChange(rowId, column.id, value)}
              onFocus={onFocus}
              isDisabled={disabled}
            />
          </FormTableWrapper>
        );
      case 'checkbox':
        return (
          <FormTableWrapper wrapper={vfRenderedFieldWrapper} style={{ minWidth: '250px', minHeight: '58px' }} isInstance>
            {isInstance && (
              <CLAFormTableCheckbox
                answer={answer ?? column.checkboxCheckedByDefault}
                onChange={e => handleAnswerChange(rowId, column.id, e)}
                disabled={disabled}
                onFocus={onFocus}
              />
            )}
            {!isInstance && <Checkbox defaultChecked={column.checkboxCheckedByDefault} />}
          </FormTableWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <FormTableWrapper wrapper={vfRenderedFieldWrapper} className={styles.container}>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead className={styles.tableHeader} data-test="tableHeader">
            <TableRow key={'header'}>
              <TableCell className={styles.tableHeaderCell} key={'action'} align="left"></TableCell>
              {!field.disableRowLabel && (
                <TableCell
                  className={styles.tableHeaderCell}
                  key={'rowLabel'}
                  align="left"
                ></TableCell>
              )}
              {columns.map((column) => (
                <TableCell className={styles.tableHeaderCell} key={column.id} align="left">
                  {column.columnLabel}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-test="tableBody">
            {rows.map((row, i) => {
              return (
                <TableRow className={styles.tableRow} key={row.id}>
                  {actionButton(row.id)}
                  {!field.disableRowLabel && (
                    <TableCell title={'rowLabel' + i} key={`row` + i}>
                      <FormTableWrapper wrapper={vfRenderedFieldWrapper}
                        style={{ minWidth: '250px', minHeight: '58px' }}
                        isInstance
                      >
                        <TableTextField
                          name="rowLabel"
                          placeholder={'Enter Text'}
                          value={getRowLabel(row)}
                          defaultValue={getRowLabel(row)}
                          onBlur={(e) => {
                            handleLabelChange(row, e.target.value);
                          }}
                          onFocus={onFocus}
                          disabled={disabled}
                          isIdle={isIdle}
                        />
                      </FormTableWrapper>
                    </TableCell>
                  )}
                  {columns.map((column, j) => {
                    return (
                      <TableCell width={'auto'} title={`row${i} column${j}`} key={j}>
                        {renderTableCell(row.id, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </FormTableWrapper>
  );
};

const TableTextField = (props) => {
  const { defaultValue, onBlur, disabled, onFocus, placeholder, isIdle } = props;

  const [shortValue, setShortValue] = useState(null);
  const inputRef = useRef(null)
  const containerRef = useRef(null)

  const getValue = () => {
    if (!shortValue) return defaultValue;

    if (shortValue !== defaultValue) return defaultValue;
    return shortValue;
  }

  useEffect(() => {
    let value = getValue();
    if (isIdle && containerRef.current?.classList.contains('Mui-focused')) {
      value = defaultValue;
      containerRef.current?.classList.remove('Mui-focused');
      inputRef.current?.blur();
    }
    setShortValue(value);
  }, [defaultValue, isIdle]); 

  return (
    <OutlinedInput
      inputRef={inputRef}
      ref={containerRef}
      maxRows={20}
      inputProps={{
        maxLength: 256,
      }}
      placeholder={placeholder}
      value={shortValue ?? ''}
      onChange={(e) => setShortValue(e.target.value)}
      onBlur={(e) => onBlur && onBlur(e)}
      onFocus={onFocus}
      notched
      disabled={disabled}
    />
  );
}

export default CLAFormTable;

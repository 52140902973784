import React, { useState, useEffect } from "react";
import { useDeepCompareEffect } from 'react-use';
import { useParams } from "react-router-dom";

import * as Constants from "@constants/index"
import styles from './CLATrialBalanceSelect.module.css';

import { CLADropdown, CLASelect } from '@ais/forms';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { useGetProjectTrialBalanceData } from '@services/trialbalance';
import { useGraphContext } from '@contexts/PlanningAnalytics/GraphContext';
import clsx from 'clsx';
// import ExportGLDetailLink from "@components/CLATrialBalanceSelect/ExportGLDetailLink/ExportGLDetailLink";
import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { sortReviewerSignoffAsc, getFormattedSignOffDate } from '@utilities/dateHelpers.js';
import { useGetAnswersByProjectFormId } from '@services/forms/projectforms.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';
import { useDisabledGlobalQueryLoading } from '@hooks/index';
import { useRoomIdle } from "@components/Concurrency/provider/RoomProvider";

const CLATrialBalanceSelect = ({
  handCaptureInput = undefined,
  isDisabled = false,
  field,
  defaultValues,
  formTypeId,
  isSingleSelect = false,
  project,
  isPlanningAnalyticsForm = false,
  onFocus,
  signOffList,
  highlightable = false,
  onOpen,
  isLockedByUser,
  isLocked,
}) => {
  useDisabledGlobalQueryLoading();
  const { ID, PLACEHOLDER, PLACEHOLDER_WITH_OPTIONAL, TRIAL_BALANCE } = Constants.TRIAL_BALANCE.EN
  const { required } = field;

  const updateMyPresence = useUpdateMyPresence();
  const isIdle = useRoomIdle();
  const [menuItems, setMenuItems] = useState([]);
  const [selectedTrialBalances, setSelectedTrialBalances] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [tbDefaultValue, setTBDefaultValue] = useState([]);
  const { projectId, projectFormId } = useParams()
  const isProjectFinalized = useFinalizedProject(projectId);
  const [shouldHighlight, setShouldHighlight] = useState(false)
  const { data: answers } = useGetAnswersByProjectFormId(projectFormId, projectId)
  const [matchingAnswer, setMatchingAnswer] = useState();
  const [open, setOpen] = useState(false);

  let { setTrialBalances } = useGraphContext()

  const {
    data: trialBalances,
    isLoading: isTrialBalanceLoading,
    isError: isTrialBalanceError,
  } = useGetProjectTrialBalanceData(
    projectDetails?.AppDataInstanceId,
    projectDetails?.FiscalYear,
    projectId
  );

  useDeepCompareEffect(() => {
    if (!project || Object.keys(project).length < 1) return;
    setProjectDetails(project);
  }, [project])

  useEffect(() => {
    if (isTrialBalanceLoading || isTrialBalanceError) return;

    const processedTrialBalanceData = trialBalances
      ?.filter(tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId)
      .map(({ id, correlationName, correlationDetailId, correlationNameId }) => ({
        value: id,
        label: correlationName,
        correlationDetailId,
        correlationNameId
      }));

    setMenuItems(processedTrialBalanceData)
  }, [isTrialBalanceLoading, isTrialBalanceError])

  useEffect(() => {
    let answer = {}
      if (answers?.length) {
          answer = answers.find((answer) => answer.questionId?.toLowerCase() === field.id.toLowerCase())
      }
      
      setMatchingAnswer(answer);
  }, [answers])

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    let shouldHighlight = false

    if (matchingAnswer?.lastUpdate) {
      const dateModified = new Date(getFormattedSignOffDate(matchingAnswer.lastUpdate)).getTime();
      const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
      shouldHighlight = new Date(getFormattedSignOffDate(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate)).getTime() < dateModified
      setShouldHighlight(isSignoffEnabled && shouldHighlight)
    }
  }, [matchingAnswer, signOffList, field])

  useEffect(() => {
    if (defaultValues && Array.isArray(defaultValues) && isIdle) {
      const selectedValues = menuItems.filter((x) =>
        defaultValues.map((o) => o?.correlationNameId).includes(x?.correlationNameId)
      );
      setOpen(false)
      setTBDefaultValue(selectedValues?.map(x => x?.value))
    }
  }, [defaultValues, menuItems, isIdle])

  useDeepCompareEffect(() => {
    if (defaultValues && defaultValues?.length) {
      const selectedValues = menuItems.filter((x) =>
        defaultValues.map((o) => o?.correlationNameId).includes(x?.correlationNameId)
      );

      setTBDefaultValue(selectedValues?.map(x => x?.value))
    }
  }, [defaultValues, menuItems])

  const handleTrialBalanceDropdownSave = (e) => {
    if (isSingleSelect) {
      const value = e.target.value;
      const found = menuItems.find((item) => item.value === value);
      const userInput = [found];
      setSelectedTrialBalances([found.correlationDetailId]);
      handCaptureInput && handCaptureInput(userInput);
      return;
    }

    const userInput = [];
    const selectedItems = menuItems.filter((item) => e.includes(item.value));
    userInput.push(...selectedItems);
    setSelectedTrialBalances(userInput.map(({ correlationDetailId }) => correlationDetailId));
    
    if (JSON.stringify(matchingAnswer?.answer) !== JSON.stringify(selectedItems)) {
      setMatchingAnswer({...matchingAnswer, answer: selectedItems, lastUpdate: new Date().toISOString()})
    }
    handCaptureInput && handCaptureInput(userInput);

    if (isPlanningAnalyticsForm && JSON.stringify(defaultValues) !== JSON.stringify(userInput)) {
      setTrialBalances(userInput);
    }
  };

  // const correlcationDetailIds = () => {
  //   const selectedValues = menuItems.filter((x) =>
  //     defaultValues.map((o) => o?.correlationNameId).includes(x?.correlationNameId)
  //   );

  //   return !!selectedTrialBalances.length ? selectedTrialBalances : selectedValues?.map(({ correlationDetailId }) => correlationDetailId)
  // }

  return (
    <div className={styles.trialBalanceContainer}>
      <div className={clsx(styles.trialBalanceDropdownSelectContainer, shouldHighlight && highlightable && styles.trialBalanceDropdownSelectContainerHighlighted)}>
        <VFRenderedFieldWrapper isLockedByUser={isLockedByUser} isLocked={isLocked}>
          {isSingleSelect ? (
            <CLADropdown
              id={ID}
              name={TRIAL_BALANCE}
              label={TRIAL_BALANCE}
              value={tbDefaultValue[0]}
              placeholder={required ? PLACEHOLDER : `${PLACEHOLDER_WITH_OPTIONAL}`}
              onChange={handleTrialBalanceDropdownSave}
              options={menuItems}
              isDisabled={isDisabled}
              onFocus={onFocus}
              onOpen={() => updateMyPresence({ focusedId: field.id })}
              onClose={() => updateMyPresence({ focusedId: null })}
            />
          ) : (
            <CLASelect
              id={ID}
              name={TRIAL_BALANCE}
              label={TRIAL_BALANCE}
              defaultValues={tbDefaultValue}
              placeholder={required ? PLACEHOLDER : `${PLACEHOLDER_WITH_OPTIONAL}`}
              menuItems={menuItems}
              isDisabled={isDisabled}
              onChange={handleTrialBalanceDropdownSave}
              truncateDisplay
              onFocus={onFocus}
              open={open}
              onOpen={() => {
                updateMyPresence({ focusedId: field.id })
                setOpen(true)
                if (onOpen) {
                  onOpen();
                }
              }}
              onClose={() => {
                setOpen(false)
                updateMyPresence({ focusedId: null })
              }}
              isIdle={isIdle}
            />
          )}
        </VFRenderedFieldWrapper>
      </div>
      {/* Comment for now to hide this feature to the user */}
      {/* <div className={styles.trialBalanceExportToCSVContainer}>
        <ExportGLDetailLink
          correlationDetailIds={correlcationDetailIds()}
          visible={formTypeId === 3}
        />
      </div> */}
    </div>
  );
}

export default CLATrialBalanceSelect
import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import styles from './Section/VFSectionV2.module.css';
import { FIELD_TYPES, INTERNAL_CONTROLS_FORM_TYPE_ID, PLANNING_ANALYTICS_FORM_TYPE_ID } from "@ais/constants";
import {
  FreeText,
  LongAnswer,
  ShortAnswer,
  DropDown,
  RadioButtonList,
  CLADatePicker,
  Checkbox,
  Table,
  QuestionGroup,
  Formula,
  InstanceLongAnswerV2
} from '@ais/forms';
import ProcedureField from './ProcedureComponents/ProcedureField/ProcedureField';
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper";
import { useOthers } from "@components/Concurrency/store/users";
import { useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useLocking, useQuery } from '@hooks';
import { useFinalizedProjectNoId } from '@hooks/useProject';
import { useProjectFormInstanceProviderV2, useVFSignOffProvider } from '@providers';
import useRules from './hooks/useRules';
import { LinkIconComponent } from '@ais/assets';
import { VFInnerSection } from '@components/FormView/v2/Section';
import { PROJECT_FORM_INSTANCE } from '@constants/index';

const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
const StyledColumnField = styled('div')(({ width, sectionType, isEditMode }) => ({
  position: 'relative',
  padding: isEditMode ? 0 : sectionType === 'CUSTOM' ? '0 24px' : '32px',
  width: isEditMode ? '100%' : width,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const StyledLinkIcon = styled('div')(({ isLink }) => ({
  display: isLink ? 'flex' : 'none',
  cursor: 'pointer',
  marginLeft: '5px',
  width: 'fit-content',
  height: 'fit-content',
  marginTop: '0.25rem'
}));

const StyledFieldWrapper = styled('div')(({ isHighlight }) => ({
  borderStyle: isHighlight ? 'dashed' : '',
  borderWidth: isHighlight ? '4px' : '',
  borderColor: isHighlight && isHighlight === HIGHLIGHT_BORDER.RELATED ? '#49bfc1' : 'red',
  padding: isHighlight ? '8px' : '',
  width: '100%'
}));

export const NATGFieldSelector = React.memo((props) => {
  const { field, fieldIndex, rowIndex, auditAreaId, sectionId, sectionType } = props;
  const query = useQuery();
  const isProjectFinalized = useFinalizedProjectNoId();
  const isProcedureField = [FIELD_TYPES.PROCEDURE, FIELD_TYPES.CLIENT_SITUATIONS].includes(field.type);
  const { project, projectForm, answerList, questionsWithLink, userId, isSectionExpanded, isQuestionGroupIdle, sectionOnEditView,
    actions: { onLinkClick, onSubmit, onFocus, updateQuestionGroupIdle } } = useProjectFormInstanceProviderV2();
  const { modifiedAfterSignOff } = useVFSignOffProvider()
  const isEditMode = sectionOnEditView === sectionId;
  const frameworks = useMemo(() => (
    projectForm.frameworks ? projectForm.frameworks.map(f => f.FrameworkId) : []
  ), [projectForm.frameworks]);

  const performanceStandards = useMemo(() => (
    projectForm.performanceStandards ? projectForm.performanceStandards.map(ps => ps.PerformanceStandardId) : []
  ), [projectForm.performanceStandards]);

  const handleIsLockedSection = () => {
    const targetSection = isSectionExpanded.find(item => item.section === sectionId)
    return targetSection ? targetSection.isExpanded : false
  }
  const { shouldDisplay } = useRules(field.rules, answerList, projectForm.methodologyIndustries, frameworks, performanceStandards);

  const others = useOthers();
  const updateMyPresence = useUpdateMyPresence();
  const lockingUser = useLocking(field.id, userId);

  const handleIsLocked = () => {
    if (projectForm.formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID)
      return lockingUser && handleIsLockedSection();
    else if (projectForm.formTypeId === PLANNING_ANALYTICS_FORM_TYPE_ID) {
      //TODO: REMOVE HARD CODED IDS
      let _isLockedByUser = lockingUser;
      switch (field.id) {
        case '0142bfff-c7d2-4b79-832f-0091cbf08a56':
          _isLockedByUser = others.find((user) => ['a0b25f15-db65-4dbd-883e-33ba42dca581', '2329c1bb-368c-40d6-beda-3573ad72ae40'].includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
          break;
        case '4db6da62-c4cd-4d8c-9756-8a7b60f45df8':
          _isLockedByUser = others.find((user) => ['2329c1bb-368c-40d6-beda-3573ad72ac39', '6f3201ec-eb69-429d-96cc-2b80081484cf'].includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
          break;
        case '533137cd-c238-47b1-80e6-3fd71625116c':
          _isLockedByUser = others.find((user) => ['36fdd3c3-3fca-40d3-8e1b-adc82bc29ab2', 'f7184d4b-0d30-4143-ab9c-0da7fabe21f6'].includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
          break;
      }
      return _isLockedByUser;
    } else
      return lockingUser;
  }

  if (!shouldDisplay) {
    return <></>;
  }

  const radioBtnLabelStyle = projectForm.formTypeId === PLANNING_ANALYTICS_FORM_TYPE_ID ?
    { fontWeight: 'bold !important', color: '#2E334E !important' } : {};

  const questionIds = query.get('questionIds');
  const isHighlight = () => {
    const shouldHighlight = !!modifiedAfterSignOff?.find(signOff => signOff?.toLowerCase() === field.id?.toLowerCase()) && !isProcedureField
      ? HIGHLIGHT_BORDER.MODIFIED : questionIds?.split(',').indexOf(field.id) >= 0 ? HIGHLIGHT_BORDER.RELATED : null;
    return shouldHighlight
  }
  let questionId = field.id
  const isQuestionGroup = field.id.includes('.')
  if (isQuestionGroup) questionId = questionId.split('.')[0]
  const lockField = () => { updateMyPresence({ focusedId: questionId }) };
  const unlockField = () => { updateMyPresence({ focusedId: null }) };

  const renderLink = () => {
    return (
      onLinkClick && (
        <StyledLinkIcon isLink={questionsWithLink?.includes(field.id)}>
          <LinkIconComponent onClick={() => onLinkClick(field.id)} />
        </StyledLinkIcon>
      )
    );
  };

  const disabled = isProjectFinalized ||
    field.linkType === 'Read Only' ||
    lockingUser !== undefined ||
    others.find(user => user.presence.focusedId === questionId && user.info.userId.toLowerCase() !== userId.toLowerCase()) !== undefined

  const renderFields = () => {
    switch (field.type) {
      case FIELD_TYPES.FREETEXT:
      case FIELD_TYPES.FREETEXT_NO_RULES:
        return <FreeText {...props} />
      case FIELD_TYPES.FORMULA:
        return (
          <Formula
            {...props}
            disabled={disabled}
            answerList={answerList}
          />
        );
      case FIELD_TYPES.LONG_ANSWER:
        return <LongAnswer
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          renderLink={renderLink}
          project={project}
        />
      case FIELD_TYPES.INSTANCE_LONG_ANSWER:
        return <InstanceLongAnswerV2
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          renderLink={renderLink}
        />
      case FIELD_TYPES.CHECKBOXGROUP:
        return <Checkbox
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          renderLink={renderLink}
          isQuestionGroupIdle={isQuestionGroupIdle}
        />
      case FIELD_TYPES.DATE_PICKER:
        return <CLADatePicker
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          renderLink={renderLink}
        />
      case FIELD_TYPES.RADIOGROUP:
        return <RadioButtonList
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onFocus={onFocus}
          renderLink={renderLink}
          onSubmit={onSubmit}
          isQuestionGroupIdle={isQuestionGroupIdle}
          radioBtnLabelStyle={radioBtnLabelStyle}
        />
      case FIELD_TYPES.DROPDOWN:
        return <DropDown
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          answerList={answerList}
          onFocus={onFocus}
          renderLink={renderLink}
          onSubmit={onSubmit}
          isQuestionGroupIdle={isQuestionGroupIdle}
        />
      case FIELD_TYPES.SHORT_ANSWER:
        return <ShortAnswer
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          renderLink={renderLink}
          project={project}
        />
      case FIELD_TYPES.TABLE:
        return <Table
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
        />
      case FIELD_TYPES.QUESTION_GROUP:
        return <QuestionGroup
          {...props}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          fieldWrapper={VFInnerSection}
          updateQuestionGroupIdle={updateQuestionGroupIdle}
        />
      case FIELD_TYPES.CLIENT_SITUATIONS:
        return <ProcedureField
          auditAreaId={auditAreaId}
          field={field}
          disabled={disabled}
          handleOnFocus={onFocus}
          sectionId={sectionId}
        />
      default:
        return React.Fragment;
    }
  }
  return (

    <StyledColumnField width={`${field.width}%`} key={`field-row-${rowIndex}-${fieldIndex}`} sectionType={sectionType} isEditMode={isEditMode}>
      <StyledFieldWrapper isHighlight={isHighlight()}>
        <VFRenderedFieldWrapper
          className={styles.field__wrapper}
          isLockedByUser={lockingUser}
          isLocked={lockingUser && handleIsLocked()}
        >
          {renderFields()}
        </VFRenderedFieldWrapper>
      </StyledFieldWrapper >
    </StyledColumnField>
  )
})
export const assertionAcronymFormatter = (assertions, mainAssertion, primary = false, returnValueProperty = 'Acronym') => {    
    const newAssertion = assertions?.length > 0 && mainAssertion?.length > 0 && Array.isArray(assertions) ? assertions.reduce((acc, cur) => {
        const assertionExist = mainAssertion.find(a => a?.AssertionId === cur?.AssertionId);                    
        return assertionExist && assertionExist?.[returnValueProperty] ? [...acc, assertionExist[returnValueProperty]] : acc;            
    }, []) : [];

    if(primary) {
        return newAssertion.join(', ') 
        .trim()
        .split(',') 
        .map(word => word.trim().split(' ').map(w => w.toLowerCase() === 'and' ? w : w.charAt(0).toUpperCase() + w.slice(1)).join(' '))
        .join(', ');
    }
    return newAssertion.join(', ').trim().toLowerCase();
};
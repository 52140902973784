import React, { useMemo, useEffect } from 'react';
import { styled } from '@mui/system';
import { useFormContext } from "react-hook-form";

import { FIELD_TYPES, RESERVED_QUESTION_IDS } from "@ais/constants";
import styles from '@components/FormView/FormView.module.css';
import { useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useOthers } from "@components/Concurrency/store/users";
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper";
import useProjectContext from "@contexts/ProjectContext";
import { useFinalizedProjectNoId, useLocking } from '@hooks';
import { useProjectFormInstanceProviderV2, useVFSignOffProvider } from '@providers';
import {
  useProjectScope,
  CLAInitialScopingConsiderations,
  CLAProjectScopeAuditArea,
  CLATrialBalanceSelect,
  FrameworksDropdown,
  PerformanceStandardsDropdown,
  RiskCharacteristics
} from '@views/ProjectScopingForm';

const StyledVFRenderedFieldWrapper = styled(VFRenderedFieldWrapper)({
  padding: '0 24px',
});

export const ScopingFieldSelector = ({ field }) => {
  const { id, formTypeId, type } = field;
  const {
    answerList,
    projectForm: { projectFormID, methodologyVersionId, schema, methodologyIndustries },
    userId,
    actions: { onSubmit, onFocus }
  } = useProjectFormInstanceProviderV2();
  const { signOffUserList } = useVFSignOffProvider(); 
  const isProjectFinalized = useFinalizedProjectNoId();
  const { setValue, getValues } = useFormContext();
  const isLockedByUser = useLocking(id, userId);
  const others = useOthers();
  const { project } = useProjectContext();
  const {
    isDeterminingScotabdsExpanded,
    isInitialScopingConsiderationExpanded
  } = useProjectScope();
  const updateMyPresence = useUpdateMyPresence();
  const disabled = isProjectFinalized || isLockedByUser !== undefined;
  const projectScopeAuditAreaId = RESERVED_QUESTION_IDS.SCOPING[3];
  const trialBalanceFieldId = RESERVED_QUESTION_IDS.SCOPING[4];
  const isTrialBalanceOrAuditScopingFieldLock = (ids) => { return !!others.find((user) => ids.includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase()) }

  const methIndustries = useMemo(() =>
    methodologyIndustries?.map((mi) => mi.MethodologyIndustryId),
    [methodologyIndustries]
  );

  const renderFields = () => {
    switch (type) {
      case FIELD_TYPES.PERFORMANCE_STANDARDS: {
        return (
          <StyledVFRenderedFieldWrapper
            className={styles.field__wrapper}
            isLockedByUser={isLockedByUser}
            isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
          >
            <div className={styles.field__wrapper}>
              <div className={styles.field__wrapper_inner}>
                <PerformanceStandardsDropdown
                  methodologyVersionId={methodologyVersionId}
                  projectFormId={projectFormID}
                  disabled={disabled}
                  onFocus={() => {
                    onFocus();
                    updateMyPresence({ focusedId: id });
                  }}
                  onClose={() => {
                    updateMyPresence({ focusedId: null })
                  }}
                  signOffList={signOffUserList}
                />
              </div>
            </div>
          </StyledVFRenderedFieldWrapper>
        );
      }
      case FIELD_TYPES.REPORTING_FRAMEWORKS: {
        return (
          <StyledVFRenderedFieldWrapper
            className={styles.field__wrapper}
            isLockedByUser={isLockedByUser}
            isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
          >
            <div className={styles.field__wrapper}>
              <div className={styles.field__wrapper_inner}>
                <FrameworksDropdown
                  methodologyVersionId={methodologyVersionId}
                  projectFormId={projectFormID}
                  disabled={disabled}
                  onFocus={() => {
                    onFocus()
                    updateMyPresence({ focusedId: id })
                  }}
                  onClose={() => {
                    updateMyPresence({ focusedId: null })
                  }}
                  signOffList={signOffUserList}
                />
              </div>
            </div>
          </StyledVFRenderedFieldWrapper>
        );
      }
      case FIELD_TYPES.PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS: {
        return (
          <StyledVFRenderedFieldWrapper
            className={styles.field__wrapper}
            isLockedByUser={isLockedByUser}
            isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
          >
            <div className={styles.field__wrapper}>
              <div className={styles.field__wrapper_inner}>
                <CLAInitialScopingConsiderations
                  fieldId={id}
                  handCaptureInput={(userInput) => {
                    setValue(id, userInput);
                    onSubmit(id);
                  }}
                  methodologyVersionId={methodologyVersionId}
                  projectFormId={projectFormID}
                  disabled={disabled}
                  onOpen={() => {
                    onFocus()
                    updateMyPresence({ focusedId: id })
                  }}
                  onClose={() => {
                    updateMyPresence({ focusedId: null })
                  }}
                  signOffList={signOffUserList}
                  answerList={answerList}
                />
              </div>
            </div>
          </StyledVFRenderedFieldWrapper>
        );
      }
      case FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE: {
        const value = getValues(id);
        const defaultArrayValue = Array.isArray(value) ? value : value ? [value] : [];
        const isLock = isTrialBalanceOrAuditScopingFieldLock([id, projectScopeAuditAreaId]);
        return (
          <StyledVFRenderedFieldWrapper
            className={styles.field__wrapper}
            isLockedByUser={isLockedByUser}
            isLocked={isLockedByUser && isDeterminingScotabdsExpanded}
          >
            <div className={styles.field__wrapper_inner}>
              <CLATrialBalanceSelect
                defaultValues={defaultArrayValue}
                formTypeId={formTypeId}
                handCaptureInput={(userInput) => {
                  setValue(id, userInput);
                  onSubmit(id);
                }}
                field={field}
                isDisabled={disabled || isLock}
                project={project}
                onFocus={onFocus}
                signOffList={signOffUserList}
                highlightable={true}
              />
            </div>
          </StyledVFRenderedFieldWrapper>
        );
      }
      case FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA: {
        const isLock = isTrialBalanceOrAuditScopingFieldLock([field.id, trialBalanceFieldId]);

        let trialBalanceField;
        schema.some(s => s.fields.some(f => {
          trialBalanceField = f.find(ff => ff.id === trialBalanceFieldId);
          return trialBalanceField;
        }));

        return (
          <StyledVFRenderedFieldWrapper
            className={styles.field__wrapper}
            isLockedByUser={isLockedByUser}
            isLocked={isLockedByUser && isDeterminingScotabdsExpanded}
          >
            <div className={styles.field__wrapper}>
              <div className={styles.field__wrapper_inner}>
                <CLAProjectScopeAuditArea
                  field={field}
                  methodologyIndustries={methIndustries}
                  trialBalanceField={trialBalanceField}
                  disabled={disabled || isLock}
                  signOffList={signOffUserList}
                />
              </div>
            </div>
          </StyledVFRenderedFieldWrapper>
        );
      }
      case FIELD_TYPES.CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP: {
        return (
          <div className={styles.field__wrapper} style={{ padding: '0 24px' }}>
            <div className={styles.field__wrapper_inner}>
              <RiskCharacteristics
                projectFormId={projectFormID}
                methodologyVersionId={methodologyVersionId}
                methodologyIndustries={methIndustries}
                disabled={disabled}
                signOffList={signOffUserList}
                schema={schema}
              />
            </div>
          </div>
        )
      }
      default:
        return <></>;
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {renderFields()}
    </div>
  )
};

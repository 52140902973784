import { forwardRef } from 'react';
import { Button, Box } from '@mui/material';
import { UNDERSTANDING_OF_ENTITY } from "@ais/constants";

const KEY_CONTROLS_BUTTON_TYPE = {
    OPTIONAL: "Optional",
    REQUIRED: "Required"
}

export const ConcurrentKeyControlsButton = forwardRef(({
        className,
        onClick,
        isKeyControlRequired,
        shouldHighlight,
        disabled = false
    }, ref
    ) => {
    const buttonSettings = {
        style: {
            paddingLeft: 25,
            paddingRight: 25
        },
        textStyle: {
            fontWeight: 600,
            marginRight: 3,
        },
        status: {
            show: isKeyControlRequired !== undefined,
            text: isKeyControlRequired ? KEY_CONTROLS_BUTTON_TYPE.REQUIRED : KEY_CONTROLS_BUTTON_TYPE.OPTIONAL
        },
        text: UNDERSTANDING_OF_ENTITY.SCOTABDS.KEY_CONTROLS,
    }

    return (
        <div ref={ref} className={className}>
            <Box sx={shouldHighlight ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red'}: {}}>
                <Button disabled={disabled} variant="contained" onClick={onClick} style={buttonSettings.style}>
                    <span style={buttonSettings.textStyle}>{buttonSettings.text}</span>
                    {buttonSettings.status.show && <span>({buttonSettings.status.text})</span>}
                </Button>
            </Box>
        </div>
    )
})
import React, { useEffect, useContext } from 'react'
import { FIELD_TYPES } from '@ais/constants'
import styles from '@components/FormView/FormView.module.css';
import { useProjectFormInstanceProviderV2, useVFSignOffProvider } from '@providers';
import UOEAuditAreaTable from '@views/InternalControl/components/Sections/UnderstandingOfEntity/UOEAuditAreaTable';
import EvaluatingTheDesign from '@views/InternalControl/components/Sections/EvaluatingTheDesign/EvaluatingTheDesign';
import CommunicationOfSignificantMatters from '@views/InternalControl/components/Sections/CommunicationOfSignificantMatters/CommunicationOfSignificantMatters';
import { UnderstandingOfEntityProvider } from "@ais/contexts";
import { CommunicationOfSignificantsMattersContextProvider } from "@contexts/CommunicationOfSignificantsMattersContext";
import {
  SCHEMA_ACTION_TYPES
} from '@ais/constants';
import { SchemaContext } from '@ais/providers';

export const ICFFieldSelector = ({ field, isLocked }) => {
    const { type } = field
    const {
      projectForm: { projectFormID, schema },
      userId,
    } = useProjectFormInstanceProviderV2();
    const { signOffUserList } = useVFSignOffProvider(); 
    const { dispatchSchema } = useContext(SchemaContext);
    useEffect(() => {
      if(projectFormID) {
        try {
          const payload = {
            formSettings: null,
            properties: schema,
            disabledDeleteQuestionIds: [],
            formTypeId: 7,
          }
          dispatchSchema({
            type: SCHEMA_ACTION_TYPES.INITIALIZE_SCHEMA,
            payload,  
          })
        } catch (error) {
          console.log(error)
        }
      }
    },[])
    
    switch(type) {
        case FIELD_TYPES.UNDERSTANDING_OF_ENTITY:
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <UnderstandingOfEntityProvider>
                            <UOEAuditAreaTable 
                              signOffList={signOffUserList} 
                              userId={userId}
                              isLocked={isLocked}
                            />
                        </UnderstandingOfEntityProvider>
                    </div>
                </div>
            )
        case FIELD_TYPES.EVALUATING_KEY_CONTROL:
            return (<EvaluatingTheDesign
                        projectFormId={projectFormID}
                        userId={userId}
                        isLocked={isLocked}
                        signOffList={signOffUserList}
                    />)
        case FIELD_TYPES.COMMUNICATION_OF_SIGNIFICANT_MATTERS:
            return (<CommunicationOfSignificantsMattersContextProvider>
                    <CommunicationOfSignificantMatters 
                      userId={userId}
                      signOffList={signOffUserList}
                      isLocked={isLocked}
                      />
                </CommunicationOfSignificantsMattersContextProvider>)
        default: 
            return <></>

    }
}
import { useContext, useCallback, useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import { CLAAccordionPro } from '@ais/components';
import { Comment, CommentResolved } from '@ais/assets';
import { UnderstandingOfEntityContext } from "@ais/contexts";
import { useKeyControlDrawerContext } from '@contexts/KeyControlDrawer/KeyControlDrawerContext'
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import FinanceStatementProcessContent from "./FinanceStatementProcessContent.jsx";
import styles from './UOEAuditAreaCollapsibles.module.css';
import { ConcurrentKeyControlsButton } from '../../../ConcurrentKeyControlsButton.jsx'
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';
import { useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useOthers } from '@components/Concurrency/store/users';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useProjectFormInstanceProvider } from '@providers';

const UOELabelsConstants = UNDERSTANDING_OF_ENTITY.AUDIT_AREAS.FINANCIAL_STEMENT_PROCESS;

export const FinanceStatementProcess = (
    {
        financeStatementData,
        saveInternalControlData,
        isProjectFinalized,
        signOffList,
        userId, 
        isLocked
    }
) => {
    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();
    const {
        formattedInternalControls
    } = useInternalControlsContext();

    const financialStatementICComment = formattedInternalControls?.FinancialStatementICComment;
    const { state: { openComment }, setOpenComment } = useContext(UnderstandingOfEntityContext)
    const isCommentOpen = openComment === UOELabelsConstants.COMMENT_KEY
    const { projectFormId } = useParams();
    const { openDrawer, initializeInternalControl } = useKeyControlDrawerContext();
    const [commentHighlighted, setCommentHighlighted] = useState(false)
    const [keyControlButtonHighlighted, setKeyControlButtonHighlighted] = useState(false)
    const toggleCommentOpen = () => {
        setOpenComment(UOELabelsConstants.COMMENT_KEY)
    }

    const onKeyControlClick = useCallback(() => {
        openDrawer('financial-statement-key-controls')
        updateMyPresence({ focusedId: 'financial-statement-key-controls', type: 'custom' })
        initializeInternalControl({
            projectFormId,
            isJournalEntry: false,
            isFinancialStatement: true,
            internalControlData: formattedInternalControls
        });
    }, [projectFormId, formattedInternalControls])

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
        const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
        let shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
        setCommentHighlighted(isSignoffEnabled && shouldHighlight)

        if(formattedInternalControls?.KeyControlHistory?.length > 0){
            const financialStatement = formattedInternalControls?.KeyControlHistory.filter((kc) => {
                return kc.IsFinancialStatement
            })
            if(financialStatement.length > 0){
                const dateModified = new Date(financialStatement[0].ValidTo).getTime();
                const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
                setKeyControlButtonHighlighted(isSignoffEnabled && shouldHighlight)
            }
        }else{
            if(formattedInternalControls?.ProjectInternalControlProjectKeyControl?.length > 0){
                const financialStatement = formattedInternalControls.ProjectInternalControlProjectKeyControl.filter((kc) => {
                    return kc.IsFinancialStatement
                })
                if(financialStatement.length > 0){
                    const dateModified = new Date(financialStatement[0]?.ValidFrom + "Z").getTime();
                    const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                    shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
                    setKeyControlButtonHighlighted(isSignoffEnabled && shouldHighlight)
                }
                  
            }
        }
    }, [formattedInternalControls, signOffList])

    return (
        <CLAAccordionPro.Root>
            <CLAAccordionPro
                id='financialStatementProcess'
                customClassName={{ container: 'UOEContainer', accordion: 'UOEAccordion' }}
                disableTransitions={true}
                expandedPanel={isCommentOpen ? 'financialStatementProcess' : undefined}
            >
                <CLAAccordionPro.Trigger
                    id='financialStatementProcess'
                    customClassName={{ title: '', subtitle: 'flex100' }}
                    styles={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            '&.Mui-expanded': {
                                transform: 'none'
                            }
                        }
                    }}
                >
                    <div className={styles.headerContainer}>
                        <div className={styles.gridContainer}>
                            <div className={styles.rowTitle}>
                                Financial Statement Close and Reporting Process
                            </div>
                            <div className={styles.rowIcon}>
                                <Box sx={commentHighlighted ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p:'4px'}: {}}>
                                    <IconButton sx={{ p: 0 }} onClick={toggleCommentOpen}>
                                        {
                                            (!!financialStatementICComment && financialStatementICComment.length > 0)
                                                ? <CommentResolved />
                                                : <Comment />
                                        }
                                    </IconButton>
                                </Box>
                            </div>
                            <VFRenderedFieldWrapper
                                className={`${styles.rowButton} ${styles["field__wrapper"]}`}
                                isLockedByUser={others.find((user) => user.presence.focusedId === `financial-statement-key-controls` && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                                isLocked={isLocked}
                                disablePortal={true}
                            >
                                <ConcurrentKeyControlsButton 
                                    onClick={onKeyControlClick} 
                                    customFormObjectId={`financial-statement-key-controls`}
                                    disabled={!!others.find((user) => user.presence.focusedId === `financial-statement-key-controls` && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                                    shouldHighlight={keyControlButtonHighlighted}
                                />
                            </VFRenderedFieldWrapper>
                        </div>
                    </div>

                </CLAAccordionPro.Trigger>

                <CLAAccordionPro.Panel>
                    <FinanceStatementProcessContent
                        financeStatementData={financeStatementData}
                        isCommentOpen={isCommentOpen}
                        saveInternalControlData={saveInternalControlData}
                        isProjectFinalized={isProjectFinalized}
                        signOffList={signOffList}
                        userId={userId}
                        isLocked={isLocked}
                    />
                </CLAAccordionPro.Panel>
            </CLAAccordionPro>
        </CLAAccordionPro.Root>
    );
}

export default FinanceStatementProcess;
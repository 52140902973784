import React from 'react';
import { Flex } from "@ais/palette";
import {
  Checkbox as MUICheckbox,
  Grid,
  FormLabel,
  FormControlLabel,
  styled
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CheckBoxOtherOption from './CheckBoxOtherOption';
import { renderLabel } from '@ais/forms';


const CheckboxGrid = styled(Grid)`
  min-width: 150px;
  margin-top: -1px;
`;


const CheckboxGroupWrapper = styled('div')`
  margin-top: 12px;
`;


const Checkbox = ({
  field: {
    allowOtherOption,
    columns,
    options,
    label,
    id,
    tooltip
  },
  disabled,
  clientExternalIcon = null,
  lockField,
  unlockField,
  onSubmit,
  onFocus,
  renderLink,
  isQuestionGroupIdle
}) => {
  const { control } = useFormContext();
  return (
    <CheckboxGroupWrapper>
      <Flex direction="row" justify="between" style={{ width: '100%' }}>
        <FormLabel>
          {renderLabel(label, tooltip, renderLink)}
        </FormLabel>
        {clientExternalIcon}
      </Flex>
      <CheckboxGrid container spacing={2} columns={columns}>
        {options.map((val) => (
          <Controller
            key={val}
            name={id}
            control={control}
            render={({ field }) => (
              <CheckboxGrid item xs={1}>
                <FormControlLabel
                  control={
                    <MUICheckbox
                      {...field}
                      checked={Array.isArray(field.value) ? field.value.includes(val) : field.value === val}
                      onChange={(e) => {
                        const value = field.value || [];
                        if (e.target.checked) {
                          field.onChange([...value, val]);
                        } else {
                          field.onChange(value.filter(v => v !== val));
                        }
                        onSubmit(id)
                      }}
                      inputProps={{
                        "test-id": `Checkbox-${id}`,
                        "test-value": field.value
                      }}
                      value={val}
                      disabled={disabled}
                    />
                  }
                  label={val}
                />
              </CheckboxGrid>
            )}
          />
        ))}
        <CheckBoxOtherOption
          allowOtherOption={allowOtherOption}
          id={id}
          options={options}
          disabled={disabled}
          lockField={lockField}
          unlockField={unlockField}
          onSubmit={onSubmit}
          onFocus={onFocus}
          isQuestionGroupIdle={isQuestionGroupIdle}
        />
      </CheckboxGrid>
    </CheckboxGroupWrapper>
  );
};


export default React.memo(Checkbox);


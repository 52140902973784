import { useState, useEffect, memo, useMemo, Fragment, useCallback, useRef } from 'react';
import {
  Box,
  OutlinedInput,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Button,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { sanitize } from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { FIELD_TYPES } from '@ais/constants';
import {
  CLACheckBoxGroup,
  CLAComboBox,
  CLACurrencyField,
  CLADatePickerField,
  CLADropdown,
  CLAFormTable,
  CLANumberField,
  CLAPercentageField,
  CLARadioButtonList,
  CLASelect,
  ClientExternalIcon,
  parseQuestionLabel,
  renderLabel,
  getInstanceFields,
} from '@ais/forms';
import { CLADialogConfirm } from '@ais/components';
import { useAppTypeProvider } from '@ais/providers';
import { DeleteIconComponent } from '@ais/assets';

import questionGroupStyle from './VFQuestionGroup.module.css';
import formViewStyle from '../../FormView.module.css';

// * READ FROM BOTTOM TO TOP

const getDefaultValues = (field) => {
  const type = field.type;
  const defaultValue = field.defaultValue || '';
  if (
    type === FIELD_TYPES.CHECKBOXGROUP ||
    (type === FIELD_TYPES.DROPDOWN && field?.allowMultiSelect)
  )
    return [defaultValue].filter(Boolean);
  if (type === FIELD_TYPES.TABLE) {
    const columns = [];
    field.columns.forEach((col) => {
      const value =
        col.dropdownDefaultValue ?? col.radioDefaultValue ?? col.checkboxCheckedByDefault;
      if (value) columns.push({ id: col.id, value });
    });
    const value = field.rows.map((row) => ({
      id: row.id,
      rowLabel: row.rowLabel,
      values: columns,
    }));
    return value;
  } else return defaultValue;
};
const QuestionGroupTextField = (props) => {
  const { disabled, field, isIdle, value: _value, onBlur, onFocus } = props;
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [value, setValue] = useState(_value);

  useEffect(() => {
    setValue(_value ?? "");
    if (isIdle) {
      containerRef.current?.classList.remove('Mui-focused');
      inputRef.current?.blur();
    }
  }, [isIdle, _value])

  return (
    <OutlinedInput
      key={`${field.id}-${_value}`}
      inputRef={inputRef}
      ref={containerRef}
      multiline
      minRows={field.type === FIELD_TYPES.SHORT_ANSWER ? 1 : field.minRows}
      maxRows={field.type === FIELD_TYPES.SHORT_ANSWER ? 1 : 20}
      label={field.label ? renderLabel(field.label, field.tooltip) : null}
      placeholder={field.placeholder}
      inputProps={{
        maxLength: field.type === FIELD_TYPES.SHORT_ANSWER ? 256 : 4096,
      }}
      disabled={disabled}
      value={value ?? ""}
      onBlur={onBlur}
      sx={
        field.type === FIELD_TYPES.SHORT_ANSWER
          ? {
            '& textarea': {
              whiteSpace: 'nowrap',
              resize: 'none',
              overflowX: 'hidden',
            },
          }
          : {}
      }
      onChange={(e) => { setValue(e.target.value) }}
      notched
      onKeyPress={(e) => {
        if (field.type === FIELD_TYPES.SHORT_ANSWER && e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onFocus={onFocus}
    />
  )
}
const VFQuestionGroupTextFields = memo((props) => {
  const { field, handleChange, value, disabled, handleFocus, isIdle } = props;

  return (
    <FormControl fullWidth>
      {field.label && (
        <InputLabel shrink variant="multiline-label">
          {renderLabel(field.label, field.tooltip)}
        </InputLabel>
      )}
      {field.validation === 'none' && (
        <QuestionGroupTextField
          key={`${field.id}-${value}`}
          field={field}
          disabled={disabled}
          value={value}
          onBlur={(e) => {
            if (isIdle) return;
            handleChange(e.target.value)
          }}
          onFocus={handleFocus}
          isIdle={isIdle}
        />
      )}
      {field.validation === 'numerical' && (
        <CLANumberField
          label={renderLabel(field.label, field.tooltip)}
          placeholder={field.placeholder}
          notched
          disabled={disabled}
          digitGroupSeparator=","
          defaultValue={value}
          onBlur={(_, value) => {
            if (isIdle) return;
            handleChange(value)
          }}
          isInstance={false}
          tbLinkEnabled={false}
          onFocus={handleFocus}
          isIdle={isIdle}
        />
      )}
      {field.validation === 'usCurrency' && (
        <CLACurrencyField
          label={renderLabel(field.label, field.tooltip)}
          placeholder={field.placeholder}
          notched
          disabled={disabled}
          defaultValue={value}
          onBlur={(_, value) => {
            if (isIdle) return;
            handleChange(value)
          }}
          isInstance={false}
          tbLinkEnabled={false}
          onFocus={handleFocus}
          isIdle={isIdle}
        />
      )}
      {field.validation === 'percentage' && (
        <CLAPercentageField
          label={renderLabel(field.label, field.tooltip)}
          placeholder={field.placeholder}
          notched
          disabled={disabled}
          value={value}
          onBlur={(_, value) => {
            if (isIdle) return;
            handleChange(value)
          }}
          onFocus={handleFocus}
          isIdle={isIdle}
        />
      )}
    </FormControl>
  );
});

const VFQuestionGroupRenderedField = memo((props) => {
  const {
    field,
    values,
    disabled,
    onChange,
    isInstance,
    questionGroupChildProps,
    onFocus,
    onSubmit,
    onDropDownClose,
    isIdle = false
  } = props;
  const row = values[questionGroupChildProps.questionGroupRowIndex];
  const value = row?.find((r) => r.id === field.id)?.value;

  const handleChange = (value, isOnBlur = false) => {
    onChange &&
      onChange(questionGroupChildProps.questionGroupRowIndex, field.id, {
        id: field.id,
        index: questionGroupChildProps.questionGroupFieldIndex,
        value,
      }, [FIELD_TYPES.DROPDOWN, FIELD_TYPES.DATE_PICKER].includes(field.type) ? isOnBlur : true);
  };

  const handleFocus = () => onFocus && onFocus();
  const handleOnDropDownClose = () => onDropDownClose?.();

  const renderField = () => {
    switch (field.type) {
      case FIELD_TYPES.FREETEXT: {
        return (
          <Typography variant="freeText">
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: sanitize(field.text, { ADD_ATTR: ['target'] }),
              }}
            />
          </Typography>
        );
      }
      case FIELD_TYPES.SHORT_ANSWER:
      case FIELD_TYPES.LONG_ANSWER: {
        return (
          <VFQuestionGroupTextFields
            {...props}
            handleChange={handleChange}
            value={value}
            handleFocus={handleFocus}
          />
        );
      }
      case FIELD_TYPES.DROPDOWN: {
        return (
          <>
            {field.allowOtherOption && (
              <CLAComboBox
                label={renderLabel(field.label, field.tooltip)}
                options={field.options}
                allowMultiSelect={field.allowMultiSelect}
                allowMultiLineLabel
                isDisabled={disabled}
                defaultValue={value ?? ''}
                onBlur={(values) => {
                  if (isIdle) return;
                  handleChange(values, true);
                }}
                onChange={(newValue, e, reason) => {
                  handleChange(newValue);
                  if (
                    reason === 'removeOption' &&
                    (e.target.tagName === 'path' || e.target.tagName === 'svg') &&
                    isInstance
                  )
                    onSubmit();
                }}
                onFocus={handleFocus}
                enableOnChangeHandler={false}
                isQuestionGroupField
                isIdle={isIdle}
              />
            )}
            {!field.allowOtherOption && !field.allowMultiSelect && (
              <CLADropdown
                name={field.id}
                label={renderLabel(field.label, field.tooltip)}
                options={field.options}
                allowMultiLineLabel
                isDisabled={disabled}
                value={value}
                onClose={(e, value) => {
                  if (isIdle) return;
                  handleOnDropDownClose(e, value)
                }}
                onOpen={handleFocus}
                onChange={(e) => {
                  handleChange(e.target.value)
                }}
                isIdle={isIdle}
              />
            )}
            {!field.allowOtherOption && field.allowMultiSelect && (
              <CLASelect
                id={field.id}
                name={field.id}
                label={renderLabel(field.label, field.tooltip)}
                menuItems={field.options}
                isDisabled={disabled}
                allowMultiLineLabel
                defaultValues={value}
                onChange={(newValue) => handleChange(newValue)}
                onOpen={handleFocus}
                onClose={(e) => {
                  if(isIdle) return;
                  handleOnDropDownClose(e)
                }}
                isIdle={isIdle}
              />
            )}
          </>
        );
      }
      case FIELD_TYPES.RADIOGROUP: {
        return (
          <CLARadioButtonList
            id={field.id}
            label={renderLabel(field.label, field.tooltip)}
            columns={field.columns}
            options={field.options}
            isDisabled={disabled}
            allowOtherOption={field.allowOtherOption}
            value={value}
            onChange={value => {
              handleChange(value);
              if (isInstance) onSubmit();
            }}
            onFocus={handleFocus}
            isIdle={isIdle}
          />
        );
      }
      case FIELD_TYPES.CHECKBOXGROUP: {
        return (
          <CLACheckBoxGroup
            id={field.id}
            label={renderLabel(field.label, field.tooltip)}
            columns={field.columns}
            options={field.options}
            allowOtherOption={field.allowOtherOption}
            value={value}
            isDisabled={disabled}
            onChange={(newValue, otherValue, otherToggled) => {
              const actualValue = [...newValue];
              if (otherValue) actualValue.push(otherValue);
              handleChange(actualValue);
              if (!otherToggled && isInstance) onSubmit();
            }}
            onFocus={handleFocus}
            isIdle={isIdle}
          />
        );
      }
      case FIELD_TYPES.DATE_PICKER: {
        return (
          <CLADatePickerField
            id={field.id}
            label={renderLabel(field.label, field.tooltip)}
            placeholder={field.placeholder}
            allowMultiLineLabel
            disabled={disabled}
            defaultValue={value}
            onFocus={handleFocus}
            isQuestionGroup
            onBlur={(value, isOnBlur) => {
              if (isIdle) return
              const newValue = value ?? null;
              handleChange(newValue, isOnBlur);
            }}
            isIdle={isIdle}
          />
        );
      }
      case FIELD_TYPES.TABLE: {
        const parsed = parseQuestionLabel(field.label);
        const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
        return (
          <div className={formViewStyle['vf-section-table-container']}>
            <div className={formViewStyle['vf-section-table-label-wrapper']}>
              <InputLabel className={formViewStyle['vf-section-table-label']}>
                {renderLabel(label, field.tooltip)}
              </InputLabel>
            </div>
            <CLAFormTable
              field={field}
              values={value}
              disabled={disabled}
              onChange={(newValue) => handleChange(newValue)}
              isInstance={isInstance}
              onFocus={handleFocus}
              isIdle={isIdle}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={questionGroupStyle.renderedFieldWrapper} style={{ width: `${field.width}%` }}>
      {renderField()}
    </div>
  );
});

const VFQuestionGroupButtons = memo((props) => {
  const { isInstance, onAdd, onDelete, deleteDisabled, rowIndex, onFocus } = props;

  const { isPortalApp } = useAppTypeProvider();

  const [visible, setVisible] = useState(false);

  const handleAdd = () => {
    if (!isPortalApp) onFocus();
    onAdd && onAdd(rowIndex + 1);
  };

  const handleAttemptDelete = () => {
    if (!isPortalApp) onFocus();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDelete = () => {
    if (onDelete && !deleteDisabled) {
      onDelete(rowIndex);
      setVisible(false);
    }
  };

  if (!isInstance) return null;

  return (
    <Grid container px="40px" py="20px">
      <Grid container item xs={6} alignItems="center">
        <Button
          onClick={handleAdd}
          startIcon={<AddIcon />}
          variant="page-button-add-form"
          sx={{ m: 0, px: 0 }}
        >
          ADD ANOTHER
        </Button>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <IconButton onClick={handleAttemptDelete} disabled={deleteDisabled}>
          <DeleteIconComponent
            style={{ width: '20px', height: '30px', opacity: deleteDisabled ? 0.3 : 1 }}
          />
        </IconButton>
      </Grid>
      <CLADialogConfirm
        visible={visible}
        title="Warning"
        cancelText="Cancel"
        confirmText="Delete"
        message="Are you sure you want to delete this?"
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
    </Grid>
  );
});

const VFQuestionGroupInner = memo((props) => {
  const { field, instanceRows, values, disabled, rhfOnChange, isInstance, answered, onFocus, isIdle } =
    props;

  const handleAdd = (newRowIndex) => {
    const toAdd = [];
    instanceRows[0].forEach((field, fieldIndex) => {
      const newField = {};
      newField.index = fieldIndex;
      newField.id = uuidv4();
      newField.value = getDefaultValues(field);
      toAdd.push(newField);
    });
    const newValues = [...values];
    newValues.splice(newRowIndex, 0, toAdd);
    rhfOnChange(newValues);
  };

  const handleDelete = (rowIndex) => {
    const newValues = [...values];
    newValues.splice(rowIndex, 1);
    rhfOnChange(newValues);
  };

  return (
    <div className={questionGroupStyle.questionGroupInner}>
      <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
        <ClientExternalIcon
          allowExternalAccess={field.visibleToClient ?? false}
          answerable={field.editableByClient ?? false}
          answered={answered}
        />
      </Box>
      {!isInstance &&
        instanceRows.map((questionGroupRow, questionGroupRowIndex) => {
          return (
            <Fragment key={questionGroupRowIndex}>
              {questionGroupRowIndex > 0 && isInstance && <Box as="hr" mx="25px" />}
              <div className={questionGroupStyle.rowField}>
                {questionGroupRow.map((questionGroupField, questionGroupFieldIndex) => {
                  return (
                    <VFQuestionGroupRenderedField
                      key={questionGroupField.id}
                      {...props}
                      field={questionGroupField}
                      questionGroupChildProps={{
                        parentField: field,
                        isChild: true,
                        questionGroupRowIndex: questionGroupRowIndex,
                        questionGroupFieldIndex: questionGroupFieldIndex,
                      }}
                    />
                  );
                })}
              </div>
            </Fragment>
          );
        })}
      {isInstance &&
        values.map((value, questionGroupRowIndex) => {
          return (
            <Fragment key={questionGroupRowIndex}>
              {questionGroupRowIndex > 0 && isInstance && <Box as="hr" mx="25px" />}
              <div className={questionGroupStyle.rowField}>
                {instanceRows[0].map((questionGroupField, questionGroupFieldIndex) => {
                  const found = Object.values(value).find(
                    (v) => v.index === questionGroupFieldIndex
                  );
                  return (
                    <VFQuestionGroupRenderedField
                      key={!found ? questionGroupField.id : found.id}
                      {...props}
                      field={
                        !found
                          ? questionGroupField
                          : {
                              ...questionGroupField,
                              id: found.id,
                            }
                      }
                      questionGroupChildProps={{
                        parentField: field,
                        isChild: true,
                        questionGroupRowIndex: questionGroupRowIndex,
                        questionGroupFieldIndex: questionGroupFieldIndex,
                      }}
                      onFocus={onFocus}
                      isIdle={isIdle}
                    />
                  );
                })}
              </div>
              {isInstance && !disabled && (
                <VFQuestionGroupButtons
                  isInstance={isInstance}
                  onAdd={handleAdd}
                  onDelete={handleDelete}
                  deleteDisabled={values.length <= 1}
                  rowIndex={questionGroupRowIndex}
                  onFocus={onFocus}
                />
              )}
            </Fragment>
          );
        })}
    </div>
  );
});

export const VFQuestionGroup = memo((props) => {
  const {
    field,
    isInstance,
    onChange,
    handleOnFocus,
    updateIsFormFocused,
    answerValues: _answerValues = [],
  } = props;

  const instanceFieldRows = useCallback(getInstanceFields(field), [field]);

  const [values, setValues] = useState([]);

  const answerValues = useMemo(() => _answerValues, [_answerValues]);

  const parsed = useMemo(() => parseQuestionLabel(field.label), [field.label]);
  const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;

  const handleChangeValues = (rowIndex, id, value, isOnBlur) => {
    const newState = [...values];
    const found = newState[rowIndex]?.find((r) => r.id === id);
    if (found) {
      const newMapped = newState[rowIndex].map((n) => {
        if (n.index === found.index) return value;
        else return n;
      });
      newState[rowIndex] = newMapped;
    } else newState[rowIndex].push(value);
    setValues(newState);
    isInstance && onChange(newState, isOnBlur);
  };

  const handleFocus = () => {
    if (updateIsFormFocused) updateIsFormFocused(true);
    if (handleOnFocus) handleOnFocus();
  };

  // * Get default values in the schema if in View Form
  useEffect(() => {
    if (!isInstance) {
      const defaultAnswers = [];
      const toAdd = [];
      instanceFieldRows[0].forEach((field, fieldIndex) => {
        const newField = {};
        newField.index = fieldIndex;
        newField.id = field.id;
        newField.value = getDefaultValues(field);
        toAdd.push(newField);
      });
      defaultAnswers.push(toAdd);
      setValues(defaultAnswers);
    }
  }, [instanceFieldRows, isInstance]);

  useEffect(() => {
    if (isInstance) setValues(answerValues);
  }, [answerValues, isInstance]);

  return (
    <fieldset className={questionGroupStyle.fieldset}>
      <legend className={questionGroupStyle.legend}>{label}</legend>
      <VFQuestionGroupInner
        {...props}
        instanceRows={instanceFieldRows}
        values={isInstance ? (Array.isArray(answerValues) ? answerValues : []) : values}
        onChange={handleChangeValues}
        onFocus={handleFocus}
        rhfOnChange={onChange}
      />
    </fieldset>
  );
});

VFQuestionGroup.propTypes = {
  field: PropTypes.object,
  isInstance: PropTypes.bool,
  onChange: PropTypes.func,
  answerValues: PropTypes.array,
  answered: PropTypes.bool,
};

export default VFQuestionGroup;

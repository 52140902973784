import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Typography,
    TextField
} from '@mui/material';

import { Comment as NoComment, CommentResolved as WithComment } from '@ais/assets';
import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";

import CLASCOTABDsCollapsible from './CLASCOTABDsCollapsible';
import { useAccountAssertionLevelRiskState } from './AccountAssertionLevelRiskContext';
import formServices from '@services/forms/forms';
import styles from './CLAAccountAssertionLevelRisk.module.css';
import { boxAuditAreaName, boxRowStyles, buttonStyles, typoAuditAreaName, typoSCOTABDS } from './muiStyles';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import CustomToast from '@components/CustomToast/CustomToast';
import { PROJECT_FORM_INSTANCE } from '@constants/index';

import { useUpdateMyPresence, useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from '@components/Concurrency/store/users';
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper"
import { useRiskAssessment } from '../../hooks/useRiskAssessment'; 
const CLAAuditAreaCollapsible = (props) => {
    const { ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
    const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
    const {
        auditArea,
        scotabdList,
        index,
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        financialRisks,
        disabled,
        focusedId,
        setFocusedId,
        userId
    } = props;

    const { updateChildExpanded, isChildExpanded  } = useAccountAssertionLevelRiskState();
    const expandCutomId = `CLAAuditAreaCollapsible-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}`;
	const isExpanded = isChildExpanded(expandCutomId);
    const [showComment, setShowComment] = useState(false);
    const [commentValue, setCommentValue] = useState(auditArea?.riskAssessmentComment ?? "");
    const [isValueChanged, setIsValueChanged] = useState(false);
    const [loadingProjectScopeAuditArea, setLoadingProjectScopeAuditArea] = useState(false)
    const [successProjectScopeAuditArea, setSuccessProjectScopeAuditArea] = useState(false)
    const [errorProjectScopeAuditArea, setErrorProjectScopeAuditArea] = useState(false)
    const [shouldHighlight, setShouldHighlight] = useState();
    const { projectId, projectFormId } = useParams();
    const activeElementRef = useRef(null)

    const others = useOthers();
    const updateMyPresence = useUpdateMyPresence();
    const isIdle = useRoomIdle()
    const { 
        auditAreaWithComment, 
        concurrencyEventReceived,
        isAssertionLevelRiskExpanded,
        modifiedRiskAssessmentSummaryAfterSignOff,
        actions: {
            updateAnswerUpdateToggle
        }
    } = useRiskAssessment();

    const handleSaveComment = async () => {
        setLoadingProjectScopeAuditArea(true);
        updateAnswerUpdateToggle();
        const data = {
            ProjectScopeAuditAreaId: auditArea.projectScopeAuditAreaId,
            ProjectScopeId: auditArea.projectScopeId,
            AuditAreaId: auditArea.auditAreaId,
            AuditAreaName: auditArea.auditAreaName,
            RiskAssessmentComment: commentValue,
            RequestProjectFormId: projectFormId
        }

        const result = await formServices.updateProjectScopeAuditArea(projectId, auditArea.projectScopeAuditAreaId, data);

        if (result?.status === 200) {
            auditArea.riskAssessmentComment = commentValue;
            setShowComment(false);
            setLoadingProjectScopeAuditArea(false);
            setSuccessProjectScopeAuditArea(true)
        } else {
            setLoadingProjectScopeAuditArea(false);
            setErrorProjectScopeAuditArea(true)
        }
    }

    const applyClass = () => {
        let classList = [styles['comment-box-button-wrapper']];
        if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED) classList.push(styles['highlight-modified']);
        return classList.join(' ');
    };

    const displayCommentIcon = () => auditArea?.riskAssessmentComment ? <WithComment /> : <NoComment />

    useEffect(()=>{
        updateChildExpanded(expandCutomId, false);
    },[])

    const handleSaveButtonClick = () => {
        handleSaveComment();
        setIsValueChanged(false);
        if (focusedId === `RiskAssessmentComment-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}`) {
            setFocusedId(null)
            updateMyPresence({ focusedId: null, type: 'custom' });
        }
    }

    const handleCancelButtonClick = () => {
        setShowComment(false);
        setIsValueChanged(false);
        setCommentValue(auditArea.riskAssessmentComment);
        if (focusedId === `RiskAssessmentComment-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}`) {
            setFocusedId(null)
            updateMyPresence({ focusedId: null, type: 'custom' });
        }
    }

    useEffect(() => {
        if (modifiedRiskAssessmentSummaryAfterSignOff) {
            const shouldHighlight = !!modifiedRiskAssessmentSummaryAfterSignOff
                .find(signOff => signOff.auditAreaId === auditArea.auditAreaId && signOff.projectScopeAuditAreaId === auditArea.projectScopeAuditAreaId) ? HIGHLIGHT_BORDER.MODIFIED : null;

            setShouldHighlight(shouldHighlight);
        }
    }, [modifiedRiskAssessmentSummaryAfterSignOff]);

    useEffect(() => {
        if (isIdle) {
            activeElementRef.current?.blur()
        }
    }, [isIdle])
    useEffect(() => {
        if (concurrencyEventReceived) {
            const concurrencyComment = auditAreaWithComment.find(comment =>
                comment.auditAreaId === auditArea.auditAreaId) &&
                auditAreaWithComment.find(comment => comment.auditAreaId === auditArea.auditAreaId
                    && comment.projectScopeAuditAreaId === auditArea.projectScopeAuditAreaId).riskAssessmentComment;
            setCommentValue(concurrencyComment)
        }
    }, [concurrencyEventReceived, auditAreaWithComment])

    useEffect(() => {
        setCommentValue(auditArea.riskAssessmentComment);
    }, [auditArea?.riskAssessmentComment])

    useEffect(() => {
        if (focusedId && focusedId !== `RiskAssessmentComment-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}`) {
            setCommentValue(auditArea.riskAssessmentComment ?? '');
        }
    }, [focusedId])
    return (
        <Box>
            <Box sx={boxRowStyles}>
                <div className={styles['collapse-icon-wrapper']}>
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={() => { updateChildExpanded(expandCutomId, !isExpanded) }}>
                        {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                    </IconButton>
                </div>
                <div className={`${styles.column} ${styles['first-auditarea-column']} ${index > 0 && styles['row-border']}`}>
                    <Box sx={{
                        ...(boxRowStyles),
                        width: '100%'
                    }}>
                        <Box sx={boxAuditAreaName}>
                            <Typography sx={typoAuditAreaName}>
                                {auditArea?.auditAreaName}
                            </Typography>
                        </Box>
                    </Box>
                </div>
                <div className={`${styles.column} ${styles['second-auditarea-column']} ${index > 0 && styles['row-border']}`}>
                    <Box sx={{
                        flexShrink: 0,
                        width: '100px',
                        ml: '30px'
                    }}
                        className={applyClass()}
                    >
                        {!showComment && <IconButton
                            sx={{ padding: 0 }}
                            onClick={() => setShowComment(true)}
                        >
                            {displayCommentIcon()}
                        </IconButton>}
                        {showComment && displayCommentIcon()}

                    </Box>
                </div>
            </Box>
            {
                showComment &&
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={(others.find((user) => user.presence.focusedId === `RiskAssessmentComment-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}` && user.info.userId.toLowerCase() !== userId.toLowerCase()))}
                    isLocked={(others.find((user) => user.presence.focusedId === `RiskAssessmentComment-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}` && user.info.userId.toLowerCase() !== userId.toLowerCase())) && isAssertionLevelRiskExpanded}
                >
                    <Box sx={{ ...(boxRowStyles), marginLeft: '50px' }}>
                        <div className={styles['comment-wrapper']}>
                            <TextField
                                multiline={true}
                                minRows="3"
                                maxRows="6"
                                label={CONSTANT_TEXT.COMMENTS}
                                placeholder={CONSTANT_TEXT.COMMENT_PLACEHOLDER}
                                defaultValue={commentValue}
                                value={commentValue}
                                inputProps={{ maxLength: 4096 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ width: '100%' }}
                                onChange={(e) => {
                                    setCommentValue(e.target.value);
                                    setIsValueChanged(true);
                                }}
                                onBlur={() => {
                                    activeElementRef.current = null;
                                    if (isIdle) {
                                        setCommentValue(auditArea.riskAssessmentComment ?? '');
                                        setIsValueChanged(false);
                                    }
                                }}
                                onFocus={(event) => {
                                    setFocusedId(`RiskAssessmentComment-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}`)
                                    updateMyPresence({ focusedId: `RiskAssessmentComment-${auditArea.auditAreaId}-${auditArea.projectScopeAuditAreaId}`, type: 'custom' })
                                    activeElementRef.current = event.target
                                }}
                                disabled={disabled}
                            />
                            <Box>
                                <Button
                                    disabled={disabled || !isValueChanged}
                                    variant='contained'
                                    sx={buttonStyles}
                                    onClick={handleSaveButtonClick}>
                                    {CONSTANT_TEXT.SAVE}
                                </Button>
                                <Button
                                    variant='outlined'
                                    sx={buttonStyles}
                                    onClick={handleCancelButtonClick}>
                                    {CONSTANT_TEXT.CANCEL}
                                </Button>
                            </Box>
                        </div>
                    </Box>
                </VFRenderedFieldWrapper>
            }
            <Collapse in={isExpanded} collapsedSize={0} data-test="collapse-component" sx={{ ml: '40px' }}>
                <Box sx={{ ...(boxRowStyles) }}>
                    <div className={styles['scotabd-wrapper']}>
                        <Typography sx={typoSCOTABDS} data-test='scotabds-title'>
                            {CONSTANT_TEXT.SCOTABDS}
                        </Typography>
                    </div>
                </Box>
                {scotabdList && scotabdList.map((scotabd, index) =>
                    <CLASCOTABDsCollapsible
                        key={index}
                        index={index}
                        scotabd={scotabd}
                        controlRiskOptions={controlRiskOptions}
                        inherentRiskOptions={inherentRiskOptions}
                        isInternalControlEffectiveness={isInternalControlEffectiveness}
                        financialRisks={financialRisks}
                        disabled={disabled}
                        isExpanded={isExpanded && isAssertionLevelRiskExpanded}
                        userId={userId}
                    />)}
            </Collapse>
            <CustomToast
                error={errorProjectScopeAuditArea}
                success={successProjectScopeAuditArea}
                loading={loadingProjectScopeAuditArea}
            />
        </Box >
    );
};

CLAAuditAreaCollapsible.propTypes = {
    auditArea: PropTypes.object,
    index: PropTypes.number,
    inherentRiskOptions: PropTypes.array,
    controlRiskOptions: PropTypes.array,
    isInternalControlEffectiveness: PropTypes.bool,
    financialRisks: PropTypes.array,
    disabled: PropTypes.bool,
}

export default CLAAuditAreaCollapsible;
import { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form"
import { Form } from "@ais/palette";

import { BasicTextField, ExternalAccess } from './dialog-components/settings';
import { DIALOG, TRIGGERS } from '@constants/forms';
import {
  createField,
  dropNewItem,
  updateFormItem,
  filterEmptyCriterias
} from '@components/Forms/helpers';
import {parseQuestionLabel} from '@ais/forms';
import { SchemaContext } from '@ais/providers';
import { BaseDialog } from '.';
import { RulesTab } from './dialog-components/rules';

const processInitialValues = (additionalData) => {
  const parsed = parseQuestionLabel(additionalData?.label);
  const initialValues = {
    label: additionalData?.label ?? '',
    fields: Array.isArray(additionalData?.fields) ? additionalData.fields : [],
    width: 100,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
            behavior: 'show',
            match: 'any',
            criterias: [],
          },
    visibleToClient: additionalData.hasOwnProperty('visibleToClient')
      ? additionalData.visibleToClient
      : true,
    editableByClient: additionalData.hasOwnProperty('editableByClient')
      ? additionalData.editableByClient
      : true,
  };
  if (typeof parsed === 'string') {
    initialValues.label = additionalData?.label;
  } else if (typeof parsed === 'object') {
    initialValues.label = parsed.questionLabel;
  }
  return initialValues;
};

export const QuestionGroupDialog = ({ visible, setVisible, additionalData, trigger }) => {
  const { LABEL, PLACEHOLDER } = DIALOG.SETTINGS;

  const { schema, dispatchSchema } = useContext(SchemaContext);

  const initialValues = processInitialValues(additionalData);

  const schemaValidation = Yup.object().shape({
    label: Yup.string().trim().required('')
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const handleSubmit = (values) => {
    const filteredRules = filterEmptyCriterias(values.rules);
    if (trigger !== TRIGGERS.EDIT_BTN) {
      const newField = createField({
        ...values,
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        rules: filteredRules,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        ...values,
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        rules: filteredRules,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger();
  }, [visible]);
  
  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => (
        <Grid container spacing={1} columns={1}>
          <Grid item xs={12}>
            <BasicTextField
              name="label"
              label={LABEL.QUESTION_GROUP_LABEL}
              placeholder={PLACEHOLDER.QUESTION_GROUP}
              maxLength={256}
            />
          </Grid>
          <Grid item xs={12} p={30}></Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <ExternalAccess
                allowExternalAccessName="visibleToClient"
                makeAnswerableName="editableByClient"
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      label: LABEL.RULES,
      render: () => (
        <RulesTab additionalData={additionalData} trigger={trigger} isSection />
      ),
    },
  ];

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={additionalData.id}
        idPrefix={LABEL.QUESTION_ID}
        title={LABEL.QUESTION_GROUP}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={tabs}
        trigger={trigger}
      />
    </Form>
  );
};

QuestionGroupDialog.propTypes = {
  visible: PropTypes.bool,
  additionalData: PropTypes.object,
  trigger: PropTypes.string,
  setVisible: PropTypes.func,  
};

QuestionGroupDialog.defaultProps = {
  value: false,
  additionalData: null,
  trigger: '',  
};

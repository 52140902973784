import { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"

import { Box, IconButton } from '@mui/material';
import { Comment, CommentResolved } from '@ais/assets';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import {
    CLAAccordionPro,
} from '@ais/components';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import { UnderstandingOfEntityContext } from "@ais/contexts";
import { useKeyControlDrawerContext } from '@contexts/KeyControlDrawer/KeyControlDrawerContext' 
import { ConcurrentInternalControlTextbox } from '../../../ConcurrentInternalControlTextbox.jsx';
import { ConcurrentKeyControlsButton } from '../../../ConcurrentKeyControlsButton.jsx';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';

import styles from './UOEAuditAreaCollapsibles.module.css';

import { useRoomIdle, useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useOthers } from '@components/Concurrency/store/users';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js'; 

const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEJournalEntriesConstants = UOEConstants.JOURNAL_ENTRIES;
const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;
const UOEComments = UOEConstants.COMMENTS;

export const JournalEntries = ({
    journalEntryInfoProcessing,
    saveInternalControlData,
    isProjectFinalized,
    signOffList = [],
    userId,
    isLocked
}) => {
    const {
        formattedInternalControls,
        changeFirstLevelField
    } = useInternalControlsContext();
    const { state: { openComment }, setOpenComment } = useContext(UnderstandingOfEntityContext)
    const isCommentOpen = openComment === UOEJournalEntriesConstants.COMMENT_KEY
    const { projectFormId } = useParams();
    const [journalHighlighted, setJournalHighlighted] = useState(false)
    const [keyControlButtonHighlighted, setKeyControlButtonHighlighted] = useState(false)
    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();
    
    const { openDrawer, initializeInternalControl } = useKeyControlDrawerContext();
    const [ currentFocusedField, setCurrentFocusedField ] = useState(null)
    const [ journalEntryInfoProcessingValue, setJournalEntryInfoProcessingValue ] = useState(journalEntryInfoProcessing)
    const [ journalEntryICComment, setJournalEntryICComment ] = useState(formattedInternalControls?.JournalEntryICComment)
    const journalEntryRef = useRef(null)
    const commentRef = useRef(null)
    const isIdle = useRoomIdle()

    const handleBlur = (event, _ = null) => {
        setCurrentFocusedField(null)
        if (isIdle) {
            updateMyPresence({ focusedId: null, type: 'custom' }) 
            journalEntryRef.current = null
            return
        }
        if ((journalEntryInfoProcessingValue == null && !event.target.value) || (journalEntryInfoProcessing === event.target.value)) {
            updateMyPresence({ focusedId: null, type: 'custom' })
            return
        }

        updateMyPresence({ focusedId: null, type: 'custom' })
        changeFirstLevelField(UOEJournalEntriesConstants.FIELD, event.target.value);
        saveInternalControlData({
            [UOEJournalEntriesConstants.FIELD]: event.target.value
        });
        journalEntryRef.current = null
    };

    useEffect(() => {
        if (journalEntryInfoProcessing === null || journalEntryInfoProcessing === undefined) return
        setJournalEntryInfoProcessingValue(journalEntryInfoProcessing)
    }, [ journalEntryInfoProcessing ])

    useEffect(() => {
        if (formattedInternalControls?.JournalEntryICComment === null || formattedInternalControls?.JournalEntryICComment === undefined) return
        setJournalEntryICComment(formattedInternalControls?.JournalEntryICComment)
    }, [ formattedInternalControls?.JournalEntryICComment ])

    useEffect(() => {
        if (isIdle) {
            if (currentFocusedField) {
                if (currentFocusedField === UOEJournalEntriesConstants.FIELD) { 
                    setJournalEntryInfoProcessingValue(journalEntryInfoProcessing)
                    journalEntryRef.current?.blur()

                    return
                } 
                setJournalEntryICComment(formattedInternalControls?.JournalEntryICComment)
                commentRef.current?.blur()
              } 
        }
    }, [isIdle])

    const handleCommentBlur = (event, _ = null) => {
        setCurrentFocusedField(null)
        if (isIdle) {
            updateMyPresence({ focusedId: null, type: 'custom' }) 
            commentRef.current = null

            return
        }
        if (journalEntryICComment === formattedInternalControls?.JournalEntryICComment) {
            updateMyPresence({ focusedId: null, type: 'custom' })
            return;
        }

        updateMyPresence({ focusedId: null, type: 'custom' })
        changeFirstLevelField(
            UOEJournalEntriesConstants.COMMENT_FIELD,
            event.target.value
        );

        saveInternalControlData({
            [UOEJournalEntriesConstants.COMMENT_FIELD]: event.target.value
        });
        commentRef.current = null
    };

    const toggleCommentOpen = () => {
        setOpenComment(UOEJournalEntriesConstants.COMMENT_KEY)
    }

    const onKeyControlClick = () => {
        openDrawer('journal-entries-key-controls')
        updateMyPresence({ focusedId: "journal-entries-key-controls", type: 'custom' })
        initializeInternalControl({
            projectFormId,
            isJournalEntry: true,
            isFinancialStatement: false,
            internalControlData: formattedInternalControls
        });
    }

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlight = false
        const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
        const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
        const isJournalHighlighted = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
        setJournalHighlighted(isSignoffEnabled && isJournalHighlighted)


        if (formattedInternalControls?.KeyControlHistory?.length > 0) {
            const journalEntries = formattedInternalControls?.KeyControlHistory.filter((kc) => {
                return kc.IsJournalEntry
            })
            if(journalEntries.length > 0){
                const dateModified = new Date(journalEntries[0].ValidTo).getTime();
                const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
                setKeyControlButtonHighlighted(isSignoffEnabled && shouldHighlight)
            }
        }else{
            if(formattedInternalControls?.ProjectInternalControlProjectKeyControl?.length > 0){
                const journalEntries = formattedInternalControls.ProjectInternalControlProjectKeyControl.filter((kc) => {
                    return kc.IsJournalEntry
                })
                if(journalEntries.length > 0){
                    const dateModified = new Date(journalEntries[0]?.ValidFrom + "Z").getTime();
                    const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                    shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
                    setKeyControlButtonHighlighted(isSignoffEnabled && shouldHighlight)
                }
            }
        }
    }, [formattedInternalControls, signOffList])
    return (
        <CLAAccordionPro.Root>
            <CLAAccordionPro
                id={UOEJournalEntriesConstants.ACCORDION_ID}
                customClassName={{ container: 'UOEContainer', accordion: 'UOEAccordion' }}
                disableTransitions={true}
                expandedPanel={isCommentOpen ? UOEJournalEntriesConstants.ACCORDION_ID : undefined}
            >
                <CLAAccordionPro.Trigger
                    id={UOEJournalEntriesConstants.ACCORDION_ID}
                    customClassName={{ title: 'title', subtitle: 'flex100' }}
                    styles={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            '&.Mui-expanded': {
                                transform: 'none'
                            }
                        }
                    }}
                >
                    <div className={styles.headerContainer}>
                        <div className={styles.gridContainer}>
                            <div className={styles.rowTitle}>Journal Entries</div>
                            <div className={styles.rowIcon}>
                                <IconButton onClick={toggleCommentOpen} sx={{ p: 0 }}>
                                    {
                                        <Box sx={journalHighlighted ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '4px' } : {}}>
                                            {(!!journalEntryICComment && journalEntryICComment.length > 0)
                                                ? <CommentResolved />
                                                : <Comment />}
                                        </Box>
                                    }
                                </IconButton>
                            </div>
                            <VFRenderedFieldWrapper
                                className={styles["field__wrapper"]}
                                isLockedByUser={others.find((user) => user.presence.focusedId === `journal-entries-key-controls` && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                                isLocked={isLocked}
                                disablePortal={true}
                            >
                                    <ConcurrentKeyControlsButton 
                                    className={styles.rowButton} 
                                        onClick={onKeyControlClick} 
                                        customFormObjectId={`journal-entries-key-controls`}
                                        disabled={!!others.find((user) => user.presence.focusedId === `journal-entries-key-controls` && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                                        shouldHighlight={keyControlButtonHighlighted}
                                    />
                            </VFRenderedFieldWrapper>
                        </div>
                    </div>
                </CLAAccordionPro.Trigger>

                <CLAAccordionPro.Panel
                    panelStyles={{
                        padding: 'auto',
                        paddingRight: 0,
                        backgroundColor: '#FFF'
                    }}
                >
                    <Box sx={{ ml: '45px', my: '20px' }}>
                        <VFRenderedFieldWrapper
                            className={styles["field__wrapper"]}
                            isLockedByUser={others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.FIELD && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                            isLocked={isLocked}
                        >
                            <Box sx={journalHighlighted ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '10px', pt: '20px' } : {}}>
                                <ConcurrentInternalControlTextbox
                                    label={UOEInfoProcessingConstants.LABEL}
                                    placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
                                    value={journalEntryInfoProcessingValue || ''}
                                    defaultValue={journalEntryInfoProcessingValue || ''}
                                    onBlur={handleBlur}
                                    onFocus={(event, identifier, focusedId) => {
                                        updateMyPresence({ focusedId, type: 'custom' })
                                        journalEntryRef.current = event.target
                                        setCurrentFocusedField(UOEJournalEntriesConstants.FIELD)
                                    }}
                                    onKeyUp={event => setJournalEntryInfoProcessingValue(event.target.value)}
                                    customFormObjectId={UOEJournalEntriesConstants.FIELD}
                                    disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.FIELD && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                                    maxLength={null}
                                />
                            </Box>
                        </VFRenderedFieldWrapper>
                        {!!isCommentOpen &&
                            <VFRenderedFieldWrapper
                                className={styles["field__wrapper"]}
                                isLockedByUser={others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.COMMENT_KEY && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                                isLocked={isLocked} 
                            >
                                <ConcurrentInternalControlTextbox
                                    label={UOEComments.LABEL}
                                    placeholder={UOEComments.PLACEHOLDER}
                                    value={journalEntryICComment || ''}
                                    defaultValue={journalEntryICComment || ''}
                                    nonMultilineLabelFontSize={UOEComments.NON_MULTILINE_LABEL_FONT_SIZE}
                                    nonMultilineLabelMarginLeft={UOEComments.NON_MULTILINE_LABEL_MARGIN_LEFT}
                                    onBlur={handleCommentBlur}
                                    onFocus={(event, identifier, focusedId) => {
                                        updateMyPresence({ focusedId, type: 'custom' })
                                        commentRef.current = event.target
                                        setCurrentFocusedField(UOEJournalEntriesConstants.COMMENT_KEY)
                                    }}
                                    onKeyUp={event => setJournalEntryICComment(event.target.value)}
                                    sx={{
                                        marginTop: '20px'
                                    }}
                                    customFormObjectId={UOEJournalEntriesConstants.COMMENT_KEY}
                                    disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.COMMENT_KEY && userId.toLowerCase() !== user.info.userId.toLowerCase())}
                                />
                            </VFRenderedFieldWrapper>
                        }
                    </Box>
                </CLAAccordionPro.Panel>
            </CLAAccordionPro>
        </CLAAccordionPro.Root>
    );
}

export default JournalEntries;
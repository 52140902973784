import React, { useEffect, useState, useCallback, useContext, useMemo, createContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetProjectSimple } from "@services/project";
import projectFormServices, { useProjectFormProcedures } from "@services/forms/projectforms";
import { useOtherCharacteristics } from "@services/forms/othercharacteristics";
import { useProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk";
import { TailorProcedureContext } from '@components/Forms/state';
import { useLoading } from '@hooks/index';
import { useAssertions } from '@services/assertions';
import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import {
    filterSteps,
    filterCustomProcedures
} from '@components/Forms/helpers';
import SummaryProcedureSection from './SummaryProcedureSection';
import CustomProcedureSection from './CustomProcedureSection';
import CLAProcedureTable from './CLAProcedureTable';
import useUserProviderState from "@contexts/UserContext";
import { CLAProcedureContextProvider } from './CLAProcedureContexProvider';
import CustomToast from '@components/CustomToast/CustomToast';
import useConcurrencyProcedureListeners from './useConcurrencyProcedure';

const CLAProcedureField = ({ section, procedureFilter, field, ...props }) => {
    const { id: sectionId, projectId, auditArea: auditAreaId } = section;
    const { projectFormId } = useParams();
    const [options, setOptions] = useState([]);
    const { tailorProcedure, dispatchTailorProcedures } = useContext(TailorProcedureContext);
    const [summaryProcedures, setSummaryProcedures] = useState([]);
    const [customProcedures, setCustomProcedures] = useState([]);
    const setLoading = useLoading();
    const { getUser } = useUserProviderState();
    const [userLocalId, setUserLocalId] = useState('');
    const [successToast, setSuccessToast] = useState(false)
    const [errorToast, setErrorToast] = useState(false)
    const [loadingToast, setLoadingToast] = useState(false)

    const { data: projectData } = useGetProjectSimple(projectId);
    const MethodologyVersionId = projectData?.data?.MethodologyVersionId;
    const { data: otherCharacteristics } = useOtherCharacteristics(MethodologyVersionId);
    const { data: projectFormProcedureResponse, refetch } = useProjectFormProcedures(projectFormId, auditAreaId, projectId);
    const { data: projectRisks } = useProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId);
    const { data: assertionsResult, isLoading: isLoadingAssertions, isFetching: isAssertionsFetching } = useAssertions(MethodologyVersionId, true);

    useConcurrencyProcedureListeners(sectionId);

    const checkIfChildIsRequiredOrChecked = (summaryProcedureSteps, ps) => {
        const { SummaryProcedureStepId, SummaryProcedureId } = ps;
        let isRequired = false;
        let childProcedures = [];
        if (SummaryProcedureId) {
            childProcedures = summaryProcedureSteps.filter((data) => data.SummaryProcedureId === SummaryProcedureId);
        } else {
            childProcedures = summaryProcedureSteps.filter((data) => data.ParentSummaryProcedureStepId === SummaryProcedureStepId);
        }

        for (let summaryProcedureStep of childProcedures) {
            if (summaryProcedureStep.IsRequiredStep) {
                isRequired = true;
                break;
            }
            isRequired = checkIfChildIsRequiredOrChecked(summaryProcedureSteps, summaryProcedureStep);
            if (isRequired) {
                isRequired = true;
                break;
            }
        }
        return isRequired;
    }

    useEffect(() => {
        async function initData() {
            const user = await getUser();
            const userLocalId = user?.activeDirectoryId ?? '';
            setUserLocalId(userLocalId);

        }

        initData()
    }, [])

    useEffect(() => {
        const filteredStepItems = filterSteps(procedureFilter, tailorProcedure[`${sectionId}`]?.summaryProcedures);
        const filteredCustomProcedures = filterCustomProcedures(procedureFilter, tailorProcedure[`${sectionId}`]?.customProcedures)
        setSummaryProcedures(filteredStepItems);
        setCustomProcedures(filteredCustomProcedures);
    }, [procedureFilter])


    const getProjectFormProcedureResponse = async (projectFormProcedureResponse) => {
        const responseData = projectFormProcedureResponse?.data ?? undefined;
        if (responseData && assertionsResult?.data) {
            const { SummaryProcedures, CustomProcedures } = responseData;
            dispatchTailorProcedures({
                type: TAILOR_PROCEDURE_REDUCER.LOAD_INIT_TAILOR_PROCEDURE,
                payload: {
                    sectionId: sectionId,
                    summaryProcedures: SummaryProcedures ?? [],
                    customProcedures: CustomProcedures ?? [],
                    assertions: assertionsResult?.data ?? [],
                }
            })
        }
    }

    useEffect(() => {
        const init = async () => {
            await getProjectFormProcedureResponse(projectFormProcedureResponse);
        }
        init();
    }, [projectFormProcedureResponse, isLoadingAssertions]);

    useEffect(() => {
        if (isAssertionsFetching) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isAssertionsFetching]);

    useEffect(() => {
        if (otherCharacteristics) {
            const mappedOtherCharacteristics = otherCharacteristics.filter(o => o.IsEnabled)?.map(data => ({
                value: data.OtherCharacteristicId,
                label: data.OtherCharacteristicName,
                ...data
            }));

            const filteredOtherCharacteristicsByAuditArea = mappedOtherCharacteristics.filter(data => {
                const { OtherCharacteristicAuditAreas = [] } = data;
                const otherCharacteristicAuditArea = OtherCharacteristicAuditAreas.find(_data => _data.AuditAreaId === auditAreaId);

                if (otherCharacteristicAuditArea) return true;
                return false;
            });

            setOptions(filteredOtherCharacteristicsByAuditArea);
        }
    }, [otherCharacteristics]);

    useEffect(() => {
        let summaryProcedures = tailorProcedure[`${sectionId}`]?.summaryProcedures;
        let customProcedures = tailorProcedure[`${sectionId}`]?.customProcedures;
        if (procedureFilter) {
            summaryProcedures = filterSteps(procedureFilter, tailorProcedure[`${sectionId}`]?.summaryProcedures);
            customProcedures = filterCustomProcedures(procedureFilter, tailorProcedure[`${sectionId}`]?.customProcedures);
        }
        setSummaryProcedures(summaryProcedures);
        setCustomProcedures(customProcedures);
    }, [tailorProcedure])

    const toastStates = {
        error: errorToast,
        loading: loadingToast,
        success: successToast
    }

    return (
        <CLAProcedureContextProvider>
            <CLAProcedureTable {...props} field={field} options={options} userId={userLocalId}>
                <SummaryProcedureSection
                    summaryProcedure={summaryProcedures ?? []}
                    projectRisks={projectRisks?.data ?? []}
                    getProjectFormProcedures={refetch}
                    filtered={procedureFilter !== null && procedureFilter !== undefined}
                    userId={userLocalId}
                    sectionId={sectionId}
                    questionId={field.id}
                    setSuccessToast={setSuccessToast}
                    setErrorToast={setErrorToast}
                    setLoadingToast={setLoadingToast}
                />
                <CustomProcedureSection
                    customProcedures={customProcedures}
                    assertions={tailorProcedure[`${sectionId}`]?.assertions ?? []}
                    projectRisks={projectRisks?.data ?? []}
                    userId={userLocalId}
                    auditAreaId={auditAreaId}
                    getProjectFormProcedures={refetch}
                    sectionId={sectionId}
                    questionId={field.id}
                    setSuccessToast={setSuccessToast}
                    setErrorToast={setErrorToast}
                    setLoadingToast={setLoadingToast}
                />
            </CLAProcedureTable>
            <CustomToast
                error={toastStates.error}
                loading={toastStates.loading}
                success={toastStates.success}
            />
        </CLAProcedureContextProvider>
    )
};

export default CLAProcedureField;

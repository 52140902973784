import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import { updateProjectRiskAssessmentSummary } from "@services/projectRisk.js";

import CLAFinancialStatemetRichTextEditor from '../CLAFinancialStatemetRichTextEditor/CLAFinancialStatemetRichTextEditor';

import styles from './CLAFinancialStatementLevelRisk.module.css';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { useRoomIdle, useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from '@components/Concurrency/store/users';

import { richTextEditorTextContent } from '@utilities/richTextEditorTextContent';
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper"
import { useRiskAssessment } from '../../hooks/useRiskAssessment';

const CLAFinancialStatementLevelPersuasiveRisk = ({ index, row, disabled, userId }) => {
    const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
    const updateMyPresence = useUpdateMyPresence()
    const { projectId, projectFormId } = useParams();
    const [shouldHighlight, setShouldHighlight] = useState();
    const [natureAndExtentOfPervasiveEffect, setNatureAndExtentOfPervasiveEffect] = useState(null);
    const [plannedOverallResponse, setPlannedOverallResponse] = useState(null);
    const others = useOthers();
    const isIdle = useRoomIdle()

    const {
        isFinancialStatementExpanded,
        modifiedRiskAssessmentSummaryAfterSignOff,
        financialStatementPersuasiveRisk,
        setFinancialStatementPersuasiveRisk,
        actions: {
            updateLoadingProjectRiskAssessmentSummary,
            updateSuccessProjectRiskAssessmentSummary,
            updateErrorProjectRiskAssessmentSummary,
            updateAnswerUpdateToggle
        }
    } = useRiskAssessment();

    const handleBlur = async (value, property) => {
        if (property === 'NatureAndExtentOfPervasiveEffect' && value === natureAndExtentOfPervasiveEffect) return;
        if (property === 'PlannedOverallResponse' && value === plannedOverallResponse) return;

        const requestBody = {
            ...row,
            [property]: value,
            RequestProjectFormId: projectFormId
        }

        const updatedTableRows = financialStatementPersuasiveRisk.map((item) => {
            if (item.ProjectRiskId === requestBody.ProjectRiskId) {
                return requestBody;
            } else {
                return item;
            }
        });

        setFinancialStatementPersuasiveRisk(updatedTableRows);
        try {
            updateLoadingProjectRiskAssessmentSummary(true)
            await updateProjectRiskAssessmentSummary(projectId, requestBody.ProjectRiskId, requestBody);
            updateAnswerUpdateToggle();
            updateSuccessProjectRiskAssessmentSummary(true)
            updateLoadingProjectRiskAssessmentSummary(false)
        } catch (error) {
            updateLoadingProjectRiskAssessmentSummary(false)
            updateErrorProjectRiskAssessmentSummary(true)
        }
    };

    const applyClass = () => {
        let classList = [styles['fin-state-level-row']];
        if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED)
            classList.push(styles['highlight-modified']);

        return classList.join(' ');
    };

    useEffect(() => {
        if (modifiedRiskAssessmentSummaryAfterSignOff) {
            const shouldHighlight = !!modifiedRiskAssessmentSummaryAfterSignOff
                .find(signOff => signOff.ProjectRiskId === row.ProjectRiskId) ? HIGHLIGHT_BORDER.MODIFIED : null;

            setShouldHighlight(shouldHighlight);
        }
    }, [modifiedRiskAssessmentSummaryAfterSignOff]);

    useEffect(() => {
        setNatureAndExtentOfPervasiveEffect(row.NatureAndExtentOfPervasiveEffect);
    }, [row.NatureAndExtentOfPervasiveEffect])

    useEffect(() => {
        setPlannedOverallResponse(row.PlannedOverallResponse);
    }, [row.PlannedOverallResponse])

    return (
        <div key={index} className={applyClass()}>
            <div className={styles['fin-state-level-text-cell']}>
                {row.identifiedRisk ?? row.RiskName}
            </ div>
            <div className={styles['fin-state-level-rte-cell']}>
                <div className={styles['quill-container']}>
                    <VFRenderedFieldWrapper
                        className={styles.field__wrapper}
                        isLockedByUser={others.find((user) => user.presence.focusedId === `NameAndExtent-${row.ProjectRiskId}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())}
                        isLocked={(others.find((user) => user.presence.focusedId === `NameAndExtent-${row.ProjectRiskId}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())) && isFinancialStatementExpanded}
                    >
                        <CLAFinancialStatemetRichTextEditor
                            onBlur={(_, value) => {
                                if (!isIdle) handleBlur(value, 'NatureAndExtentOfPervasiveEffect')
                                else setNatureAndExtentOfPervasiveEffect(row.NatureAndExtentOfPervasiveEffect ? richTextEditorTextContent(row.NatureAndExtentOfPervasiveEffect) : '')
                            }}
                            expandable={false}
                            onFocus={() => {
                                updateMyPresence({ focusedId: `NameAndExtent-${row.ProjectRiskId}`, type: 'custom' })
                            }}
                            placeholder={"Enter Text"}
                            value={natureAndExtentOfPervasiveEffect}
                            height="75px"
                            disabled={disabled || !!others.find((user) => user.presence.focusedId === `NameAndExtent-${row.ProjectRiskId}`)}
                            isIdle={isIdle}
                            unlockField={() => {
                                updateMyPresence({ focusedId: null })
                            }}
                        />
                    </VFRenderedFieldWrapper>
                </div>
            </ div>
            <div className={styles['fin-state-level-rte-cell']}>
                <div className={styles['quill-container']}>
                    <VFRenderedFieldWrapper
                        className={styles.field__wrapper}
                        isLockedByUser={others.find((user) => user.presence.focusedId === `PlannedOverallResponse-${row.ProjectRiskId}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())}
                        isLocked={(others.find((user) => user.presence.focusedId === `PlannedOverallResponse-${row.ProjectRiskId}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())) && isFinancialStatementExpanded}
                    >
                        <CLAFinancialStatemetRichTextEditor
                            onBlur={(_, value) => {
                                if (!isIdle) handleBlur(value, 'PlannedOverallResponse')
                                else setPlannedOverallResponse(row.PlannedOverallResponse ? richTextEditorTextContent(row.PlannedOverallResponse) : '')
                            }}
                            expandable={false}
                            placeholder={"Enter Text"}
                            onFocus={() => {
                                updateMyPresence({ focusedId: `PlannedOverallResponse-${row.ProjectRiskId}`, type: 'custom' })
                            }}
                            value={plannedOverallResponse}
                            height="75px"
                            disabled={disabled || !!others.find((user) => user.presence.focusedId === `PlannedOverallResponse-${row.ProjectRiskId}` && user.info.userId.toLowerCase() !== userId?.toLowerCase())}
                            isIdle={isIdle}
                            unlockField={() => {
                                updateMyPresence({ focusedId: null })
                            }}
                        />
                    </VFRenderedFieldWrapper>
                </div>
            </ div>
        </ div>
    )
}

CLAFinancialStatementLevelPersuasiveRisk.propTypes = {
    index: PropTypes.number,
    row: PropTypes.object,
    disabled: PropTypes.bool,
}

export default CLAFinancialStatementLevelPersuasiveRisk;
import { useState, useEffect, memo } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateIcon } from "@ais/assets";
import { TextField, InputAdornment } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { renderLabel } from '@ais/forms';
import { styled } from '@mui/system';
import dayjs from 'dayjs'
import { useRoomIdle } from '@ais/hooks';
import { Flex } from "@ais/palette";

const StyledInputAdornment = styled(InputAdornment)({
    marginRight: "-5px",
    marginTop: "4px"
});


export const CLADatePicker = ({
    field: {
        id,
        label,
        tooltip,
        placeholder
    },
    disabled,
    clientExternalIcon,
    lockField,
    unlockField,
    onFocus,
    renderLink,
    onSubmit
}) => {
    const renderedLabel = renderLabel(label, tooltip, null);

    const { control, setValue, getValues } = useFormContext();
    const [errorState, setErrorState] = useState('');
    const [keyCount, setKeyCount] = useState(0);
    const [open, setOpen] = useState(false);
    const isIdle = useRoomIdle();
    const dateIcon = () => {
        return (
            <img src={DateIcon} style={{ "marginRight": "5px" }} alt='CLA Date icon' />
        )
    }

    const handleOnChange = (date) => {
        let updatedDate = dayjs(date).format('MM/DD/YYYY');
        let year = dayjs(date).year();
        if (updatedDate !== 'Invalid Date' && year <= 2099) {
            setValue(id, updatedDate);
            setErrorState('');
        } else {
            updatedDate = null;
            setErrorState('Invalid Date Format');
        }
    }

    useEffect(() => {
        if (isIdle) {
            setOpen(false);
            if (errorState) {
                setErrorState('');
                const val = getValues(id);
                setValue(id, val);
                setKeyCount(prev => prev + 1);
            }
        }
    }, [isIdle])

    return (
        <LocalizationProvider key={`DatePicker-${id}-${keyCount}`} dateAdapter={AdapterDayjs}>
            <Controller
                control={control}
                name={id}
                render={({ field: { value } }) => (
                    <Flex direction="row" justify="space-between" align="center" style={{ width: '100%' }}>
                        <DatePicker
                            data-testid={`DatePicker-${id}`}
                            PopperProps={{
                                placement: 'bottom-end'
                            }}
                            open={open}
                            value={value ? value : null}
                            onChange={date => {
                                handleOnChange(date);
                            }}
                            onOpen={() => {
                                setOpen(true)
                                onFocus(null, id)
                                lockField && lockField()
                            }}
                            onClose={() => {
                                if (isIdle) return;
                                setOpen(false)
                                onSubmit && onSubmit(id);
                                unlockField && unlockField()
                            }}
                            components={{
                                OpenPickerIcon: dateIcon
                            }}
                            disabled={disabled}
                            renderInput={(props) => {
                                if (placeholder) props.inputProps.placeholder = placeholder;
                                props.InputProps.notched = true;
                                return (
                                    <TextField
                                        {...props}
                                        label={renderedLabel}
                                        helperText={errorState}
                                        error={!!errorState}
                                        InputLabelProps={{
                                            shrink: true,
                                            variant: 'multiline-label'
                                        }}
                                        InputProps={{
                                            ...props.InputProps,
                                            endAdornment: (
                                                <>
                                                    {props.InputProps.endAdornment}
                                                    <StyledInputAdornment position="end">
                                                        {clientExternalIcon}
                                                    </StyledInputAdornment>
                                                </>
                                            )
                                        }}
                                        inputProps={{
                                            ...props.inputProps,
                                            "test-id": `DatePicker-${id}`,
                                            "test-value": value
                                        }}
                                        FormHelperTextProps={{
                                            sx: {
                                                mx: 0
                                            }
                                        }}
                                        fullWidth
                                        onFocus={(e) => {
                                            onFocus(e, id)
                                            lockField && lockField()
                                        }}
                                        onBlur={() => {
                                            if (isIdle) return;
                                            if (!errorState && value && onSubmit) {
                                                onSubmit(id)
                                            }
                                            unlockField && unlockField()
                                        }}
                                    />
                                );
                            }}
                        />
                        {renderLink && renderLink()}
                    </Flex>
                )}
            />

        </LocalizationProvider>
    );
};

export default memo(CLADatePicker);

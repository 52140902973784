import { useRef, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  InputAdornment
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FORM_LABEL_TYPES } from '@ais/constants';
import { ClientExternalIcon } from '@ais/forms';
import { CLATooltip } from "@ais/components"

const useStyles = makeStyles(_ => ({
  selectIcon: {
    position: "relative"
  }
}))

export const CLADropdown = (props) => {
  const {
    id,
    name,
    options,
    label,
    labelType,
    wrapLabel,
    placeholder,
    displayEmpty,
    emptyCanBeSelected,
    isDisabled,
    value,
    showToolTip,
    onChange,
    disabledValues,
    onFocus,
    onOpen,
    allowMultiLineLabel,
    onClose,
    onBlur,
    error,
    helperText,
    formHelperTextProps,
    selectedOptionRender,
    uncontrolled,
    allowExternalAccess,
    answerable,
    answered,
    isIdle,
    onClosedByIdle,
    useLabelForTooltip,
    joinedTextRef
  } = props;

  const dropdownRef = useRef(null);
  const containerRef = useRef(null);
  const [menuItemWidth, setMenuItemWidth] = useState(0);
  const [open, setOpen] = useState(false);

  const menuItemStyle = {
    maxWidth: `${menuItemWidth}px`,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  };

  const classes = useStyles();

  const isMenuItemDisabled = (disabledValues, option) => {
    const isEnabled = option.isEnabled === undefined ? true : option.isEnabled;
    const value = option?.hasOwnProperty('value') ? option.value : option;
    return disabledValues.indexOf(value) >= 0 || !isEnabled;
  };

  useEffect(() => {
    setMenuItemWidth(dropdownRef.current?.offsetWidth);
  }, [options, onChange, onOpen]);

  useEffect(() => {
    if (isIdle) {
      containerRef.current?.classList.remove('Mui-focused');
      setOpen(false)
      if(onClosedByIdle) onClosedByIdle()
    }
  }, [isIdle]);

  const renderDisplayValues = (selected) => {
    const _placeholder = <div style={{ color: '#cbcbcb' }}>{placeholder}</div>;
    if (selected === undefined || selected?.length === 0) {
      return _placeholder;
    }

    if (Array.isArray(selected) && options.length) {
      const foundArray = options.find((option) => {
        return option.hasOwnProperty('value') ? option.value.some(x => selected.includes(x)) : option
      });
      return (foundArray.hasOwnProperty('label')) ? foundArray.label : _placeholder;
    }

    const found = options.find((option) => option.value === selected);
    if (found === undefined) {
      const stringFound = options.find((option) => option === selected);
      if (stringFound) return stringFound;
      else return _placeholder;
    } else {
      if (found?.label) return found?.label;
      else return _placeholder;
    }
  };

  const handleChange = (e) => {  
    onChange && onChange(e)
  }

  const handleClose = (e) => {
    onClose && onClose(e, value)
  }

  const renderDropdown = () => {
    const selectProps = {
      name: name,
      displayEmpty: displayEmpty,
      value: value,
      disabled: !!isDisabled,
      onChange: handleChange,
      label: label,
      notched: labelType === FORM_LABEL_TYPES.INNER,
      onClick: isDisabled ? undefined : (e) => { 
        if (open) {
          onClose && onClose(e, value)
        }
        setOpen((prevOpen) => !prevOpen)
      },
      onFocus: onFocus,
      onOpen: onOpen,
      onBlur: () => {
        setOpen(false)
        if (typeof onBlur === 'function') onBlur()
      },
      renderValue: !!selectedOptionRender ? selectedOptionRender : renderDisplayValues,
      onClose: handleClose,
      inputProps: {
        name: name,
        id: id,
      },
    };
    const uncontrolledSelectProps = {
      name: name,
      displayEmpty: displayEmpty,
      defaultValue: value,
      disabled: !!isDisabled,
      label: label,
      notched: labelType === FORM_LABEL_TYPES.INNER,
      renderValue: !!selectedOptionRender ? selectedOptionRender : renderDisplayValues,
      inputProps: {
        name: name,
        id: id,
      }
    };

    const renderMenuItemOptions = (option, index) => {
      return <MenuItem
        disabled={isMenuItemDisabled(disabledValues, option)}
        key={index}
        value={option?.hasOwnProperty('value') ? option.value : option}
        sx={menuItemStyle}
      >
        {option?.hasOwnProperty('label') ? option.label : option}
      </MenuItem>
    }

    const setLabelTooltip = (selectedValue) => {
      const selectedItem = options && options.find(e => e.value === selectedValue);
      return (selectedItem && 'label' in selectedItem) ? selectedItem.label : null;    
    }

    return (
      <CLATooltip title={showToolTip ? (useLabelForTooltip ? setLabelTooltip(value) : value) : null} placement="bottom">
      <Select
        {...(uncontrolled ? uncontrolledSelectProps : selectProps)}
        ref={(!useLabelForTooltip) ? containerRef : joinedTextRef}
        open={open}
        classes={{
          icon: classes.selectIcon
        }}
        sx={{ paddingRight: '4px' }}
        endAdornment={
          allowExternalAccess ?
            <InputAdornment position="end" sx={{ margin: 0 }}>
              <ClientExternalIcon
                allowExternalAccess={allowExternalAccess}
                answerable={answerable}
                answered={answered}
              />
            </InputAdornment> : <></>
        }
      >
        {displayEmpty && (
          <MenuItem disabled={!emptyCanBeSelected} value="" sx={menuItemStyle}>
            {placeholder}
          </MenuItem>
        )}
        {
          options.map((option, index) => (
            (option.isEnabled === undefined ? true : option.isEnabled) ? renderMenuItemOptions(option, index) :
              <CLATooltip
                arrow
                title={option.toolTipTitle}
                placement="top"
                theme={{ padding: '15px 10px', display: 'relative', top: 15 }}
              >
                <div>
                  {renderMenuItemOptions(option, index)}
                </div>
              </CLATooltip>
          ))
        }
      </Select >
      </CLATooltip>
    );
  };

  return (
    <FormControl ref={dropdownRef} error={error} fullWidth>
      {labelType === FORM_LABEL_TYPES.INNER && (
        <InputLabel shrink {...(allowMultiLineLabel ? { variant: 'multiline-label' } : {})}>
          {label}
        </InputLabel>
      )}
      {labelType === FORM_LABEL_TYPES.OUTER && (
        <Typography
          variant="label"
          sx={{
            marginBottom: '8px',
            whiteSpace: wrapLabel ? 'normal' : 'nowrap',
          }}
        >
          {label}
        </Typography>
      )}
      {renderDropdown()}
      {error && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

CLADropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelType: PropTypes.string,
  wrapLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  displayEmpty: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  disabledValues: PropTypes.array,
  onFocus: PropTypes.func,
  onOpen: PropTypes.func,
  onBlur: PropTypes.func,
  allowMultiLineLabel: PropTypes.bool,
  onClose: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  formHelperTextProps: PropTypes.object,
  selectedOptionRender: PropTypes.func,
  uncontrolled: PropTypes.bool,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool,
  useLabelForTooltip: PropTypes.bool
};

CLADropdown.defaultProps = {
  options: [],
  labelType: FORM_LABEL_TYPES.INNER,
  wrapLabel: true,
  placeholder: 'Select',
  displayEmpty: true,
  emptyCanBeSelected: false,
  isDisabled: false,
  value: '',
  disabledValues: [],
  allowMultiLineLabel: false,
  onClose: undefined,
  error: false,
  helperText: '',
  formHelperTextProps: {},
  uncontrolled: false,
  allowExternalAccess: false,
  answerable: false,
  useLabelForTooltip: false
};

export default CLADropdown;


export const ensureHttpProtocol = (url) => {
    try {
        if (!url) return url;
        const parsedUrl = new URL(url);
        return parsedUrl.href;
    } catch (e) {
        return `https://${url}`;
    }
};

export const extractDomainFromUrl = (url) => {
    // Use a regular expression to match the domain part at the end of the URL
    const match = url?.match?.(/\/([^/]+)$/);
    return match?.[1] ?? url;
};

export const extractTextOrUrlFromLink = (url) => {
    url = extractDomainFromUrl(url);
    url = ensureHttpProtocol(url);
    return url;
};

export const extractTextOrUrl = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const elements = doc.body.childNodes;

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const linkElements = element?.querySelectorAll('a');

        linkElements?.forEach(link => {
            const url = extractTextOrUrlFromLink(link.href);
            link.setAttribute('href', url);
            link.setAttribute('rel', 'noopener noreferrer');
            link.setAttribute('target', '_blank');
            link.setAttribute('style', 'color: #0d6efd;');
        });
    }
    
    return doc.body.innerHTML;
};

import React, { useState, useEffect } from "react";
import { useDeepCompareEffect } from 'react-use';
import { useParams } from "react-router-dom";

import * as Constants from "@constants/index"
import styles from './CLATrialBalanceSelect.module.css';

import { CLADropdown } from '@ais/forms';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { useGetProjectTrialBalanceData } from '@services/trialbalance';
import clsx from 'clsx';
import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { sortReviewerSignoffAsc, getFormattedSignOffDate } from '@utilities/dateHelpers.js';
import { useGetAnswersByProjectFormId } from '@services/forms/projectforms.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';
import { useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import { useDisabledGlobalQueryLoading } from '@hooks/index';

const CLATrialBalanceSelect = ({
  handCaptureInput = undefined,
  isDisabled = false,
  field,
  defaultValues,
  project,
  onFocus,
  signOffList,
  highlightable = false,
  onOpen,
  isLockedByUser,
  isLocked,
}) => {
  useDisabledGlobalQueryLoading();
  const { ID, PLACEHOLDER, PLACEHOLDER_WITH_OPTIONAL, TRIAL_BALANCE } = Constants.TRIAL_BALANCE.EN
  const { required } = field;

  const updateMyPresence = useUpdateMyPresence();
  const isIdle = useRoomIdle();
  const [menuItems, setMenuItems] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [tbDefaultValue, setTBDefaultValue] = useState([]);
  const { projectId, projectFormId } = useParams()
  const isProjectFinalized = useFinalizedProject(projectId);
  const [shouldHighlight, setShouldHighlight] = useState(false)
  const { data: answers } = useGetAnswersByProjectFormId(projectFormId, projectId)
  const [matchingAnswer, setMatchingAnswer] = useState();

  const {
    data: trialBalances,
    isLoading: isTrialBalanceLoading,
    isError: isTrialBalanceError,
  } = useGetProjectTrialBalanceData(
    projectDetails?.AppDataInstanceId,
    projectDetails?.FiscalYear,
    projectId
  );

  useDeepCompareEffect(() => {
    if (!project || Object.keys(project).length < 1) return;
    setProjectDetails(project);
  }, [project])

  useEffect(() => {
    if (isTrialBalanceLoading || isTrialBalanceError) return;

    const processedTrialBalanceData = trialBalances
      ?.filter(tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId)
      .map(({ id, correlationName, correlationDetailId, correlationNameId }) => ({
        value: id,
        label: correlationName,
        correlationDetailId,
        correlationNameId
      }));

    setMenuItems(processedTrialBalanceData)
  }, [isTrialBalanceLoading, isTrialBalanceError])

  useEffect(() => {
    let answer = {}
      if (answers?.length) {
          answer = answers.find((answer) => answer.questionId?.toLowerCase() === field.id.toLowerCase())
      }
      
      setMatchingAnswer(answer);
  }, [answers])

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    let shouldHighlight = false;

    if (matchingAnswer?.lastUpdate) {
      const dateModified = new Date(getFormattedSignOffDate(matchingAnswer.lastUpdate)).getTime();
      const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
      shouldHighlight = new Date(getFormattedSignOffDate(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate)).getTime() < dateModified
      setShouldHighlight(isSignoffEnabled && shouldHighlight)
    }
  }, [matchingAnswer, signOffList, field])

  useEffect(() => {
    if (defaultValues && Array.isArray(defaultValues) && isIdle) {
      const selectedValues = menuItems.filter((x) =>
        defaultValues.map((o) => o?.correlationNameId).includes(x?.correlationNameId)
      );

      setTBDefaultValue(selectedValues?.map(x => x?.value))
    }
  }, [defaultValues, menuItems, isIdle])

  useDeepCompareEffect(() => {
    if (defaultValues && defaultValues?.length) {
      const selectedValues = menuItems.filter((x) =>
        defaultValues.map((o) => o?.correlationNameId).includes(x?.correlationNameId)
      );

      setTBDefaultValue(selectedValues?.map(x => x?.value))
    }
  }, [defaultValues, menuItems])

  const handleTrialBalanceDropdownSave = (e) => {
    const value = e.target.value;
    const found = menuItems.find((item) => item.value === value);
    const userInput = [found];
    handCaptureInput && handCaptureInput(userInput);

    if (matchingAnswer?.answer !== e.target.value) {
      setMatchingAnswer({...matchingAnswer, answer: e.target.value, lastUpdate: new Date().toISOString()})
    }
  };

  return (
    <div className={styles.trialBalanceContainer}>
      <div className={clsx(styles.trialBalanceDropdownSelectContainer, shouldHighlight && highlightable && styles.trialBalanceDropdownSelectContainerHighlighted)}>
        <VFRenderedFieldWrapper isLockedByUser={isLockedByUser} isLocked={isLocked}>
          <CLADropdown
            id={ID}
            name={TRIAL_BALANCE}
            label={TRIAL_BALANCE}
            value={tbDefaultValue[0]}
            placeholder={required ? PLACEHOLDER : `${PLACEHOLDER_WITH_OPTIONAL}`}
            onChange={handleTrialBalanceDropdownSave}
            options={menuItems}
            isDisabled={isDisabled}
            onFocus={onFocus}
            onOpen={() => {
              updateMyPresence({ focusedId: field.id })
              if (onOpen) {
                onOpen()
              }
            }}
            onClose={() => updateMyPresence({ focusedId: null })}
            isIdle={isIdle}
          />
        </VFRenderedFieldWrapper>
      </div>
    </div>
  );
}

export default CLATrialBalanceSelect
import { useMsal } from "@azure/msal-react";
import { useLoadMap, useMethodologyVersion, useUserList } from '@hooks/index';
import React, { useEffect, useState, useRef } from 'react';
import styles from '@components/CLAHeader/CLAHeader.module.css';
import Container from '@mui/material/Container';
import CLANavigation from "@components/CLANavigation/CLANavigation";
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import * as Constants from '@constants/index'
import PageTitle from '@components/PageTitle';
import PageSubTitle from '@components/PageSubTitle';
import MethodologyVersion from '@components/MethodologyVersions/MethodologyVersions';
import { useParams } from "react-router-dom";
import projectServices from "@services/project";
import { useGETProjectClientHeaderInformationByProjectId, useProject } from '@services/project';
import moment from 'moment';
import string from "@utilities/stringHelper.js";
import { CacheService, Providers } from '@microsoft/mgt-element';
import { CLADialog, CLAPerson, CLATooltip } from "@ais/components"
import { colors } from '@ais/theme';
import { useLocation } from 'react-router-dom';
import { useFinalizedProject } from '@hooks/useProject';

// assets
import BgDashboardHeader from '@assets/Bg_Dashboard_Header.svg';
import {ClaLogo} from "@ais/assets";
import icon_tooltip from "@assets/icon_tooltip.svg";
import CLANotification from "@components/CLANotification/CLANotification";
import CLANotificationsIconButton from "@components/CLANotification/CLANotificationsIconButton.jsx";


const CLAHeader = ({ pageName }) => {
    const { instance, accounts } = useMsal();
    const { projectId } = useParams();
    const {setLoadMapEntry} = useLoadMap();
    const {getUserById} = useUserList();

    const [open, setOpen] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [content, setContent] = useState(null)
    const [openModal, setOpenModal] = useState(false);
    const [methodologyVersions, setMethodologyVersions] = useState(null);
    const [methodologyVersion, setMethodologyVersion] = useState('');
    const [account, setAccount] = useState();
    const { isLatestVersion, dispatchProjectData, currentMethodologyVersion } = useMethodologyVersion(methodologyVersion);
    const isProjectFinalized = useFinalizedProject(projectId);

    const showNewMethodologyLatestVersion = !isProjectFinalized && !isLatestVersion

    let location = useLocation();
    const notificationButtonRef = useRef()

    // Commented because we don't use it currently,
    // DO NOT REMOVE MIGHT BE USED FOR FUTURE ITERATIONS
    // const userId = accounts[0].localAccountId;
  
    const {
        data: userPrefProjects,
        isFetching: loadingUserPrefProjects,
        isRefetching: refetchingUserPrefProjects
    } = useGETProjectClientHeaderInformationByProjectId(projectId);

    const {
        data: projectData
    } = useProject(projectId)

    const transformData = (data) => {
        let transformdata = []
        const dataArrangement = ['ClientName', 'ProjectName', 'ProjectType', 'ProjectState', 'ProjectPeriodEndDate', 'CLCIndustryName', 'MethodologyVersion']
        dataArrangement.map((key) => {
            if (key === 'ClientName') {
                var value = data[key]
                transformdata.push({
                    prefix: Constants.CLIENT_ID,
                    title: value,
                    subtitle: data['ClientID'],
                    styles: { width: value.length > 26 ? '330px' : '250px', marginLeft: '83px', fontSize: '21px', lineHeight: '24px' },
                    hasTooltipIcon: false
                })
                dispatchProjectData({ clientName: data[key] });
            }
            else if (key === 'ProjectName') {
                var value = data[key]
                transformdata.push({
                    prefix: Constants.PROJECT_NUMBER,
                    title: value,
                    subtitle: data['CLCProjectNumber'],
                    styles: { marginRight: value.length > 26 ? '20px' : '0px', width: value.length > 26 ? '270px' : '250px', marginLeft: '70px', fontSize: '17px', lineHeight: '19px' },
                    hasTooltipIcon: false
                })
                dispatchProjectData({ projectName: data[key] });
            }
            else if (key === 'ProjectState') {
                transformdata.push({
                    title: 'Project State',
                    subtitle: data[key],
                    styles: { width: '150px', marginLeft: '20px', fontSize: '17px', lineHeight: '19px' },
                    hasTooltipIcon: false
                })
            }
            else if (key === 'ProjectType') {
                transformdata.push({
                    title: 'Project Type',
                    subtitle: data[key],
                    styles: { width: '150px', marginLeft: '20px', fontSize: '17px', lineHeight: '19px' },
                    hasTooltipIcon: false
                })
            }
            else if (key === 'ProjectPeriodEndDate') {
                var date = !!data[key] ? moment.utc(data[key]).format('MM/DD/YYYY') : '';
                transformdata.push({
                    title: 'Period End Date',
                    subtitle: date,
                    styles: { width: '150px', marginLeft: '20px', fontSize: '17px', lineHeight: '19px' },
                    hasTooltipIcon: false
                })
            }
            else if (key === 'CLCIndustryName') {
                transformdata.push({
                    title: 'Project Industry',
                    subtitle: data[key],
                    styles: { width: '150px', marginLeft: '25px', fontSize: '17px', lineHeight: '19px' },
                    hasTooltipIcon: false
                })
            }
            else if (key === 'MethodologyVersion') {
                transformdata.push({
                    title: 'Methodology Version',
                    subtitle: data[key],
                    styles: { width: '218px', marginLeft: '20px', fontSize: '17px', lineHeight: '19px' },
                    hasTooltipIcon: true
                })
                setMethodologyVersion(data[key]);
                dispatchProjectData({ methodologyVersion: data[key] });
            }
        })
        return transformdata;
    }

    const getProjectMethodologyVersions = () => {
        return projectServices.getProjectMethodologyVersionsByProjectId(projectId);
    }
    
    const initializeContentData = (userPrefProjectData) => {
        if (userPrefProjectData) {
            setContent(transformData(userPrefProjectData));
        }
        setMethodologyVersions(getProjectMethodologyVersions());
    }

    const getAISData = () => {
        let data = []
        data.push({
            title: accounts,
            subtitle: Constants.DASHBOARD_SUB_TITLE,
            styles: { marginLeft: '83px' }
        })
        return data;
    }

    const handleClose = (event) => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleNotificationsOpen = () => {
        setAnchorEl(notificationButtonRef.current);
        setOpenNotifications(true);
    }
    const handleNotificationsClose = () => {
        if(notificationButtonRef.current){
            setAnchorEl(null);
            setOpenNotifications(false);
        }
    }
    

    const handleSignout = () => {
        const cacheId = Providers.getCacheId()
        CacheService.clearCacheById(cacheId);
        instance.logoutRedirect({
            postLogoutRedirectUri: Constants.REDIRECT_LOGOUT
        });
    }

    useEffect(() => {
        if (pageName === "project") {
            const loading = loadingUserPrefProjects || refetchingUserPrefProjects
            setLoadMapEntry('CLAHeader', loading)
            if (!loading) {
                initializeContentData(userPrefProjects?.data)
            }
        }
        else if (pageName === "ais") {
            setContent(getAISData())
        }
    }, [pageName, loadingUserPrefProjects, refetchingUserPrefProjects, userPrefProjects, location])

    useEffect(() => {
        if (content)
            setContent(value => {
                let methodologyVersionData = value.find(data => data.title === 'Methodology Version');
                methodologyVersionData.subtitle = currentMethodologyVersion;
                return value;
            });
        setMethodologyVersion(currentMethodologyVersion);
    }, [currentMethodologyVersion])

    useEffect(() => {
        setOpen(open)
    }, [open])

    useEffect(() => {
        const getUserData = async () => {
            const data = await getUserById(accounts[0].localAccountId);
            setAccount(data);
        }

        getUserData();
    }, [])

    const truncateString = (field, str) => string.truncate(str, (field === "title") ? 58 : 30)
    const imgComponent = () => {
        return (
            <img src={ClaLogo} className={styles.headerLogo} alt="CLA Logo" />
        )
    }

    const userNotificationsComponent = () => {
        return (
            <div>
                <span ref={notificationButtonRef} onClick={handleNotificationsOpen}>
                    <ClickAwayListener onClickAway={handleNotificationsClose}>
                        <div>
                            <CLANotificationsIconButton />
                            <Popper
                                style={{ zIndex: 1000 }}
                                open={openNotifications}
                                anchorEl={anchorEl}
                                role={undefined}
                                placement={'bottom-end'}
                                transition
                                disablePortal={false}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'right top' : 'right bottom',
                                        }}
                                    > 
                                        <Paper className={styles.claNotificationPaper}>
                                                <CLANotification/>
                                            
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </ClickAwayListener> 
                </span>
            </div> 
        )
    }
    const userSignOutComponent = () => {
        return (
            <div className={styles.dropdownPerson}>
                <span onClick={handleOpen} className={styles.spanPerson}>
                    <CLAPerson
                        styles={{
                            height: '40px',
                            width: '40px',
                            color: '#2e334e'
                        }}
                        className={styles.person}
                        personQuery="me"
                        account={account}
                    />
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <Popper
                        style={{ zIndex: 2 }}
                        open={open}
                        anchorEl={anchorEl}
                        role={undefined}
                        placement={'bottom-start'}
                        transition
                        disablePortal={false}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'right top' : 'right bottom',
                                }}
                            >
                                <Paper className={styles.claHeaderPaper}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList className={styles.claHeaderMenuList}>
                                            <MenuItem className={styles.menuItem} onClick={handleSignout}>Sign Out</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </span>
            </div>
        )
    }

    const onTooltipIconClicked = () => {
        setOpenModal(true);
    }

    const handleModalClose = (value) => {
        setOpenModal(false);
    };

    return (
        <>
            <Container className={styles.headerMainContainer} maxWidth={'true'}>
                <CLANavigation
                    navType={pageName}
                    logoSrc={imgComponent()}
                    userAvatarDropDown={userSignOutComponent()}
                    userNotificationsDropdown={userNotificationsComponent()}
                />
            </Container>
            <Container className={`${styles.headerSubContainer} ${styles.headerImg}`} maxWidth={'true'}>
                <img src={BgDashboardHeader} alt='Bg_Dashboard_Header.svg' />
                <Container className={styles.headerContentContainer} maxWidth={'true'}>
                    {content ? content.map((item, i) => {
                        return (<div key={i} className={styles.headerItem} style={{
                            marginRight:
                                item.hasOwnProperty('styles') || item.styles.hasOwnProperty('marginRight')
                                    ? item.styles.marginRight : '',
                            marginLeft:
                                item.hasOwnProperty('styles') || item.styles.hasOwnProperty('marginLeft')
                                    ? item.styles.marginLeft : '',
                            width:
                                item.hasOwnProperty('styles') || item.styles.hasOwnProperty('width')
                                    ? item.styles.width : ''
                        }}>
                            {item.title && (<span title={item.title[0].hasOwnProperty('name')
                                ? item.title[0].name.split(',')[1]
                                : item.title} className={pageName === 'ais' || i === 0 ? styles.headerTextName : styles.headerTextName2}
                            >
                                {item.title[0].hasOwnProperty('name') ? Constants.GREETINGS : ''}<b>
                                    <PageTitle title={item.title[0].hasOwnProperty('name')
                                        ? item.title
                                        : truncateString('title', item.title)} /></b>
                                {item.hasTooltipIcon ?
                                    <IconButton aria-label="Tooltip" onClick={onTooltipIconClicked} sx={{ padding: '4px' }} >
                                        <img alt='Tooltip' src={icon_tooltip} ></img>
                                    </IconButton>
                                    : ''}
                            </span>)}
                            <span className={styles.headerSubText}>
                                <PageSubTitle title={item.hasOwnProperty('prefix')
                                    ? item.prefix + item.subtitle
                                    : item.subtitle}
                                    subTitle={
                                        <>
                                            {item.hasOwnProperty('prefix')
                                                ? item.prefix + truncateString('subtitle', item.subtitle)
                                                : pageName === 'ais'
                                                    ? item.subtitle
                                                    : truncateString('subtitle', item.subtitle)}
                                          
                                                {item.title === 'Methodology Version' ? (
                                                    showNewMethodologyLatestVersion && (
                                                        <CLATooltip title="A new methodology version is available. Please review the release notes to determine if update is required">
                                                            <ErrorIcon
                                                                sx={{
                                                                    ml: 1,
                                                                    color: colors.saffron[400],
                                                                }}
                                                            />
                                                        </CLATooltip>
                                                    )
                                                ) : null}
                                        </>
                                    }
                                />
                            </span>
                            {item.hasTooltipIcon ?
                                <CLADialog visible={openModal} setVisible={handleModalClose} width={1076} >
                                    <MethodologyVersion methodologyVersionId={projectData?.MethodologyVersionId} />
                                </CLADialog>
                                : ''}
                        </div>)
                    }) : ''}
                </Container>
            </Container>
        </>
    )
}

export default CLAHeader;
import formApi from '@utilities/claApiForm';
import { useQuery, useQueries } from "@tanstack/react-query"
import logger from '@utilities/logService'

const getPerformanceStandards = async (methodologyVersionId, isEnabledOnly = false) => {
    try {

        const response = await formApi.get(`/performancestandards/${methodologyVersionId}`);

        return { status: 200, data: (!isEnabledOnly) ? response.data : response.data.filter(({ IsEnabled }) => IsEnabled) };
    } catch (error) {
        logger.error(error)
    }
}

export const useGetPerformanceStandards = (methodologyVersionId, filtered = false) => {
    return useQuery(
        ["performanceStandards", methodologyVersionId],
        async () => {
            const { data } = await getPerformanceStandards(methodologyVersionId)
            return data
        },
        {
            enabled: !!methodologyVersionId,
            select: (performanceStandards) => {
                if(!filtered){
                    return performanceStandards
                }

                const performanceStandardsFiltered = performanceStandards.filter(({ IsEnabled }) => IsEnabled)
                .map((performanceStandard) => ({
                  label: performanceStandard.PerformanceStandardName,
                  value: performanceStandard.PerformanceStandardId,
                }));

                return performanceStandardsFiltered
            }
        }
    )
}

export const usePerformanceStandardsAuditAreaData = (projectScopeId, performanceStandardIds) => {
    return useQuery(
        ['performanceStandardsAuditAreaData', ...performanceStandardIds],
        async () => {
            const { data } = await formApi.post(`/performancestandards/auditareadata/`, {
                projectScopeId,
                performanceStandardIds
            })
            return data
        },
        {
            enabled: Array.isArray(performanceStandardIds) 
                && performanceStandardIds.length > 0 
                && !!projectScopeId
        }
    )
}
  
const performanceStandardsServices = {
    getPerformanceStandards
}

export default performanceStandardsServices;
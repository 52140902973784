import { Comment, CommentResolved } from '@ais/assets';
import { CLATooltip } from "@ais/components";
import { CLASelect } from '@ais/forms';
import { useRoomIdle, useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from "@components/Concurrency/store/users";
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import { TailorProcedureContext } from '@components/Forms/state';
import { PROCEDURE_FIELD, TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import { useUserList } from '@hooks/index';
import { useFinalizedProject } from '@hooks/useProject';
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import projectFormServices from "@services/forms/projectforms";
import { updateProjectRiskByProjectFormIdProjectFormCustomProcedureId } from "@services/projectRisk";
import { assertionAcronymFormatter } from '@utilities/assertionAcronymFormatter.js';
import { formatUtcToLocalTimeZone } from '@utilities/dateHelpers';
import { extractTextOrUrl } from '@utilities/urlHelpers';
import isEqual from 'lodash/isEqual';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import VFRenderedFieldWrapper from '../../FormView/VFRenderedFieldWrapper';
import { useCLAProcedureItem } from './CLAProcedureContexProvider';
import styles from './SummaryProcedureSection.module.css';

const CustomProcedure = memo((props) => {
    const { 
        customProcedure, 
        projectRisks, 
        assertions, 
        userId, 
        auditAreaId, 
        getProjectFormProcedures, 
        sectionId,
        setSuccessToast,
        setErrorToast,
        setLoadingToast
    } = props;
    const {
        ProjectFormCustomProcedureId,
        CustomProcedureName: customProcedureName,
        Description: description,
        PrimaryAssertions: primaryAssertions,
        SecondaryAssertions: secondaryAssertions,
        ProjectRiskProjectFormCustomProcedures: projectRiskProjectFormCustomProcedures
    } = customProcedure;

    const { dispatchTailorProcedures } = useContext(TailorProcedureContext);
    const [openCommentBox, setOpenCommentBox] = useCLAProcedureItem(customProcedure.ProjectFormCustomProcedureId);
    const [workpaperRef, setWorkpaperRef] = useState('');
    const [checked, setChecked] = useState(false);
    const [comment, setComment] = useState('');
    const [isCLASelectOpen, setIsCLASelectOpen] = useState(false);
    const [signOff, setSignOff] = useState({
        signOffUser: null,
        signOffDate: null
    });

    const { projectId, projectFormId } = useParams();
    const [selectedRisks, setSelectedRisks] = useState([]);
    
    const separator = secondaryAssertions && primaryAssertions && secondaryAssertions.length > 0 && primaryAssertions.length > 0 ? ', ' : '';
    const tooltipAssertion =
        assertionAcronymFormatter(primaryAssertions, assertions, true, "AssertionName") +
        separator +
        assertionAcronymFormatter(secondaryAssertions, assertions, false, "AssertionName")
        .trim()
        .split(',') 
        .map(word => word.trim().split(' ').map(w => w.toLowerCase() === 'and' ? w : w.charAt(0).toUpperCase() + w.slice(1)).join(' '))
        .join(', ');

    const sortedProjectRisks = projectRisks.sort((a, b) => a.RiskName.toLowerCase().localeCompare(b.RiskName.toLowerCase()));
    const CLATextFieldRef = useRef(null);
    const TextFieldCommentRef = useRef(null);
    const CLASelectRef = useRef(null);
    const isProjectFinalized = useFinalizedProject(projectId);
    const {getUserById} = useUserList();

    const others = useOthers();
    const updateMyPresence = useUpdateMyPresence();
    const isIdle = useRoomIdle();

    const focusId = `custom-procedure-${ProjectFormCustomProcedureId}`;
    const isLocked = others.find((user) => user.presence.focusedId === focusId && user.info.userId != userId)
    const lockingUser = isLocked ? { userId: isLocked.info.userId, alternativeName: isLocked.info.name } : undefined
    const identifiedRiskFocusId = `${focusId}-identified-risk`;
    const isIdentifiedRiskLocked = others.find((user) => user.presence.focusedId === identifiedRiskFocusId && user.info.userId != userId)
    const identifiedRiskLockingUser = isIdentifiedRiskLocked ? { userId: isIdentifiedRiskLocked.info.userId, alternativeName: isIdentifiedRiskLocked.info.name } : undefined
    
    function handleCLASelectClick(toggle) {
        if(toggle) {
            updateMyPresence({ focusedId: identifiedRiskFocusId, type: 'custom' });
        } else {
            updateMyPresence({ focusedId: null, type: 'custom' });
        }
        setIsCLASelectOpen(toggle)
    }

    useEffect(() => {
        const _defaultValues = projectRiskProjectFormCustomProcedures ? projectRiskProjectFormCustomProcedures?.map(projectRisk => projectRisk.ProjectRiskId) : [];
        setSelectedRisks(_defaultValues);
    }, [projectRiskProjectFormCustomProcedures])

    const serviceDispatcher = async (payload) => {
        setLoadingToast(true)
        const { status, data } = await projectFormServices.upsertCustomProcedure(projectId, {
            ...payload,
            AuditAreaId: auditAreaId,
            PrimaryAssertions: primaryAssertions ? primaryAssertions : [],
            SecondaryAssertions: secondaryAssertions ? secondaryAssertions : []
        });
        if (status !== 200 || !data || data && data.length <= 0) {
            setErrorToast(true)
            setSuccessToast(false)
            setLoadingToast(false)
            return;
        }
        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.UPSERT_CUSTOM_PROCEDURE,
            payload: { 
                customProcedure: data[0],
                sectionId: sectionId
            }
        })

        setErrorToast(false)
        setSuccessToast(true)
        setLoadingToast(false)
    }

    useEffect(() => {
        if (customProcedure?.Comment)
            setComment(customProcedure.Comment);
        else
            setComment('');

        if (customProcedure?.WorkpaperReference)
            setWorkpaperRef(customProcedure.WorkpaperReference);
        else
            setWorkpaperRef('');

        if (customProcedure?.SignOffUser) {
            (async () => {
                const users = await getUserById(customProcedure.SignOffUser, true);
                const { firstName = '', lastName = '', employeeId = null } = users;
                setSignOff({
                    signOffUser: `${firstName} ${lastName ? lastName[0] : ''}.` + (employeeId && (employeeId !== 'null') ? `(${employeeId})` : ''),
                    signOffDate: customProcedure?.SignOffDate ? formatUtcToLocalTimeZone(customProcedure.SignOffDate) : null,
                })
            })();
        } else {
            setSignOff({
                signOffUser: null,
                signOffDate: null
            })
        }
        setChecked(!!customProcedure?.SignOffUser);
    }, [customProcedure]);

    useEffect(() => {
        if(isIdle) {
            handleCLASelectClick(false)
        }
    }, [isIdle])

    const handleOnFocusCustomProcedure = () => {
        updateMyPresence({ focusedId: focusId, type: 'custom' });
    }


    const handleOnChange = async (values) => {
        updateMyPresence({ focusedId: null, type: 'custom' });
        if(!Array.isArray(values) || isEqual(selectedRisks, values)) return;
        
        setLoadingToast(true)

        const res = await updateProjectRiskByProjectFormIdProjectFormCustomProcedureId(projectId, ProjectFormCustomProcedureId, projectFormId, values);
        if (!res || res.status !== 201) {
            setErrorToast(true)
            setSuccessToast(false)
            setLoadingToast(false)
            return;
        }

        setErrorToast(false)
        setSuccessToast(true)
        setLoadingToast(false)
        setSelectedRisks(values);
        getProjectFormProcedures();
    }

    const handleCommentSave = async (e) => {
        updateMyPresence({ focusedId: null, type: 'custom' });
        if(isIdle) return;
        const comment = e.target.value;
        const newcustomProcedure = { ...customProcedure };
        newcustomProcedure.Comment = comment;
        await serviceDispatcher(newcustomProcedure);
    };

    const handleWorkpaperRefSave = async (e) => {
        updateMyPresence({ focusedId: null, type: 'custom' });
        if(isIdle) return;
        const workpaperRef = e.target.value;
        const newcustomProcedure = { ...customProcedure };
        newcustomProcedure.WorkpaperReference = workpaperRef;
        await serviceDispatcher(newcustomProcedure);
    };

    const handleCheckSave = async (e) => {
        const checked = e.target.checked;
        if (customProcedure.ProjectFormCustomProcedureId) {
            const newcustomProcedure = { ...customProcedure };
            newcustomProcedure.SignOffUser = checked ? userId.trim() : null
            newcustomProcedure.SignOffDate = checked ? new Date().toISOString().slice(0, 21).replace('T', ' ') : null;

            const signOffData = {
                signOffUser: null,
                signOffDate: null,
            }

            if (checked) {
                const { firstName = '', lastName = '', employeeId = null } = await getUserById(newcustomProcedure.SignOffUser);
                signOffData.signOffUser = `${firstName} ${lastName ? lastName[0] : ''}. ` + (employeeId && (employeeId !== 'null') ? `(${employeeId})` : '');
                signOffData.signOffDate = formatUtcToLocalTimeZone(newcustomProcedure.SignOffDate);
            }

            setSignOff(signOffData)
            setChecked(checked);
            await serviceDispatcher(newcustomProcedure);
        }
    };

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: '20px 15px 10px 15px',
            }}>
                <Box sx={{ width: '20%', fontSize: '16px', fontWeigth: '700' }}> {`${customProcedureName}`}</Box>
                <Box sx={{ width: '40%' }}>
                    <VFRenderedFieldWrapper 
                        isLockedByUser={identifiedRiskLockingUser}
                        isLocked={isIdentifiedRiskLocked}
                        isInstance
                    >
                        <CLASelect
                            ref={CLASelectRef}
                            data-test="identifiedRisk-dropdown"
                            placeholder="Select"
                            defaultValues={selectedRisks}
                            menuItems={sortedProjectRisks?.map(projectRisk => ({ ...projectRisk, label: projectRisk.RiskName, value: projectRisk.ProjectRiskId }))}
                            onChange={handleOnChange}
                            isDisabled={isIdentifiedRiskLocked || isProjectFinalized}
                            onClose={() => handleCLASelectClick(false)}
                            onOpen={() => handleCLASelectClick(true)}
                            open={isCLASelectOpen}
                        />
                    </VFRenderedFieldWrapper>
                </Box>
                <Box sx={{ width: '40%' }} />
            </Box >
            <Box sx={{ p: '0 15px 10px 15px' }}>
                <VFRenderedFieldWrapper 
                    isLockedByUser={lockingUser}
                    isLocked={isLocked}
                    isInstance
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: '20px',
                            fontSize: '14px',
                            fontWeigth: 700
                        }}>
                            <Box sx={{
                                width: '60%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Box>
                                    <span
                                        className="ql-editor"
                                        style={{ paddingRight: '10px', display: 'grid' }}
                                        dangerouslySetInnerHTML={{ __html: extractTextOrUrl(description) }}
                                   />
                                </Box>
                            </Box>
                            <Box className={styles.tableCenter__cell} sx={{textAlign:'center'}}>
                                <CLATooltip 
                                    title={tooltipAssertion}
                                    PopperProps={{ sx: { zIndex: 1500 } }}
                                    placement="top"
                                >                
                                    <Typography sx={{
                                        overflowWrap: 'break-word',
                                        fontSize: '16px',
                                        color: '#4B4B4B',
                                        textAlign: 'center'
                                    }}>
                                        {assertionAcronymFormatter(primaryAssertions, assertions, true) + separator + assertionAcronymFormatter(secondaryAssertions, assertions)}
                                    </Typography>
                                </CLATooltip>                                                              
                            </Box>
                            <Box sx={{ display:'flex', width: '10%' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={(e) => handleCheckSave(e)}
                                            onBlur={handleOnChange}
                                            disabled={isLocked || isProjectFinalized}
                                        />
                                    }
                                    label={<Typography component='p' sx={{ fontSize: '0.7rem' }}>
                                        {signOff.signOffUser}<br /><span>{signOff.signOffDate}</span>
                                    </Typography>}
                                    labelPlacement="end"
                                />
                            </Box>
                            <Box sx={{ width: '10%', alignContent: 'center' }}>
                                <CLATextField
                                    ref={CLATextFieldRef}
                                    label=""
                                    onChange={(e) => setWorkpaperRef(e.target.value)}
                                    value={workpaperRef}
                                    inputLableShrink={true}
                                    onBlur={handleWorkpaperRefSave}
                                    placeholder={PROCEDURE_FIELD.WORKPAPER_REF} 
                                    onFocus={handleOnFocusCustomProcedure} 
                                    disabled={isLocked || isProjectFinalized} />
                            </Box>
                            <Box className={styles.tableCenter__cell}
                                sx={{
                                    pr: '65px',
                                    justifyContent: 'end'
                                }}>
                                <IconButton
                                    sx={{ padding: 0 }}
                                    onClick={() => setOpenCommentBox((prevOpenCommentBox) => !prevOpenCommentBox)}
                                >
                                    {comment ? <CommentResolved /> : <Comment />}
                                </IconButton>
                            </Box>
                        </Box>
                        {
                            openCommentBox && <Box sx={{ p: `20px 65px 10px 0px` }}> 
                            <TextField
                                ref={TextFieldCommentRef}
                                label={PROCEDURE_FIELD.COMMENT}
                                fullWidth
                                multiline
                                placeholder="Enter comment"
                                rows={2}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                onBlur={handleCommentSave}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    notched: true
                                }}
                                inputProps={{
                                    maxLength: 4096
                                }}
                                onFocus={handleOnFocusCustomProcedure}
                                disabled={isLocked || isProjectFinalized}
                            />
                            </Box>
                        }
                    </Box>
                </VFRenderedFieldWrapper>
            </Box>
        </>
    )
});

export default CustomProcedure;

import { useContext, useState, useEffect } from "react";
import {
  Button,
  Grid,
  FormControl,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useUpdateEffect } from "react-use";
import { useForm, useWatch } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup"
import { Form } from "@ais/palette";

import {FIELD_TYPES} from '@ais/constants';
import { DIALOG, TRIGGERS, VALIDATION_TYPES } from "@constants/forms";
import { SchemaContext } from "@ais/providers";
import {
  createField,
  dropNewItem,
  updateFormItem,
  filterEmptyCriterias,
  isTriggeredFromQuestionGroup,
  validateAdvancedDefaultAnswers,
  getShareableComponentDefaultValue
} from "@components/Forms/helpers";
import { BaseDialog } from ".";
import {
  BasicTextField,
  ToggleableTextarea,
  BasicDropdown,
  RadioSelect,
  ExternalAccess,
} from "./dialog-components/settings";
import { RulesTab } from "./dialog-components/rules";
import {CLACurrencyField, CLANumberField, CLAPercentageField} from '@ais/forms';
import DefaultValueDrawer from "./dialog-components/settings/DefaultValueDrawer";
import DefaultValues from "./dialog-components/settings/DefaultValues";

const validationOptions = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Numerical",
    value: VALIDATION_TYPES.NUMERICAL,
  },
  {
    label: "US Currency",
    value: VALIDATION_TYPES.USCURRENCY,
  },
  {
    label: "Percentage",
    value: VALIDATION_TYPES.PERCENTAGE,
  },
];

const widthOptions = [
  {
    label: "25%",
    value: 25,
  },
  {
    label: "50%",
    value: 50,
  },
  {
    label: "75%",
    value: 75,
  },
  {
    label: "100%",
    value: 100,
  },
];

export const ShortAnswerDialog = ({
  visible,
  setVisible,
  additionalData,
  trigger,  
}) => {
  const { LABEL, PLACEHOLDER } = DIALOG.SETTINGS;
  const { SHORT_ANSWER } = DIALOG.DEFAULT_ANSWERS;
  const { EDIT_ADVANCED, ADVANCED, ADVANCED_MODE_DETECTED } =
    DIALOG.DEFAULT_ANSWERS.GENERAL;

  const { schema, dispatchSchema } = useContext(SchemaContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [defaultValues, setDefaultValues] = useState([]);
  const [isValidForm, setIsValidForm] = useState(false);

  const initialValues = {
    label: additionalData?.label ?? '',
    required: additionalData?.required ?? true,
    validation: additionalData?.validation ?? 'none',
    placeholder: additionalData?.placeholder ?? '',
    defaultValue: additionalData?.defaultValue ?? '',
    defaultValues: additionalData?.defaultValues ?? [],
    tooltip: additionalData?.tooltip ?? '',
    width: additionalData?.width ?? 100,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
            behavior: 'show',
            match: 'any',
            criterias: [],
            excludeAnswerOnRollForward: false,
            canNotBeDeleted: true,
          },
    visibleToClient: getShareableComponentDefaultValue(additionalData, "visibleToClient", trigger),
    editableByClient: getShareableComponentDefaultValue(additionalData, "editableByClient", trigger),
  };

  const schemaValidation = Yup.object().shape({
    label: Yup.string().trim().required('')
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const watchValidation = useWatch({ control: formMethods.control, name: 'validation' }) ?? 'none';
  const watchDefaultValue = useWatch({ control: formMethods.control, name: 'defaultValue' }) ?? '';
  const watchDefaultValues = useWatch({ control: formMethods.control, name: 'defaultValues' }) ?? [];

  const handleSubmit = values => {
    const filteredRules = filterEmptyCriterias(values.rules);
    if (![TRIGGERS.EDIT_BTN, TRIGGERS.QUESTION_GROUP_EDIT].some((t) => t === trigger)) {
      const newField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        ...values,
        width: +values.width,
        rules: filteredRules,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        ...values,
        width: +values.width,
        rules: filteredRules,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  const handleChange = (values) => {
    const validationResult = validateAdvancedDefaultAnswers(values, watchDefaultValues);
    setIsValidForm(validationResult.isValid);
    setDefaultValues(validationResult.newValues);
  };

  const handleSave = () => {
    formMethods.setValue('defaultValues', defaultValues.filter(def => def.defaultValue.length && def.industries.length));
    formMethods.setValue('defaultValue', '');
    setOpenDrawer(false);
  };

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger(); // initial validation
  }, [visible]);

  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => (
        <Grid container rowSpacing={6} columnSpacing={22}>
          <Grid item xs={12}>
            <BasicTextField
              name="label"
              label={LABEL.QUESTION_LABEL}
              placeholder={PLACEHOLDER.QUESTION}
              maxLength={256}
              showRequired
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <BasicDropdown
                  name="validation"
                  label={LABEL.VALIDATION}
                  options={validationOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <BasicTextField
                  name="placeholder"
                  label={LABEL.PLACEHOLDER_TEXT}
                  placeholder={PLACEHOLDER.TEXT}
                  maxLength={256}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography
                    variant="label"
                    sx={{
                      marginBottom: "8px",
                    }}
                  >
                    {LABEL.DEFAULT_VALUE}
                  </Typography>
                  {watchValidation === "none" && (
                    <OutlinedInput
                      name="defaultValue"
                      placeholder={
                        watchDefaultValues.length > 0
                          ? ADVANCED_MODE_DETECTED
                          : PLACEHOLDER.VALUE
                      }
                      disabled={watchDefaultValues.length > 0}
                      readOnly={
                        watchDefaultValues.length ? true : false
                      }
                      value={
                        watchDefaultValues.length
                          ? ""
                          : watchDefaultValue
                      }
                      onChange={(e) => formMethods.setValue('defaultValue', e.target.value)}
                    />
                  )}
                  {watchValidation === VALIDATION_TYPES.NUMERICAL && (
                    <CLANumberField
                      {...(watchDefaultValues.length > 0 && { value: "" })}
                      name="defaultValue"
                      value={
                        watchDefaultValues.length
                          ? ""
                          : watchDefaultValue
                      }
                      onChange={(e, value) => formMethods.setValue('defaultValue', value)}
                      disabled={watchDefaultValues.length > 0}
                      placeholder={
                        watchDefaultValues.length > 0
                          ? ADVANCED_MODE_DETECTED
                          : PLACEHOLDER.VALUE
                      }
                      digitGroupSeparator=","
                    />
                  )}
                  {watchValidation === VALIDATION_TYPES.USCURRENCY && (
                    <CLACurrencyField
                      {...(watchDefaultValues.length > 0 && { value: '' })}
                      name="defaultValue"
                      value={watchDefaultValues.length ? '' : watchDefaultValue}
                      onChange={(e, value) => formMethods.setValue('defaultValue', value)}
                      disabled={watchDefaultValues.length > 0}
                      useAsDisplay={watchDefaultValues?.length > 0}
                      placeholder={
                        watchDefaultValues.length > 0 ? ADVANCED_MODE_DETECTED : PLACEHOLDER.VALUE
                      }
                    />
                  )}
                  {watchValidation === VALIDATION_TYPES.PERCENTAGE && (
                    <CLAPercentageField
                      {...(watchDefaultValues.length > 0 && { value: "" })}
                      name="defaultValue"
                      value={
                        watchDefaultValues.length
                          ? ""
                          : watchDefaultValue
                      }
                      onChange={(e, value) => formMethods.setValue('defaultValue', value?.toString())}
                      disabled={watchDefaultValues.length > 0}
                      placeholder={
                        watchDefaultValues.length > 0
                          ? ADVANCED_MODE_DETECTED
                          : PLACEHOLDER.VALUE
                      }
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingTop: "5px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpenDrawer(true);
                      }}
                      variant="text"
                      disableRipple
                      sx={{
                        fontFamily: "museo-sans",
                        textTransform: "none",
                        color: "#76D2D3",
                        fontSize: "16px",
                        p: 0,
                        ":hover": {
                          backgroundColor: "inherit",
                        },
                      }}
                    >
                      {watchDefaultValues.length
                        ? EDIT_ADVANCED
                        : ADVANCED}
                    </Button>
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {!isTriggeredFromQuestionGroup(trigger) && <ExternalAccess
                  allowExternalAccessName="visibleToClient"
                  makeAnswerableName="editableByClient"
                />}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container height="100%">
              <Grid item xs={12}>
                <ToggleableTextarea
                  name="tooltip"
                  label={LABEL.ADD_TOOLTIP}
                  placeholder={PLACEHOLDER.TEXT}
                  toggleable
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <RadioSelect
                  name="width"
                  label={LABEL.DISPLAY_WIDTH}
                  options={widthOptions}
                />
              </Grid>
            </Grid>
          </Grid>
          <DefaultValueDrawer
            name="defaultValues"
            formComponentName={SHORT_ANSWER.COMPONENT_NAME}
            drawerVisible={openDrawer}
            onClose={() => setOpenDrawer(false)}
            handleSave={handleSave}
            isValidForm={isValidForm}
            headerText={SHORT_ANSWER.HEADER_TEXT}
            displayWarning={true}
          >
            <DefaultValues
              onChange={handleChange}
              fieldType={FIELD_TYPES.SHORT_ANSWER}
            />
          </DefaultValueDrawer>
        </Grid>
      ),
    },
    {
      label: LABEL.RULES,
      render: () => (
        <RulesTab
          additionalData={additionalData}
          trigger={trigger}
        />
      ),
    },
  ];

  useUpdateEffect(() => {
    formMethods.setValue('defaultValue', '');
    formMethods.setValue('defaultValues', []);
  }, [watchValidation]);

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={isTriggeredFromQuestionGroup(trigger) ? '' : additionalData?.id}
        idPrefix={isTriggeredFromQuestionGroup(trigger) ? '' : LABEL.QUESTION_ID}
        title={LABEL.SHORT_ANSWER}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={isTriggeredFromQuestionGroup(trigger) ? tabs.filter((tab) => tab.label !== LABEL.RULES) : tabs}
        trigger={trigger}
      />
    </Form>
  );
};

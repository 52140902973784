import { useContext, useEffect, useState, } from 'react';
import { useForm, useWatch } from "react-hook-form"
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";
import { DIALOG, TRIGGERS } from '@constants/forms';
import get from 'lodash/get';

import formCategoriesService from '@services/forms/formCategories.js';
import formServices from '@services/forms/forms.js';
import questionsService from '@services/forms/questions.js';

import { FIELD_TYPES, QUESTION_LINK_TYPE, SCHEMA_ACTION_TYPES } from '@ais/constants';
import { parseQuestionLabel } from '@ais/forms';
import { createField, dropNewItem, updateFormItem } from '@components/Forms/helpers';
import { SchemaContext } from '@ais/providers';
import { BaseDialog } from '.';
import {
  BasicTextField,
  BasicDropdown,
  RadioSelect,
  ExternalAccess,
} from './dialog-components/settings';
import { VFRenderedField } from '@components/FormView';

import { useLoading } from '@hooks/index';

const widthOptions = [
  {
    label: '25%',
    value: 25,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '75%',
    value: 75,
  },
  {
    label: '100%',
    value: 100,
  },
];

const getValuesForQuestionDropdown = (field) => {
  if (![FIELD_TYPES.TABLE, FIELD_TYPES.QUESTION_GROUP].includes(field.type)) {
    return {
      label: field.label,
      value: field.id,
    };
  }

  const parsed = parseQuestionLabel(field.label);
  const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
  return {
    label,
    value: field.id,
  };
};

export const ExistingQuestionDialog = ({
  visible,
  setVisible,
  additionalData,
  trigger,
}) => {
  const { LABEL, ERROR } = DIALOG.SETTINGS;
  const questionLinkTypes = QUESTION_LINK_TYPE.QUESTION_LINK_TYPES;
  const { schema, dispatchSchema } = useContext(SchemaContext);
  const [formCategoryOptions, setFormCategoryOptions] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [formSchema, setFormSchema] = useState(null);
  const [formSectionsOptions, setFormSectionOptions] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [fetchedQuestion, setFetchedQuestion] = useState(null);
  const [existingQuestionId, setExistingQuestionId] = useState(null);
  const [loadingExistingQuestionData, setLoadingExistingQuestionData] = useState(false);
  const [hideFetchedQuestion, setHideFetchedQuestion] = useState(false);

  const methodologyVersionId = schema?.formSettings?.methodologyVersionId;

  const initialValues = {
    formCategoryId: '',
    formId: '',
    sectionId: '',
    questionId: '',
    searchByQuestionID: '',
    linkType: '',
    width: 100,
    visibleToClient: true,
    editableByClient: true,
  };

  const setLoading = useLoading()

  const validateQuestionID = (data) => {
    const pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return data.match(pattern);
  }

  const validateQuestionRequiredIndustries = (data) => {
    let valid = false;
    if (!data) {
      //No data passed
      return valid;
    }
    data?.formMetaData.forEach(formMetaData => {
      if (formMetaData?.industryData?.filter(industry => schema?.formSettings?.formIndustry.some(formIndustry => formIndustry.MethodologyIndustryId == industry.MethodologyIndustryId))?.length > 0) {
        valid = true;
      }
    });
    return valid;
  }

  const fetchInputQuestion = async (questionID, context) => {
    const questionIdValue = questionID ?? '';
    if (validateQuestionID(questionIdValue)) {
      let questionFound = false;

      try {
        setLoading(true)
        const { data } = await questionsService.getRequiredQuestionById(questionID, true, schema.formSettings.methodologyVersionId);
        if (validateQuestionRequiredIndustries(data)) {
          setFetchedQuestion(data);
          setExistingQuestionId(data.id);
          setLoading(false);
          questionFound = true;
        } else {
          setFetchedQuestion(null);
          setExistingQuestionId(null);
          setLoading(false);
          return context.createError({ message: ERROR.QUESTION_INDUSTRY_REQUIRED_NON_RULES });
        }
      } catch {
        setFetchedQuestion(null);
        setExistingQuestionId(null);
        setLoading(false);
        return context.createError({ message: ERROR.QUESTION_ID_INVALID });
      }

      // setTimeout here to prevent previously selected question from briefly flashing on the screen.
      setTimeout(() => setHideFetchedQuestion(false));
      return questionFound;
    } else
      return context.createError({ message: ERROR.QUESTION_ID_INVALID });
  }

  const schemaValidation = Yup.object().shape({
    /***
     * This validation triggers when existing question is populated from dropdowns.
     * Added !!! in searchByQuestionID to make it truthy/falsey then inverting its state 
     */
    questionId: Yup.string().ensure().trim()
      .when(['formCategoryId', 'formId', 'searchByQuestionID'], {
        is: (formCategoryId, formId, searchByQuestionID) => !!!searchByQuestionID && !!formCategoryId && !!formId,
        then: (schema) => schema.required()
          .test({
            name: 'questionId_validation',
            test: async (value, context) => await fetchInputQuestion(value, context)
          })
      }),
    /***
     * This validation triggers when existing question is populated from pasting Question Id. 
     */
    searchByQuestionID: Yup.string().ensure().trim()
      .when('questionId', {
        is: (value) => !value,
        then: (schema) => schema.required('')
          .test({
            name: 'searchByQuestionID_validation',
            test: async (value, context) => await fetchInputQuestion(value, context)
          })
      }),
    linkType: Yup.string().ensure().trim()
      .when(['questionId', 'searchByQuestionID'], {
        is: (questionId, searchByQuestionID) => !questionId || !searchByQuestionID,
        then: (schema) => schema.required('')
      })
  }, ['questionId', 'searchByQuestionID'])

  const formMethods = useForm({
    defaultValues: initialValues,
    // values: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const { errors } = formMethods.formState;

  const watchFormCategoryId = useWatch({ control: formMethods.control, name: 'formCategoryId' });
  const watchFormId = useWatch({ control: formMethods.control, name: 'formId' });
  const watchSectionId = useWatch({ control: formMethods.control, name: 'sectionId' });
  const watchQuestionId = useWatch({ control: formMethods.control, name: 'questionId' });
  const watchLinkType = useWatch({ control: formMethods.control, name: 'linkType' });

  const handleSubmit = values => {
    dispatchSchema({
      type: SCHEMA_ACTION_TYPES.UPDATE_NEWLY_ADDED_IDS,
      payload: [...schema.newlyAddedIds, additionalData.id],
    });

    if (trigger !== TRIGGERS.EDIT_BTN) {
      const newField = createField({
        ...fetchedQuestion,
        type: fetchedQuestion?.type,
        id: additionalData.id,
        existingQuestionId,
        index: additionalData.refField.index,
        width: +values.width,
        linkType: values.linkType,
        visibleToClient: values.visibleToClient,
        editableByClient: values.editableByClient,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        ...fetchedQuestion,
        type: fetchedQuestion?.type,
        id: additionalData.id,
        existingQuestionId,
        index: additionalData.index,
        width: +values.width,
        linkType: values.linkType,
        visibleToClient: values.visibleToClient,
        editableByClient: values.editableByClient,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  const tabs = [
    {
      label: 'Import Question',
      render: () => (
        <Grid container rowSpacing={5} columnSpacing={8}>
          <Grid item xs={3}>
            <BasicDropdown
              name="formCategoryId"
              label="Select Form Category"
              placeholder={DIALOG.SETTINGS.PLACEHOLDER.BASIC_DROPDOWN}
              options={formCategoryOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <BasicDropdown
              name="formId"
              label="Select Form"
              placeholder={DIALOG.SETTINGS.PLACEHOLDER.BASIC_DROPDOWN}
              options={formOptions}
              disabled={!formOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <BasicDropdown
              name="sectionId"
              label="Select Section"
              placeholder={DIALOG.SETTINGS.PLACEHOLDER.BASIC_DROPDOWN}
              options={formSectionsOptions}
              disabled={!formSectionsOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <BasicDropdown
              name="questionId"
              label="Select Question"
              placeholder={DIALOG.SETTINGS.PLACEHOLDER.BASIC_DROPDOWN}
              options={questionOptions}
              disabled={!questionOptions.length}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <BasicTextField
                  name="searchByQuestionID"
                  label="OR paste in question ID from source"
                  placeholder="Question ID"
                  maxLength={256}
                  helperText={<div style={{ color: 'red', fontWeight: 'bold' }}>{get(errors['searchByQuestionID'], 'message')}</div>}
                />
              </Grid>
              <Grid item xs={6}>
                <BasicDropdown
                  name="linkType"
                  label="Type of Question Link"
                  placeholder={DIALOG.SETTINGS.PLACEHOLDER.BASIC_DROPDOWN}
                  options={questionLinkTypes}
                  tooltip={QUESTION_LINK_TYPE.TOOLTIP}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ py: "100px !important" }}>
            <Grid container rowSpacing={5} columnSpacing={8} justifyContent="center" alignItems="center">
              <Grid item xs={12} >
                {
                  (!hideFetchedQuestion && fetchedQuestion) ? (
                    <VFRenderedField field={{ ...fetchedQuestion, rules: null }} disabled={true} />
                  ) : (
                    <div style={{ textAlign: 'center' }}>Question displays here (from selection above)</div>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <ExternalAccess
                allowExternalAccessName="visibleToClient"
                makeAnswerableName="editableByClient"
                viewableOnly={watchLinkType === questionLinkTypes[3]}
              />
            </Grid>
            <Grid container xs={6} justifyContent="flex-end">
              <Grid item xs="auto">
                <RadioSelect
                  name="width"
                  label={LABEL.DISPLAY_WIDTH}
                  options={widthOptions}
                  sx={{ justifyContent: "right" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      label: LABEL.SETTINGS,
      render: () => <div>Existing Question Settings</div>,
    },
    {
      label: LABEL.RULES,
      render: () => <div>Existing Question rules</div>,
    },
  ];

  const loadFormCategoryOptions = async (formCategoryId) => {
    try {
      const { data: categories } = await formCategoriesService.getFormCategories(methodologyVersionId);
      const categoryOptions = categories.filter((item) => item.IsEnabled).map(c => ({
        label: c.FormCategoryName,
        value: c.FormCategoryId,
      }))


      const categoryIds = categories.map(c => (c.FormCategoryId));
      if(!!formCategoryId && !categoryIds.includes(formCategoryId)){
        const { data: preExistingCategory }  =  await formCategoriesService.getFormCategoryByCategoryId(formCategoryId);
        const updatedCategory = categoryOptions.filter((category)=> category.label == preExistingCategory.FormCategoryName); 
        formCategoryId = updatedCategory[0]?.value;
      }
      setFormCategoryOptions(categoryOptions);
    }
    catch (error) {
      toast.error(error.toString());
    }

    return formCategoryId;
  };

  const loadFormOptions = async (formCategoryId, formId) => {
    if (formCategoryId || watchFormCategoryId) {
      try {
        const { data: forms } = await formCategoriesService.getRequiredFormsByCategoryId(formCategoryId || watchFormCategoryId, true, methodologyVersionId);
        let parsedData = forms.filter(form => (form.hasOwnProperty('industryData') &&
          form?.industryData?.filter((industry) =>
            schema?.formSettings?.formIndustry.some((formIndustry) =>
              industry.IsRequired == true && formIndustry.MethodologyIndustryId == industry.MethodologyIndustryId))?.length > 0) || form.FormName === schema?.formSettings?.formname);

        const formOptions = parsedData.map(f => ({
          label: f.FormName,
          value: f.FormId,
        }));

        //Check if formId is included in formOptions, if not then try to update value based on the formName
        const formIds = parsedData.map(f => f.FormId)
        if (!!formCategoryId && !formIds.includes(formId)) {
          const previousForm = await formServices.getFormById(formId);
          const updatedForm = formOptions.filter((data) => data.label == previousForm.formName)
          formId = updatedForm[0]?.value;
        }
        setFormOptions(formOptions);
      } catch (error) {
        toast.error(error.toString());
      }
    }
    return formId;
  };

  const loadSectionOptions = async (formId) => {
    try {
      const data = await formServices.getFormWithSchemaByFormId(formId || watchFormId);
      setFormSchema(data.formSchema);
      setFormSectionOptions(data.formSchema.flatMap((s) => ({
        label: s.title,
        value: s.id,
      })));
      setQuestionOptions(data.formSchema.flatMap(section => section.fields.flatMap(row => row.flatMap((e) => getValuesForQuestionDropdown(e)))));
    } catch (error) {
      toast.error(error.toString());
    }
  };

  const loadQuestionOptions = () => {
    let options = [];
    const section = formSchema.find((section) => section.id === watchSectionId);
    if (section) {
      options = section.fields
        .flatMap(row => row)
        .flatMap((field) => getValuesForQuestionDropdown(field));
    }
    setQuestionOptions(options);
  };

  useEffect(async () => {
    if (visible) {
      setLoadingExistingQuestionData(true);
      setLoading(true);
      const formCategoryId = await loadFormCategoryOptions(additionalData?.formCategoryId);
      const formId = await loadFormOptions(formCategoryId, additionalData?.formId);
      if (!!additionalData.formId) {
        await loadSectionOptions(formId);
      }
      const newInitialValues = {
        formCategoryId: formCategoryId ?? '',
        formId:  formId ?? '',
        sectionId: additionalData?.sectionId?.toLowerCase() ?? '',
        questionId: additionalData?.existingQuestionId ?? '',
        searchByQuestionID: additionalData?.existingQuestionId ?? '',
        linkType: additionalData?.linkType ?? '',
        width: additionalData?.width ?? 100,
        visibleToClient: additionalData.hasOwnProperty('visibleToClient') ? additionalData.visibleToClient : true,
        editableByClient: additionalData.hasOwnProperty('editableByClient') ? additionalData.editableByClient : true,
      }
      formMethods.reset(newInitialValues);
      formMethods.trigger();
      setLoadingExistingQuestionData(false);

      let data = additionalData;
      if(additionalData?.existingQuestionId){
        //Attempt to fetch the actual question data for the display so we render the original component instead of existing question component (The display when creating uses this original component as well for the display, if we render different components at create vs edit, the actual visible component may be inconsistent)
        const result = await questionsService.getRequiredQuestionById(additionalData.existingQuestionId, true, schema.formSettings.methodologyVersionId);
        if (result) {
          data = result.data;
        } 
      }

      setFetchedQuestion(data);
      setExistingQuestionId(additionalData.existingQuestionId);
      setHideFetchedQuestion(false);
      setLoading(false);
    }
  }, [additionalData]);

  useEffect(() => {
    if (!visible) {
      setFormOptions([]);
      setFormSectionOptions([]);
      setQuestionOptions([]);
      setFetchedQuestion(null);
      formMethods.reset(initialValues);
    } else {
      formMethods.trigger()
    }
  }, [visible]);

  useEffect(() => {
    async function fetchCategoryData() {
      try {
        let { data } = await formCategoriesService.getFormCategories(methodologyVersionId);
        data = data.filter((item) => item.IsEnabled).map((e) => {
          return {
            label: e.FormCategoryName,
            value: e.FormCategoryId
          }
        })
        setFormCategoryOptions(data);
      }
      catch (error) {
        toast.error(error.toString());
      }
    }
    fetchCategoryData();
  }, []);

  useEffect(() => {
    if (loadingExistingQuestionData) return;

    if (!!watchFormCategoryId) {
      loadFormOptions().finally(setFetchedQuestion(null));
    } else {
      setHideFetchedQuestion(true);
      formMethods.setValue('searchByQuestionID', initialValues.searchByQuestionID);
      formMethods.setValue('questionId', initialValues.questionId);
      formMethods.setValue('sectionId', initialValues.sectionId);
      formMethods.setValue('formId', initialValues.formId);

      setFormOptions([]);
      setFormSectionOptions([]);
      setQuestionOptions([]);
    }
  }, [watchFormCategoryId]);

  useEffect(() => {
    if (loadingExistingQuestionData) return;

    if (!!watchFormId) {
      loadSectionOptions().finally(setFetchedQuestion(null));
    } else {
      setHideFetchedQuestion(true);
      formMethods.setValue('searchByQuestionID', initialValues.searchByQuestionID);
      formMethods.setValue('questionId', initialValues.questionId);
      formMethods.setValue('sectionId', initialValues.sectionId);
      setFormSectionOptions([]);
      setQuestionOptions([]);
    }
  }, [watchFormId]);

  useEffect(() => {
    if (loadingExistingQuestionData) return;

    if (!!watchSectionId) {
      loadQuestionOptions();
      formMethods.trigger();
      setFetchedQuestion(null);
    } else {
      setHideFetchedQuestion(true);
      formMethods.setValue('searchByQuestionID', initialValues.searchByQuestionID);
      formMethods.setValue('questionId', initialValues.questionId);
      setQuestionOptions([]);
    }
  }, [watchSectionId]);

  useEffect(() => formMethods.trigger(), [watchQuestionId, watchLinkType])

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={additionalData?.id}
        idPrefix={LABEL.QUESTION_ID}
        title={LABEL.EXISTING_QUESTION}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={tabs}
        trigger={trigger}
      />
    </Form>
  );
};

ExistingQuestionDialog.propTypes = {
  visible: PropTypes.bool,
  additionalData: PropTypes.object,
  trigger: PropTypes.string,
  setVisible: PropTypes.func,
};

ExistingQuestionDialog.defaultProps = {
  value: false,
  additionalData: null,
  trigger: '',
};

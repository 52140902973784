import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { useParams } from 'react-router-dom';
import { useLoading, useUserList } from '@hooks/index';
import projectFormServices from "@services/forms/projectforms";
import useUserProviderState from '@contexts/UserContext';
import { useProjectFormSignOffs } from "@services/forms/projectforms";
import { useProjectFormInstanceProviderV2 } from '@providers';
import { getFormattedSignOffDate } from '@utilities/dateHelpers';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useRiskAssessment } from '@views/RiskAssesmentSummary';

export const VFSignOffContextV2 = createContext({});

export const VFSignOffProvider = ({ children }) => {
    const { projectFormId, projectId } = useParams();
    const [signOffUserList, setSignOffUserList] = useState([]);
    const [userInfoList, setUserInfoList] = useState([]);
    const [modifiedAfterSignOff, setModifiedAfterSignOff] = useState([]);
    const setLoading = useLoading();
    const { getUsersById } = useUserList();
    const { data: signOffList = [] } = useProjectFormSignOffs(projectId, projectFormId);
    const { authUser, getUser } = useUserProviderState();
    const { answerArray, isProjectFinalized } = useProjectFormInstanceProviderV2();
    const { actions: { updateSignOffList: updateRiskSignoffs } } = useRiskAssessment();
    const sortSignOffListAsc = (list) => list.sort((a, b) =>
        new Date(a.signOffDate).getTime() > new Date(b.signOffDate).getTime() ? 1 : -1
    );

    const addCurrentUserFullName = async () => {
        const { firstName, lastName, activeDirectoryId } = await getUser();
        const currentUser = { firstName, lastName, activeDirectoryId: activeDirectoryId?.toLowerCase() };
        setUserInfoList((prev) => {
            return prev ? [...prev, currentUser] : [currentUser];
        });
    };

    const handleSignOff = async (signOffLevel) => {
        if (!authUser) return;
        setLoading(true);
        try {
            const { status, data } = await projectFormServices.signOffProjectForm(
                projectId,
                projectFormId,
                signOffLevel,
                authUser?.localAccountId?.toUpperCase()
            );
            if (status === 201) {
                const user = userInfoList?.find(
                    (item) => item.activeDirectoryId.toLowerCase() === data.signOffUser.toLowerCase()
                );
                if (!user) await addCurrentUserFullName();
                const newValue = {
                    projectFormSignOffId: data.projectFormSignOffId,
                    projectFormId: data.projectFormId,
                    signOffLevel: data.signOffLevel,
                    signOffUser: data.signOffUser,
                    signOffDate: data.signOffDate,
                    signOffOrder: data.signOffOrder,
                };
                const newList = signOffUserList ? [...signOffUserList, newValue] : [newValue];
                setSignOffUserList(newList);
                updateRiskSignoffs(newList);
            }
        } catch (error) {
            toast.error(error.toString());
        }
        setLoading(false);
    };

    const handleRemoveSignOff = async (projectFormSignOffId) => {
        if (!authUser) return;
        setLoading(true);
        try {
            const response = await projectFormServices.removeSignOffProjectFormById(projectId, projectFormSignOffId);
            if (response.status === 204) {
                const newList = signOffUserList.filter(item => item.projectFormSignOffId !== projectFormSignOffId);
                setSignOffUserList(newList)
                updateRiskSignoffs(newList);
            }
            else toast.error(response.message);
        } catch (error) {
            toast.error(error.toString());
        }
        setLoading(false);
    };

    useUpdateEffect(async () => {
        if (signOffList && signOffList.length > 0) {
            const sortedSignOffList = sortSignOffListAsc(signOffList)
            const uniqueIds = [
                ...new Set(sortedSignOffList?.map((item) => item?.signOffUser)),
            ];

            if (uniqueIds) {
                setLoading(true);
                const users = await getUsersById(uniqueIds);
                setUserInfoList(users);
                setSignOffUserList(signOffList);
                updateRiskSignoffs(signOffList);
                setLoading(false);
            }
        }
    }, [signOffList]);

    useEffect(() => {
        if (answerArray && Object.keys(answerArray).length > 0) {
            const sortSignOff = sortSignOffListAsc(signOffUserList || []);

            let lastSignOffDate = null;
            if (sortSignOff && sortSignOff?.length > 0) {
                const reviewerSignOffs = sortSignOff.filter((signOff) => signOff.signOffLevel !== 1);
                lastSignOffDate = getFormattedSignOffDate(reviewerSignOffs[reviewerSignOffs?.length - 1]?.signOffDate);
            }
            const modifiedList = lastSignOffDate
                ? answerArray.filter((answer) =>
                    answer.lastUpdate &&
                    new Date(getFormattedSignOffDate(lastSignOffDate)).getTime() <
                    new Date(answer.lastUpdate).getTime() &&
                    isSignoffLogicEnabled(isProjectFinalized, new Date(answer.lastUpdate).getTime(), true)
                )?.map((answer) => answer.questionId) : [];

            setModifiedAfterSignOff(modifiedList);
        }

    }, [answerArray, signOffUserList])

    return (
        <VFSignOffContextV2.Provider
            value={{
                signOffUserList,
                userInfoList,
                authUser,
                modifiedAfterSignOff,
                actions: {
                    handleSignOff,
                    handleRemoveSignOff
                }
            }}
        >
            {children}
        </VFSignOffContextV2.Provider>
    )
}

import { useContext } from 'react';
import { VFSignOffContextV2 } from './VFSignOffProvider';

export const useVFSignOffProvider = () => {
  const context = useContext(VFSignOffContextV2);

  if (context === undefined) {
    throw new Error(
      'useProjectFormInstanceProviderV2() hook must be used within <ProjectFormInstanceContextV2/>'
    );
  }

  return context;
}